<template>
    <div class="row">
        <div class="col-12 mb-3">
            <form @submit.prevent="search">
                <div class="form-row align-items-center">
                    <div class="col-auto">

                    </div>
                    <div class="col-auto ml-auto">
                        <grant-project-create-form></grant-project-create-form>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12">
            <v-client-table :data="projects" :columns="columns" :options="options">
                <div slot="btns" slot-scope="{row}">
                    <a :href="`/grant-projects/${row.id}/edit`" class="btn btn-primary btn-sm">
                        Edit
                    </a>
                </div>
            </v-client-table>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        props: {
            project: {}
        },
        data() {
            return {
                columns: ['title', 'btns'],
                options: {
                    sortable: ['title'],
                    headings: {
                        btns: 'Actions',
                    },
                    sortIcon: {
                        base: 'fas',
                        is: 'fa-chevron-down',
                        up: 'fa-chevron-up',
                        down: 'fa-chevron-down'
                    },
                    filterable: false,
                    perPage: 999999,
                },
                filter: {
                    keyword: '',
                },
                showSearchResetBtn: false
            }
        },
        computed: {
            ...mapGetters({
                projects: 'grantProject/getProjects'
            })
        },
        created() {
            this.$store.dispatch('grantProject/getProjects');
        }
    }
</script>
