<template>
    <form>
        <table class="table">
            <tr>
                <th colspan="6" class="text-uppercase">Indicators</th>
            </tr>
            <tr>
                <th class="text-uppercase text-center">Indicator</th>
                <th class="text-uppercase text-center">Number</th>
                <th colspan="2" class="text-uppercase text-center">
                    Involved Donor State (NO, IS, LI)
                </th>
                <th colspan="2" class="text-uppercase text-center">
                    Source of verification
                </th>
            </tr>
            <tr>
                <td colspan="6" class="small font-weight-bold">
                    <p>Annually</p>
                    <p>Outcome 1 - Enhanced performance of Baltic research internationally</p>
                </td>
            </tr>
            <tr v-for="(item, id) in [
                {
                    'title': 'Number of joint peer-reviewed scientific publications submitted (disaggregated by Donor State, type of scientific publication)',
                    'file_title': 'Submission confirmation from editorial board',
                    'file_name': 'first_file'
                }, {
                    'title': 'Number of joint peer-reviewed scientific publications published (disaggregated by Donor State, type of scientific publication)',
                    'file_title': 'Proof of submission',
                    'file_name': 'second_file'
                }, {
                    'title': 'Number of joint applications for further funding (disaggregated by Donor State, Type of application)',
                    'file_title': 'Proof of applications submission from the funding body',
                    'file_name': 'third_file'
                }, {
                    'title': 'Number of jointly registered applications for Intellectual Property Protection (disaggregated by Donor State)',
                    'file_title': 'Proof of submission and register number from IP office',
                    'file_name': 'fourth_file'
                },
            ]">
                <td>{{ item.title }}</td>
                <td>
                    <div
                        :class="['form-control-holder light-mark', errors['props.' + (id + 1) +'.number'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control"
                               :name="'props[' + (id + 1) + '][number]'"
                               :readonly="readOnly"
                               v-model="payment.props[id + 1]['number']">
                    </div>
                </td>
                <td colspan="2">
                    <div
                        :class="['form-control-holder light-mark', errors['props.' + (id + 1) +'.donor_states'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control"
                               :name="'props[' + (id + 1) + '][donor_states]'"
                               v-model="payment.props[id + 1]['donor_states']"
                               :readonly="readOnly">
                    </div>
                </td>
                <td colspan="2">
                    <label>{{ item.file_title }}</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment[item.file_name]">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment[item.file_name].title }}</span>
                        <small class="text-muted">{{ payment[item.file_name].size_in_kb }} KB</small>

                        <a :href="payment[item.file_name].url" class="btn btn-outline-primary btn-rounded ml-3 mx-2"
                           target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded"
                                v-on:click="deleteFile(payment[item.file_name])" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>
                        <input type="hidden" :name="item.file_name" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors[item.file_name] ? 'danger-mark' : '']"
                         v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input"
                                       :id="item.file_name"
                                       :name="item.file_name"
                                       type="file">
                                <label class="custom-file-label" :for="item.file_name">
                                    {{ files[item.file_name] ? files[item.file_name] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="danger-message" v-if="errors[item.file_name]">
                        {{ errors[item.file_name][0] }}
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6" class="small font-weight-bold">
                    <p>Semi - annual</p>
                    <p>Output 1.1 Knowledge of Research Shared</p>
                </td>
            </tr>
            <tr>
                <th class="text-uppercase text-center">Indicator</th>
                <th class="text-uppercase text-center">Number</th>
                <th class="text-uppercase text-center">Male</th>
                <th class="text-uppercase text-center">Female</th>
                <th class="text-uppercase text-center">Type of graduation degree (PhD or postdoc)</th>
                <th class="text-uppercase text-center">Source of verification</th>
            </tr>
            <tr>
                <td>
                    Number of researchers supported (disaggregated by Gender, type of graduation degree)
                </td>
                <td>
                    <div :class="['form-control-holder light-mark', errors['props.0.number'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" name="props[0][number]"
                               v-model="payment.props[0]['number']"
                               :readonly="readOnly">
                    </div>
                </td>
                <td>
                    <div :class="['form-control-holder light-mark', errors['props.0.male'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" name="props[0][male]"
                               v-model="payment.props[0]['male']"
                               :readonly="readOnly">
                    </div>
                </td>
                <td>
                    <div :class="['form-control-holder light-mark', errors['props.0.female'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" name="props[0][female]"
                               v-model="payment.props[0]['female']"
                               :readonly="readOnly">
                    </div>
                </td>
                <td>
                    <div :class="['form-control-holder light-mark', errors['props.0.type'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" name="props[0][type]"
                               v-model="payment.props[0]['type']"
                               :readonly="readOnly">
                    </div>
                </td>
                <td>
                    <label>Project Promoters’ records</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.fifth_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.fifth_file.title }}</span>
                        <small class="text-muted">{{ payment.fifth_file.size_in_kb }} KB</small>

                        <a :href="payment.fifth_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2"
                           target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded"
                                v-on:click="deleteFile(payment.fifth_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="fifth_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark"
                         :class="[errors['fifth_file'] ? 'danger-mark' : '']" v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input"
                                       id="fifth_file"
                                       name="fifth_file"
                                       type="file">
                                <label class="custom-file-label" for="fifth_file">
                                    {{ files['fifth_file'] ? files['fifth_file'] : "" }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="danger-message" v-if="errors['fifth_file']">
                        {{ errors['fifth_file'][0] }}
                    </div>
                </td>
            </tr>
        </table>

        <div>
            <div class="h3 color-blue mt-5 mb-3">
                Attachments
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Project semi-annual/ Annual/ Final report</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.sixth_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.sixth_file.title }}</span>
                        <small class="text-muted">{{ payment.sixth_file.size_in_kb }} KB</small>

                        <a :href="payment.sixth_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2"
                           target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded"
                                v-on:click="deleteFile(payment.sixth_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="sixth_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors['sixth_file'] ? 'danger-mark' : '']"
                         v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input"
                                       id="sixth_file"
                                       name="sixth_file"
                                       type="file">
                                <label class="custom-file-label" for="sixth_file">
                                    {{ files['sixth_file'] ? files['sixth_file'] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                        <!--<small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>-->
                    </div>
                    <div class="danger-message" v-if="errors['sixth_file']">
                        {{ errors['sixth_file'][0] }}
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label>Supporting documents</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.seventh_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.seventh_file.title }}</span>
                        <small class="text-muted">{{ payment.seventh_file.size_in_kb }} KB</small>

                        <a :href="payment.seventh_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2"
                           target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded"
                                v-on:click="deleteFile(payment.seventh_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="seventh_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors['seventh_file'] ? 'danger-mark' : '']"
                         v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input"
                                       id="seventh_file"
                                       name="seventh_file"
                                       type="file">
                                <label class="custom-file-label" for="seventh_file">
                                    {{ files['seventh_file'] ? files['seventh_file'] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                        <!--<small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>-->
                    </div>
                    <div class="danger-message" v-if="errors['seventh_file']">
                        {{ errors['seventh_file'][0] }}
                    </div>
                </div>
            </div>
            <div>

                <div class="h5">
                    Other
                    <!--<small class="text-muted text-uppercase d-block">Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.</small>-->
                </div>

                <div v-for="(file, findex) in payment.other_files" class="form-row">
                    <div class="form-group col-md-6">
                        <div class="mb-3 file-preview d-flex align-items-center" v-if="file.title">
                            <div class="file-format-icon">
                                <i class="far fa-file-pdf color-blue"></i>
                            </div>

                            <span class="font-italic file-name">{{ file.title }}</span>
                            <small class="text-muted">{{ file.size_in_kb }} KB</small>

                            <a :href="file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                <i class="fa fa-cloud-download-alt"></i>
                            </a>
                            <button type="button" class="btn btn-outline-danger btn-rounded"
                                    v-on:click="deleteOtherFile(file, findex)" v-if="!readOnly">
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </div>
                        <div class="form-control-holder blue-mark" v-else>
                            <div class="input-group file-upload mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                </div>
                                <div class="custom-file">
                                    <input @change="fileClick($event)" :readonly="readOnly" class="custom-file-input"
                                           :id="'other_files-' + findex"
                                           :name="'other_files[' + findex + ']'"
                                           type="file">
                                    <label class="custom-file-label" :for="'other_files-' + findex">
                                        {{ files['other_files[' + findex + ']'] ? files['other_files[' + findex + ']'] :
                                        "Upload a Document" }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!--<small>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        </small>-->
                    </div>
                </div>

                <div class="mt-" v-if="!readOnly">
                    <button type="button" @click="addOtherFile()" class="btn btn-lg btn-outline-secondary mb-4">
                        <i class="fas fa-plus mr-2"></i>
                        <strong>Add Another</strong>
                    </button>
                </div>
            </div>
        </div>

        <div class="btn-out-of-box text-center btn-actions mt-5"
             v-if="!payment.id || payment.status == 'draft' || (payment.status == 'declined' && !currentUser.is_manager)">
            <div>
                <button type="button" @click="submit($event)" v-show="currentUser.is_manager"
                        class="btn pms-btn-purple btn-xlg text-uppercase mb-4" name="submit" value="1">Submit
                </button>
            </div>
            <div>
                <button type="button" @click="submitDraft($event)" v-show="currentUser.is_editor"
                        class="btn pms-btn-light-blue btn-lg text-uppercase mb-5" name="draft" value="1">Save draft
                </button>
            </div>
        </div>
        <input type="hidden" name="project_id" :value="projectId">
    </form>

</template>

<script>
    import {mapGetters} from "vuex";
    import paymentAPI from "../../api/payment"

    export default {
        props: {
            projectId: null,
            paymentId: null,
            readOnly: Boolean,
        },
        data() {
            return {
                payment: {
                    props: {
                        0: {}, 1: {}, 2: {}, 3: {}, 4: {},
                    },
                    other_files: []
                },
                files: {},
            }
        },
        created() {
            this.$store.dispatch('user/getCurrentUser');

            if (this.paymentId) {
                paymentAPI.show(this.paymentId)
                    .then(response => {
                        Object.assign(this.payment, response.data);
                    });
            }
        },
        computed: {
            ...mapGetters({
                errors: 'payment/getErrors',
                currentUser: 'user/getCurrentUser'
            })
        },
        methods: {
            submit(e) {
                var self = this;

                paymentAPI.submit(
                    self.paymentId,
                    new FormData(e.target.form)
                ).then(function (response) {
                    window.location.replace('/research-projects/' + self.projectId + '/payments/')
                }).catch(function (error) {
                    if (error.response.status == 422) {
                        self.$store.commit('payment/setErrors', error.response.data.errors);
                    }
                });
            },
            submitDraft(e) {
                var self = this;

                if (self.paymentId) {
                    paymentAPI.update(
                        self.paymentId,
                        new FormData(e.target.form)
                    ).then(function (response) {
                        window.location.replace('/research-projects/' + self.projectId + '/payments/' + response.data.id + '/edit')
                    }).catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('payment/setErrors', error.response.data.errors);
                        }
                    });
                } else {
                    paymentAPI.store(
                        new FormData(e.target.form)
                    ).then(function (response) {
                        window.location.replace('/research-projects/' + self.projectId + '/payments/' + response.data.id + '/edit')
                    }).catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('payment/setErrors', error.response.data.errors);
                        }
                    });
                }
            },
            fileClick(e) {
                if (this.readOnly) {
                    e.preventDefault();
                } else {
                    if (e.target.files.length) {
                        this.$set(this.files, e.target.name, e.target.files[0].name)
                    }
                }
            },
            deleteFile(file) {
                paymentAPI.deleteFile(file.id)
                    .then(() => {
                        Vue.delete(this.payment, file.type);
                    });
            },
            deleteOtherFile(file, key) {
                paymentAPI.deleteFile(file.id)
                    .then(response => {
                        this.payment.other_files.splice(key, 1);
                    });
            },
            addOtherFile() {
                this.payment.other_files.push({});
            },
        },
    }
</script>
