import UserAPI from '../api/user.js';
import InstitutionAPI from "../api/institution";

export const user = {
    namespaced: true,
    state: {
        currentUser: {
            institution: {
                country: {
                    title: ''
                }
            }
        },
        users: [],
        filter: {
            keyword: null,
            institution: null,
        },
        filterLists: {
            institution: []
        },
        errors: []
    },
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
        unshiftUser(state, user) {
            state.users.unshift(user);
        },
        shiftUser(state, id) {
            let index = state.users
                .map(function(item) { return item.id; })
                .indexOf(id);
            state.users.splice(index, 1);
        },
        setFilterInstitutions(state, institutions) {
            state.filterLists.institutions = institutions;
        },
        resetErrors(state) {
            state.errors = [];
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
        setCurrentUser(state, user) {
            state.currentUser = user;
        }
    },
    getters: {
        getUsers: state => {
            return state.users;
        },
        getUser: state => id => {
            return state.users.find(user => user.id === id);
        },
        getFilter: state => {
            return state.filter;
        },
        getFilterLists: state => {
            return state.filterLists;
        },
        getErrors: state => {
            return state.errors;
        },
        getCurrentUser: state => {
            return state.currentUser;
        }
    },
    actions: {
        getUsers({ commit }, filter) {
            UserAPI.index(filter)
                .then(function (response) {
                    let institutions = [];
                    response.data.forEach(function(user) {
                        if (user.institution) {
                            institutions.push(user.institution);
                        }
                    });
                    commit('setUsers', response.data);
                    commit('setFilterInstitutions', institutions);
                })
                .catch(function () {
                    return commit('setUsers', []);
                });
        },

        getCurrentUser({ commit }) {
            return UserAPI.me()
                .then(function (response) {
                    commit('setCurrentUser', response.data);
                })
                .catch(function () {
                    return commit('setCurrentUser', {});
                });
        },

        store({commit}, data) {
            commit('resetErrors');
            return UserAPI.store(data)
                .then(function (response) {
                    commit('unshiftUser', response.data.user);
                    $('#user-create').trigger('click');
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        commit('setErrors', error.response.data.errors);
                    }
                });
        },

        edit({commit}, data) {
            commit('resetErrors');
            return UserAPI.edit(data)
                .then(function (response) {
                    $('#user-create-' + response.data.user.id).trigger('click');
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        commit('setErrors', error.response.data.errors);
                    }
                });
        },

        destroy({commit}, id) {
            UserAPI.destroy(id)
                .then(function (response) {
                    $('#user-delete-' + id).trigger('click');
                    commit('shiftUser', id);
                });
        }
    }
};
