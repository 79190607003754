export default {
    index (filter) {
        return axios.get('/api/institutions', {params: filter});
    },
    store (data) {
        return axios.post('/api/institutions', data);
    },
    find (id) {
        return axios.get('/api/institutions/' + id);
    },
    update (id, data) {
        return axios.post(`/api/institutions/${id}`, data);
    },
    destroy (id) {
        return axios.delete('/api/institutions/' + id);
    },
    addParticipant (id, user) {
        return axios.post(`/api/institutions/${id}/participants/`, {user_id: user.id});
    },
    removeParticipant (id, user) {
        return axios.post(`/api/institutions/exclude-participant`, {user_id: user.id});
    },
}
