export default {
    getAvailableExperts (projectId) {
        return axios.get('/api/available-experts', {params: filter});
    },
    getProjectExperts (projectId) {
        return axios.get('/api/project-experts', {params: {project_id: projectId}});
    },
    assignExpert (data) {
        return axios.get('/api/assign-expert', {params: data});
    },
    storeExpertEvaluation(projectId, data) {
        return axios.post(`/api/evaluation/${projectId}/expert-review`, data);
    },
    destroy (projectId, expertId) {
        return axios.delete(`/api/evaluation/${projectId}/experts/${expertId}`);
    },
}
