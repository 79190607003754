import ProjectAPI from '../api/project.js';

export const project = {
    namespaced: true,
    state: {
        projects: [],
        errors: [],
    },
    mutations: {
        setProjects(state, projects) {
            state.projects = projects;
        },
        setProject(state, project) {
            state.project = project;
        },
        unshiftProject(state, project) {
            state.projects.unshift(project);
        },
        shiftProject(state, id) {
            let index = state.projects
                .map(function(item) { return item.id; })
                .indexOf(id);
            state.projects.splice(index, 1);
        },
        resetErrors(state) {
            state.errors = [];
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
        addParticipant(state, user) {
            state.project.participants.unshift(user);
        }
    },
    getters: {
        getProjects: state => {
            return state.projects;
        },
        getProject: state => {
            return state.project;
        },
        getErrors: state => {
            return state.errors;
        }
    },
    actions: {
        getProjects({ commit }, filter) {
            ProjectAPI.index(filter)
                .then(function (response) {
                    commit('setProjects', response.data);
                });
        },
        store({commit}, data) {
            commit('resetErrors');
            ProjectAPI.store(data)
                .then(function (response) {
                    commit('unshiftProject', response.data.project);
                    $('#project-create').trigger('click');
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        commit('setErrors', error.response.data.errors);
                    }
                });
        },
        find({commit}, id) {
            ProjectAPI.find(id)
                .then(function (response) {
                    commit('setProject', response.data.project);
                });
        },
        destroy({commit}, id) {
            ProjectAPI.destroy(id)
                .then(function () {
                    $('#project-delete-' + id).trigger('click');
                    commit('shiftProject', id);
                });
        }
    }
};
