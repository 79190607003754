<template>
    <div class="d-inline">
        <button v-if="institutionId" type="button" class="btn btn-success btn" data-toggle="modal"
                data-target="#user-create">
            Create and attach a new user
        </button>
        <a href="#" v-else-if="user && title" data-toggle="modal"
                :data-target="'#user-create-' + user.id">
            {{ title }}
        </a>
        <button v-else-if="user" type="button" class="btn btn-outline-primary btn-rounded" data-toggle="modal"
                :data-target="'#user-create-' + user.id">
            <i class="fa fa-pencil-alt"></i>
        </button>
        <button v-else type="button" class="btn btn-success btn" data-toggle="modal" data-target="#user-create">
            Create
        </button>

        <div class="modal fade" :id="'user-create' + (user ? '-' + user.id : '')" tabindex="-1" role="dialog"
             aria-labelledby="user-createLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="user-createLabel">
                            Creating a new user
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <input type="hidden" name="id" v-if="user" :value="user.id">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="inp-email">E-mail</label>
                                        <input type="text" name="email" id="inp-email"
                                               v-model="fields.email"
                                               :class="['form-control', errors.email ? 'is-invalid' : '']">
                                        <span class="invalid-feedback" role="alert" v-if="errors.email">
                                            {{ errors.email[0] }}
                                        </span>
                                    </div>

                                    <div class="form-group">
                                        <label for="inp-first_name">First Name</label>
                                        <input type="text" name="first_name" id="inp-first_name"
                                               v-model="fields.first_name"
                                               :class="['form-control', errors.first_name ? 'is-invalid' : '']">
                                        <span class="invalid-feedback" role="alert" v-if="errors.first_name">
                        {{ errors.first_name[0] }}
                    </span>
                                    </div>

                                    <div class="form-group">
                                        <label for="inp-last_name">Last Name</label>
                                        <input type="text" name="last_name" id="inp-last_name"
                                               v-model="fields.last_name"
                                               :class="['form-control', errors.last_name ? 'is-invalid' : '']">
                                        <span class="invalid-feedback" role="alert" v-if="errors.last_name">
                        {{ errors.last_name[0] }}
                    </span>
                                    </div>

                                    <div class="form-group">
                                        <label for="inp-personal_code">Personal Code</label>
                                        <input type="text" name="personal_code" id="inp-personal_code"
                                               v-model="fields.personal_code"
                                               :class="['form-control', errors.personal_code ? 'is-invalid' : '']">
                                        <span class="invalid-feedback" role="alert" v-if="errors.personal_code">
                        {{ errors.personal_code[0] }}
                    </span>
                                    </div>

                                    <div class="form-group">
                                        <label for="inp-phone">Phone</label>
                                        <input type="text" name="phone" id="inp-phone"
                                               v-model="fields.phone"
                                               :class="['form-control', errors.phone ? 'is-invalid' : '']">
                                        <span class="invalid-feedback" role="alert" v-if="errors.phone">
                        {{ errors.phone[0] }}
                    </span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Login</label>
                                        <div class="form-check">
                                            <input name="auth_email" v-model="fields.auth_email"
                                                   class="form-check-input" type="checkbox" value="1"
                                                   id="inp-auth_email">
                                            <label class="form-check-label" for="inp-auth_email">
                                                Available to login with email?
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input name="auth_nzdis" v-model="fields.auth_nzdis"
                                                   class="form-check-input" type="checkbox" value="1"
                                                   id="inp-auth_nzdis">
                                            <label class="form-check-label" for="inp-auth_nzdis">
                                                Available to login with NZDIS?
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Settings</label>
                                        <div class="form-check">
                                            <input name="is_blocked" v-model="fields.is_blocked"
                                                   class="form-check-input" type="checkbox" value="1"
                                                   id="inp-is_blocked">
                                            <label class="form-check-label" for="inp-is_blocked">
                                                Is blocked?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <ul class="nav nav-tabs" :id="'roleTabs-' + (user ? user.id : '')">
                                        <li class="nav-item">
                                            <a :class="['nav-link', activeRuleset === 'admin' ? 'active' : '']"
                                               :id="'admin-tab-' + (user ? user.id : '')"
                                               @click="activeRuleset = 'admin'"
                                               data-toggle="tab" :href="'#admin-' + (user ? user.id : '')">Admin</a>
                                        </li>
                                        <li class="nav-item">
                                            <a :class="['nav-link', activeRuleset === 'participant' ? 'active' : '']"
                                               :id="'participant-tab-' + (user ? user.id : '')"
                                               data-toggle="tab"
                                               @click="activeRuleset = 'participant'"
                                               :href="'#participant-' + (user ? user.id : '')">Participant</a>
                                        </li>
                                        <li class="nav-item">
                                            <a :class="['nav-link', activeRuleset === 'expert' ? 'active' : '']"
                                               :id="'expert-tab-' + (user ? user.id : '')"
                                               data-toggle="tab"
                                               @click="activeRuleset = 'expert'"
                                               :href="'#expert-' + (user ? user.id : '')">Expert</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content mt-3">
                                        <div
                                            :class="['tab-pane', 'fade', activeRuleset === 'admin' ? 'show active' : '']"
                                            :id="'admin-' + (user ? user.id : '')">
                                            <div class="form-group">
                                                <label>Permissions</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="role"
                                                           id="inp-role-research-admin"
                                                           value="research admin"
                                                           v-model="roles.admin.role">
                                                    <label class="form-check-label" for="inp-role-research-admin">
                                                        VIAA - Research
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="role"
                                                           id="inp-role-grant-admin"
                                                           value="grant admin"
                                                           v-model="roles.admin.role">
                                                    <label class="form-check-label" for="inp-role-grant-admin">
                                                        VIAA - Grants
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="role"
                                                           id="inp-role-admin"
                                                           value="admin"
                                                           v-model="roles.admin.role">
                                                    <label class="form-check-label" for="inp-role-admin">
                                                        Superadmin
                                                    </label>
                                                </div>
                                                <div class="text-danger" role="alert" v-if="errors.role">
                                                    {{ errors.role[0] }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="['tab-pane', 'fade', activeRuleset === 'participant' ? 'show active' : '']"
                                            :id="'participant-' + (user ? user.id : '')">

                                            <div class="form-group">
                                                <label for="inp-institution_id">Institution</label>
                                                <v-select :clearable="false" :disabled="!!institutionId" id="inp-institution_id" label="title" v-model="roles.participant.institution" :filterable="false" :options="institutionSelectOptions" @search="onInstitutionSearch">
                                                    <template slot="no-options">
                                                        Start typing
                                                    </template>
                                                    <template slot="option" slot-scope="option">
                                                        <div class="d-center">
                                                            {{ option.title }}
                                                        </div>
                                                    </template>
                                                    <template slot="selected-option" slot-scope="option">
                                                        <div class="selected d-center">
                                                            {{ option.title }}
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="form-group">
                                                <label for="inp-role">Role</label>
                                                <select :disabled="!this.roles.participant.institution" name="role" id="inp-role" class="form-control" v-model="roles.participant.role">
                                                    <option value="manager">Manager</option>
                                                    <option value="editor">Editor</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Permissions</label>
                                                <div class="form-check">
                                                    <input :disabled="!this.roles.participant.institution" class="form-check-input" type="checkbox" value="research" id="defaultCheck1" v-model="roles.participant.permissions">
                                                    <label class="form-check-label" for="defaultCheck1">
                                                        Research Projects
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input :disabled="!this.roles.participant.institution" class="form-check-input" type="checkbox" value="grant" id="defaultCheck2" v-model="roles.participant.permissions">
                                                    <label class="form-check-label" for="defaultCheck2">
                                                        Grant Projects
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="['tab-pane', 'fade', activeRuleset === 'expert' ? 'show active' : '']"
                                            :id="'expert-' + (user ? user.id : '')">
                                            <h5>Projects</h5>

                                            <div class="table-responsive" v-if="fields.projects && fields.projects.length">
                                                <table class="table">
                                                    <thead>
                                                    <tr>
                                                        <td>Title</td>
                                                        <td>Type</td>
                                                        <td></td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-if="activeRuleset === 'expert'" v-for="project in fields.projects">
                                                        <td>{{ project.title }}</td>
                                                        <td>{{ project.type_title }}</td>
                                                        <td class="text-right">
                                                            <a :href="`/${project.type}-projects/${project.id}`"
                                                               class="btn btn-primary btn-sm">
                                                                <i class="fas fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="alert alert-primary" v-else>
                                                Expert has no projects at this moment.
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <h5>Additional Information</h5>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="inp-expert_website">Website</label>
                                                    <input :disabled="activeRuleset != 'expert'" name="expert_website" type="text" id="inp-expert_website" class="form-control" v-model="fields.expert_website">
                                                </div>
                                                <!--
                                                <div class="form-group col-4">
                                                    <label>Contract (.pdf)</label>
                                                    <div class="mb-3 file-preview d-flex align-items-center" v-if="fields.contract_file">
                                                        <div class="file-format-icon">
                                                            <i class="far fa-file-pdf color-blue"></i>
                                                        </div>

                                                        <span class="font-italic file-name">{{ fields.contract_file.title }}</span>
                                                        <small class="text-muted">{{ fields.contract_file.size_in_kb }} KB</small>

                                                        <a :href="fields.contract_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                                            <i class="fa fa-cloud-download-alt"></i>
                                                        </a>
                                                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(fields.contract_file)">
                                                            <i class="far fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                    <div class="form-control-holder blue-mark" v-else>
                                                        <div class="input-group file-upload mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                                </span>
                                                            </div>
                                                            <div class="custom-file">
                                                                <input @change="fileClick($event)" :disabled="activeRuleset != 'expert'" class="custom-file-input" id="contract_file"
                                                                       name="contract_file"
                                                                       type="file">
                                                                <label class="custom-file-label" for="contract_file">
                                                                    {{ files.contract_file ? files.contract_file : "Upload a Document" }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                -->
                                                <div class="form-group col-4">
                                                    <label for="inp-expert_institution">Institution</label>
                                                    <input :disabled="activeRuleset != 'expert'" name="expert_institution" type="text" id="inp-expert_institution" class="form-control" v-model="fields.expert_institution">
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="inp-expert_wos_website">Web of science website</label>
                                                    <input :disabled="activeRuleset != 'expert'" name="expert_web_of_science" type="text" id="inp-expert_wos_website" class="form-control" v-model="fields.expert_web_of_science">
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="inp-expert_degree">Degree</label>
                                                    <input :disabled="activeRuleset != 'expert'" name="expert_degree" type="text" id="inp-expert_degree" class="form-control" v-model="fields.expert_degree">
                                                </div>
                                                <div class="form-group col-4">
                                                    <label>CV</label>
                                                    <div class="mb-3 file-preview d-flex align-items-center" v-if="fields.cv_file">
                                                        <div class="file-format-icon">
                                                            <i class="far fa-file-pdf color-blue"></i>
                                                        </div>

                                                        <span class="font-italic file-name">Expert CV</span>

                                                        <a :href="`/storage/users/${user.id}/${fields.cv_file}`" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                                            <i class="fa fa-cloud-download-alt"></i>
                                                        </a>
                                                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(user.id)">
                                                            <i class="far fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                    <div class="form-control-holder blue-mark" v-else>
                                                        <div class="input-group file-upload mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                                </span>
                                                            </div>
                                                            <div class="custom-file">
                                                                <input @change="fileClick($event)" :disabled="activeRuleset != 'expert'" class="custom-file-input" id="cv_file"
                                                                       name="cv_file"
                                                                       type="file">
                                                                <label class="custom-file-label" for="cv_file">
                                                                    {{ files.cv_file ? files.cv_file : "Upload a Document" }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12 text-right">
                                    <button type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
                                    <button type="button" @click="submit($event)" class="btn btn-success">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import userApi from "../../api/user.js";

    export default {
        props: {
            institutionId: {},
            user: {},
            title: ''
        },
        data() {
            return {
                fields: {},
                institutionSelectOptions: [],
                activeRuleset: 'admin',
                roles: {
                    admin: {
                        role: 'admin',
                    },
                    participant: {
                        institution: null,
                        role: 'participant',
                        permissions: [],
                    },
                    expert: {
                        role: 'expert'
                    },
                },
                files: {}
            }
        },
        mounted() {
            if (this.user) {
                this.fields = this.user;
                if (this.fields.is_admin) {
                    this.activeRuleset = 'admin';
                    this.roles.admin.role = this.fields.roles[0].name;
                }
                else if (this.fields.is_participant || this.fields.is_editor || this.fields.is_manager) {
                    this.activeRuleset = 'participant';
                    if (this.fields.is_editor) {
                        this.roles.participant.role = 'editor';
                    }
                    else if (this.fields.is_manager) {
                        this.roles.participant.role = 'manager';
                    }
                    else {
                        this.roles.participant.role = 'participant';
                    }
                    this.roles.participant.permissions = this.fields.project_permissions;
                    this.roles.participant.institution = this.fields.institution;
                }
                else if (this.fields.is_expert) {
                    this.activeRuleset = 'expert';
                }
            }

            if (this.institutionId) {
                this.$store.dispatch('institution/find', this.institutionId)
                    .then(response => this.roles.participant.institution = response);
                this.activeRuleset = 'participant';
            }
        },
        computed: {
            calculatedRoles() {
                let roles = [];
                switch (this.activeRuleset) {
                    case "admin":
                        roles.push(this.roles.admin.role);
                        break;

                    case "participant":
                        if (this.roles.participant.permissions.length) {
                            roles = this.roles.participant.permissions.map(e => e + ' ' + this.roles.participant.role)
                        }
                        else {
                            roles.push(this.roles.participant.role)
                        }
                        break;

                    case "expert":
                        roles.push(this.roles.expert.role);
                        break;
                }

                return roles;
            },
            ...mapGetters({
                errors: 'user/getErrors',
                getUser: 'user/getUser',
            })
        },
        methods: {
            submit(e) {
                let fields = new FormData(e.target.form);
                this.calculatedRoles.forEach((r) => fields.append('roles[]', r));
                if (this.activeRuleset === 'participant' && this.roles.participant.institution) {
                    fields.set('institution_id',this.roles.participant.institution.id);
                }

                if (this.user) {
                    this.$store.dispatch('user/edit', fields);
                } else {
                    this.$store.dispatch('user/store', fields).then(() => {
                        this.fields = {};
                        this.activeRuleset = 'admin';
                        this.files = {};
                        this.roles = {
                            admin: {
                                role: 'admin',
                            },
                            participant: {
                                institution: null,
                                role: 'participant',
                                permissions: [],
                            },
                            expert: {
                                role: 'expert'
                            },
                        };
                    });
                }
            },
            onInstitutionSearch(keyword, loading) {
                if (!keyword) {
                    return;
                }

                loading(true);

                axios.get('/api/institutions', {params: {keyword: keyword}})
                    .then(response => {
                        this.institutionSelectOptions = response.data;

                        loading(false);
                    });
            },
            fileClick(e) {
                if (e.target.files.length) {
                    this.$set(this.files, e.target.name, e.target.files[0].name)
                }
            },
            deleteFile(userId) {
                userApi.deleteFile(userId)
                    .then(response => {
                        Vue.delete(this.fields, 'cv_file');
                    });
            },
        },
    }
</script>
