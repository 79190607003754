import ExpertAPI from '../api/expert.js';
import InstitutionAPI from "../api/institution";
import UserAPI from "../api/user";

export const expert = {
    namespaced: true,
    state: {
        users: [],
        errors: []
    },
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
        unshiftUser(state, user) {
            state.users.unshift(user);
        },
        shiftUser(state, id) {
            let index = state.users
                .map(function (item) {
                    return item.id;
                })
                .indexOf(id);
            state.users.splice(index, 1);
        },
        resetErrors(state) {
            state.errors = [];
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
    },
    getters: {
        getUsers: state => {
            return state.users;
        },
        getErrors: state => {
            return state.errors;
        },
    },
    actions: {
        getUsers({commit}, projectId) {
            ExpertAPI.getProjectExperts(projectId)
                .then(function (response) {
                    console.log(response);
                    commit('setUsers', response.data);
                })
                .catch(function () {
                    return commit('setUsers', []);
                });
        },
        assignExpert({commit}, data) {
            ExpertAPI.assignExpert({
                user_id: data.user.id,
                project_id: data.project_id,
                type: data.type
            })
                .then(function (data) {
                    $('#user-search').trigger('click');
                    commit('unshiftUser', data.data.expert);
                });
        },
        storeExpertEvaluation({commit}, data) {
            ExpertAPI.storeExpertEvaluation(data.projectId, data.form)
                .then(function (data) {
                    location.reload();
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        commit('setErrors', error.response.data.errors);
                    }
                });
        },

        destroy({commit}, data) {
            ExpertAPI.destroy(data.projectId, data.expertId)
                .then(function (response) {
                    $('#expert-delete-' + data.expertId).trigger('click');
                    commit('shiftUser', data.expertId);
                });
        }
    }
};
