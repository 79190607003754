import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import { user } from './modules/user.js'
import { institution } from './modules/institution.js'
import { grantProject } from './modules/grantProject.js'
import { researchProject } from './modules/researchProject.js'
import { project } from './modules/project.js'
import { payment } from './modules/payment.js'
import { expert } from './modules/expert.js'

export default new Vuex.Store({
    modules: {
        user: user,
        expert: expert,
        institution: institution,
        project: project,
        payment: payment,
        grantProject: grantProject,
        researchProject: researchProject,
    }
});
