import PaymentAPI from '../api/payment.js';

export const payment = {
    namespaced: true,
    state: {
        payments: [],
        errors: [],
    },
    mutations: {
        setPayments(state, payments) {
            state.payments = payments;
        },
        resetErrors(state) {
            state.errors = [];
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
    },
    getters: {
        getPayments: state => {
            return state.payments;
        },
        getErrors: state => {
            return state.errors;
        }
    },
    actions: {
        index({ commit }, filter) {
            PaymentAPI.index(filter)
                .then(function (response) {
                    commit('setPayments', response.data);
                });
        },
        store({commit}, data) {
            commit('resetErrors');
            PaymentAPI.store(data)
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        commit('setErrors', error.response.data.errors);
                    }
                });
        },
    }
};
