export default {
    index (filter) {
        return axios.get('/api/grant-projects', {params: filter});
    },
    get (id) {
        return axios.get('/api/grant-projects/' + id + '/edit');
    },
    create (data) {
        return axios.post('/api/grant-projects', data);
    },
    update (id, data) {
        return axios.post(`/api/grant-projects/${id}`, data);
    },
    submit (id, data) {
        return axios.post(`/api/grant-projects/${id}/submit`, data);
    },
    deleteFile(projectId, fileId) {
        return axios.post(`/api/grant-projects/${projectId}/delete-file/${fileId}`);
    },
    deleteSpecialNeedRequest(projectId, id) {
        console.log(`/api/grant-projects/${projectId}/special-need-requests/${id}`);
        return axios.post(`/api/grant-projects/${projectId}/special-need-requests/${id}`);
    },
    deleteMobilityFlow(projectId, id) {
        return axios.post(`/api/grant-projects/${projectId}/mobility-flows/${id}`);
    },

}
