<template>
    <div>
        <div class="row lg-btn-part d-flex content" v-if="currentUser.is_editor">
            <div class="col pr-2" v-if="userPermissions.includes('research')">
                <a
                    href="/research-projects/create"
                    class="btn btn-primary btn-lg btn-block text-uppercase"
                >
                    <i class="fas fa-chart-line"></i>
                    {{ __('researches.create') }}
                </a>
            </div>
            <div class="col pl-2" v-if="userPermissions.includes('grant')">
                <a
                    href="/grant-projects/create"
                    class="btn btn-outline-primary pms-btn-purple-outline btn-lg btn-block text-uppercase"
                >
                    <i class="far fa-file-alt"></i>
                    {{ __('scholarships.create') }}
                </a>
            </div>
        </div>

        <div v-if="currentUser.is_admin" class="main-box">
            <div class="h3">
                Project list
            </div>
            <div class="col-12 mb-3">
                <form @submit.prevent="search">
                    <div class="form-row align-items-center">
                        <div class="col-auto">
                            <label class="sr-only" for="inp-keyword">Keyword</label>
                            <input type="text" name="keyword" id="inp-keyword" class="form-control"
                                   placeholder="Keyword" v-model="filter.keyword">
                        </div>
                        <div class="col-auto">
                            <label class="sr-only" for="inp-status">Status</label>
                            <select type="text" name="keyword" id="inp-status" class="form-control" v-model="filter.status">
                                <option value="draft">Draft</option>
                                <option value="submitted">Submitted</option>
                                <option value="evaluation">Evaluating</option>
                                <option value="evaluation_not_passed">Evaluation not passed</option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                        <div class="col-auto" v-if="showSearchResetBtn">
                            <button class="btn btn-success" @click="resetSearch">Reset</button>
                        </div>
                    </div>
                </form>
            </div>

            <v-client-table :data="projects" :columns="columns" :options="options">
                <span slot="budget" slot-scope="{row}">
                    € {{ row.budget }}
                </span>
                <span slot="status" slot-scope="{row}">
                    <span v-if="(row.type == 'grant' && row.extra_evaluation_status)">
                        <span v-if="row.extra_evaluation_status == 1">Evaluated - Not passed</span>
                        <span v-else-if="row.extra_evaluation_status == 2">Evaluated</span>
                        <span v-else-if="row.extra_evaluation_status == 3">Evaluated - Partly passed</span>
                    </span>
                    <span v-else>
                        {{(row.evaluation_completed_status == 3 && row.evaluation_is_completed == 1) ? 'Evaluated - Sent back' : (row.evaluation_completed_status == 1) ? 'Evaluated' : (row.evaluation_status == 1 || row.evaluation_completed_status == 2) ? 'Evaluation not passed' : (row.evaluation_status == 2 || row.evaluation_status == 3) ? 'Evaluating' : row.submitted_at ? 'Submitted' : 'Draft'}}
                    </span>
                </span>
                <span slot="institution" slot-scope="{row}">
                    <a :href="`/institutions/${row.institution.id}/edit`">{{ row.institution.title }}</a>
                </span>
                <span slot="type" slot-scope="{row}" class="text-capitalize">
                    {{ row.type }}
                </span>
                <div slot="btns" slot-scope="{row}" :key="row.id" class="text-right text-nowrap">
                    <a :href="`/${row.type}-projects/${row.id}/payments`" class="btn btn-outline-primary btn-rounded">
                        <i class="fa fa-money-bill"></i>
                    </a>
                    <a :href="`/evaluation/${row.id}`" class="btn btn-outline-primary btn-rounded" v-if="row.is_evaluatable">
                        <i class="fa fa-award"></i>
                    </a>
                    <a :href="`/${row.type}-projects/${row.id}`" class="btn btn-outline-primary btn-rounded">
                        <i class="fa fa-eye"></i>
                    </a>
                    <a v-if="row.title_en && row.extra_evaluation_status == 2" :href="`/${row.type}-projects/${row.id}/export`" class="btn btn-outline-primary btn-rounded" title="Export to NZDIS system">
                        <i class="fas fa-cloud-upload-alt"></i>
                    </a>
                </div>
            </v-client-table>
        </div>
        <div v-else>
            <div class="main-box" v-for="project in projects">
                <div class="h3">
                    {{ project.title }}

                    <div>
                        <small class="text-uppercase ml-0">{{ project.type_title }}</small>
                    </div>
                </div>

                <div class="project-data mb-4 d-flex align-items-center">
                    <div class="mr-5">
                        Project period:
                        <span class="txt-frame mx-1">{{ (project.started_at) ? project.started_at.substring(0, 10) : '' }} - {{ (project.ended_at) ? project.ended_at.substring(0, 10) : '' }}</span>
                    </div>
                    <div v-if="project.budget"><span class="mr-1">TOTAL BUDGET:</span> €{{ project.budget }}</div>
                </div>

                <div class="box-content">
                    <p class="text-justify" v-if="project.summary">
                        {{ project.summary }}
                    </p>

                    <p v-else>
                        Project summary is not defined
                    </p>
                </div>

                <div class="row align-items-center mt-5">
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <span v-if="currentUser.is_expert">
                                    <a :href="`/evaluation/${project.id}`"
                                       class="btn btn-primary btn-lg text-uppercase px-5 mr-3" v-if="project.evaluation.auth_is_any_expert">
                                        <i class="fas fa-binoculars mr-3"></i>
                                        <span>Evaluate</span>
                                    </a>
                                </span>
                                <span v-else>
                                    <a :href="`/${project.type}-projects/${project.id}`"
                                       class="btn btn-primary btn-lg text-uppercase px-5 mr-3" v-if="!currentUser.is_editor || (currentUser.is_editor && project.submitted_at)">
                                        <i class="fas fa-binoculars mr-3"></i>
                                        <span v-if="currentUser.is_manager && !project.submitted_at">View & Submit</span>
                                        <span v-else>View</span>
                                    </a>

                                    <a :href="`/${project.type}-projects/${project.id}/edit`"
                                       class="btn btn-lg btn-warning text-uppercase px-5"
                                       v-if="currentUser.is_editor && !project.submitted_at">
                                        <i class="far fa-edit mr-3"></i>
                                        Edit
                                    </a>

                                    <a :href="`/${project.type}-projects/${project.id}/payments`"
                                       class="btn btn-lg btn-secondary text-uppercase px-5"
                                       v-if="(currentUser.is_editor || currentUser.is_manager) && project.payments_available">
                                        <i class="fa fa-money-bill mr-3"></i>
                                        Payments
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right">
                        <div class="status-label text-uppercase">
                            current status:
                            <span class="ml-2 font-weight-bold warning txt-frame" v-if="currentUser.is_expert">
                            evaluation
                            </span>
                            <span class="ml-2 font-weight-bold txt-frame" v-else-if="project.submitted_at">
                            submitted
                            </span>
                            <span class="ml-2 font-weight-bold warning txt-frame" v-else>
                            draft
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        props: {
            project: {}
        },
        data() {
            return {
                columns: ['title', 'type', 'institution', 'budget', 'status', 'btns'],
                options: {
                    skin: 'table',
                    sortable: ['title'],
                    headings: {
                        btns: '',
                    },
                    sortIcon: {
                        base: 'fas',
                        is: 'fa-chevron-down',
                        up: 'fa-chevron-up',
                        down: 'fa-chevron-down'
                    },
                    filterable: false,
                    perPage: 999999,
                },
                filter: {
                    keyword: '',
                },
                showSearchResetBtn: false,
                userPermissions: [],
            }
        },
        computed: {
            ...mapGetters({
                projects: 'project/getProjects',
                currentUser: 'user/getCurrentUser'
            })
        },
        created() {
            this.$store.dispatch('project/getProjects');
            this.$store.dispatch('user/getCurrentUser')
                .then(() => this.userPermissions = this.currentUser.project_permissions);
        },
        methods: {
            search: function () {
                this.$store.dispatch('project/getProjects', this.filter);
                this.showSearchResetBtn = true;
            },
            resetSearch: function() {
                this.$store.dispatch('project/getProjects');
                this.showSearchResetBtn = false;
            }
        }
    }
</script>
