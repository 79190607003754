<template>
    <div>
        <div v-if="users.length">
            <v-client-table :data="users" :columns="columns" :options="options">
                <div slot="email" slot-scope="{row}">
                    <span class="fa fa-crown" v-show="row.type == 2"></span> {{ row.email }} {{row.type}}
                </div>
                <div slot="btns" slot-scope="{row}" v-show="row.is_editable" :key="row.id" class="text-nowrap">
                    <user-create-form :user="row"></user-create-form>
                    <expert-delete-form :expert="row" :project-id="projectId"></expert-delete-form>
                </div>
            </v-client-table>
        </div>

        <div class="my-4" v-else>
            <div class="alert alert-primary m-0">
                There are no assigned experts at this moment.
            </div>
        </div>

        <user-search-form v-bind:projectId="projectId" v-if="!viewOnly"></user-search-form>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        props: {
            projectId: null,
            viewOnly: false
        },
        data() {
            return {
                columns: ['email', 'first_name', 'last_name', 'expert_type', 'btns'],
                options: {
                    skin: 'table',
                    sortable: ['email', 'first_name', 'last_name'],
                    headings: {
                        email: 'E-mail',
                        btns: ''
                    },
                    sortIcon: {
                        base: 'fas',
                        is: 'fa-chevron-down',
                        up: 'fa-chevron-up',
                        down: 'fa-chevron-down'
                    },
                    filterable: false,
                    perPage: 999999,
                },
                showSearchResetBtn: false
            }
        },
        computed: {
            ...mapGetters({
                users: 'expert/getUsers'
            })
        },
        created() {
            this.$store.dispatch('expert/getUsers', this.projectId);
        }
    }
</script>
