<template>
    <form>
        <table class="table">
            <tr>
                <th colspan="6" class="text-uppercase">Indicators</th>
            </tr>
            <tr>
                <th class="text-uppercase text-center">Indicator</th>
                <th colspan="3" class="text-uppercase text-center">Number</th>
                <th colspan="2" class="text-uppercase text-center">Comments</th>
            </tr>
            <tr>
                <td colspan="6" class="small font-weight-bold">
                    Outcome 2 - Improved skills and competences of students and staff in higher education and research
                </td>
            </tr>
            <tr>
                <td>
                    Number of students with received ECTS credits
                </td>
                <td colspan="3">
                    <div :class="['form-control-holder light-mark', errors['props.0.number'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" name="props[0][number]"
                               v-model="payment.props[0]['number']"
                        :readonly="readOnly">
                    </div>
                </td>
                <td colspan="2">
                    <div :class="['form-control-holder light-mark', errors['props.0.comments'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" name="props[0][comments]"
                               v-model="payment.props[0]['comments']"
                               :readonly="readOnly">
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6" class="small font-weight-bold">
                    Output 2.1. Facilitated learning mobility in higher education (HE) (students and staff) between
                    Beneficiary State and Donor States
                </td>
            </tr>
            <tr>
                <th rowspan="2" class="text-uppercase text-center">
                    Indicator
                </th>
                <th rowspan="2" class="text-uppercase text-center">
                    Number
                </th>
                <th colspan="2" class="text-uppercase text-center">
                    Gender
                </th>
                <th rowspan="2" class="text-uppercase text-center">
                    Involved Donor State(NO, IS, LI)
                </th>
                <th rowspan="2" class="text-uppercase text-center">
                    Comments
                </th>
            </tr>
            <tr>
                <th class="text-uppercase text-center">Male</th>
                <th class="text-uppercase text-center">Female</th>
            </tr>
            <tr v-for="(title, id) in [
            'Number of staff from Donor States in exchanges (disaggregated by gender, Donor State)',
            'Number of staff from Latvia in exchanges (disaggregated by gender, Donor State)',
            'Number of students from Donor Statesin exchanges (disaggregated by gender, Donor State)',
            'Number of students from Latvia in exchanges (disaggregated by gender, Donor State)',
            ]">
                <td>{{ title }}</td>
                <td>
                    <div
                        :class="['form-control-holder light-mark', errors['props.' + (id + 1) +'.number'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" :name="'props[' + (id + 1) + '][number]'"
                               v-model="payment.props[id + 1]['number']"
                               :readonly="readOnly">
                    </div>
                </td>
                <td class="text-center">
                    <div
                        :class="['form-control-holder light-mark', errors['props.' + (id + 1) +'.male'] ? 'danger-mark' : '']">
                        <input type="text" :name="'props[' + (id + 1) + '][male]'"
                               v-model="payment.props[id + 1]['male']"
                               class="form-control" :readonly="readOnly">
                    </div>
                </td>
                <td class="text-center">
                    <div
                        :class="['form-control-holder light-mark', errors['props.' + (id + 1) +'.female'] ? 'danger-mark' : '']">
                        <input type="text" :name="'props[' + (id + 1) + '][female]'"
                               v-model="payment.props[id + 1]['female']"
                               class="form-control" :readonly="readOnly">
                    </div>
                </td>
                <td>
                    <div
                        :class="['form-control-holder light-mark', errors['props.' + (id + 1) +'.donor_states'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" :name="'props[' + (id + 1) + '][donor_states]'"
                               v-model="payment.props[id + 1]['donor_states']"
                               :readonly="readOnly">
                    </div>
                </td>
                <td>
                    <div
                        :class="['form-control-holder light-mark', errors['props.' + (id + 1) +'.comments'] ? 'danger-mark' : '']">
                        <input type="text" class="form-control" :name="'props[' + (id + 1) + '][comments]'"
                               v-model="payment.props[id + 1]['comments']"
                               :readonly="readOnly">
                    </div>
                </td>
            </tr>
        </table>

        <div>

            <div class="h3 color-blue mt-5 mb-3">
                Attachments
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Project semi-annual/ Annual/ Final report</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.first_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.first_file.title }}</span>
                        <small class="text-muted">{{ payment.first_file.size_in_kb }} KB</small>

                        <a :href="payment.first_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(payment.first_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="first_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors['first_file'] ? 'danger-mark' : '']" v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input" id="first_file"
                                       name="first_file"
                                       type="file">
                                <label class="custom-file-label" for="first_file">
                                    {{ files['first_file'] ? files['first_file'] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                        <small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>
                    </div>
                    <div class="danger-message" v-if="errors['first_file']">
                        {{ errors['first_file'][0] }}
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label>Project financial report</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.second_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.second_file.title }}</span>
                        <small class="text-muted">{{ payment.second_file.size_in_kb }} KB</small>

                        <a :href="payment.second_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(payment.second_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="second_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors['second_file'] ? 'danger-mark' : '']" v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input" id="second_file"
                                       name="second_file"
                                       type="file">
                                <label class="custom-file-label" for="second_file">
                                    {{ files['second_file'] ? files['second_file'] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                        <small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>
                    </div>
                    <div class="danger-message" v-if="errors['second_file']">
                        {{ errors['second_file'][0] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Documents certifying staff mobility</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.third_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.third_file.title }}</span>
                        <small class="text-muted">{{ payment.third_file.size_in_kb }} KB</small>

                        <a :href="payment.third_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(payment.third_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="third_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors['third_file'] ? 'danger-mark' : '']" v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input" id="third_file"
                                       name="third_file"
                                       type="file">
                                <label class="custom-file-label" for="third_file">
                                    {{ files['third_file'] ? files['third_file'] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                        <small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>
                    </div>
                    <div class="danger-message" v-if="errors['third_file']">
                        {{ errors['third_file'][0] }}
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label>Documents certifying the studies for student mobility, incl. the awarded ECTS</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.fourth_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.fourth_file.title }}</span>
                        <small class="text-muted">{{ payment.fourth_file.size_in_kb }} KB</small>

                        <a :href="payment.fourth_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(payment.fourth_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="fourth_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors['fourth_file'] ? 'danger-mark' : '']" v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input" id="fourth_file"
                                       name="fourth_file"
                                       type="file">
                                <label class="custom-file-label" for="fourth_file">
                                    {{ files['fourth_file'] ? files['fourth_file'] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                        <small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>
                    </div>
                    <div class="danger-message" v-if="errors['fourth_file']">
                        {{ errors['fourth_file'][0] }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Bank Statement of HEI</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.fifth_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.fifth_file.title }}</span>
                        <small class="text-muted">{{ payment.fifth_file.size_in_kb }} KB</small>

                        <a :href="payment.fifth_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(payment.fifth_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="fifth_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors['fifth_file'] ? 'danger-mark' : '']" v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input" id="fifth_file"
                                       name="fifth_file"
                                       type="file">
                                <label class="custom-file-label" for="fifth_file">
                                    {{ files['fifth_file'] ? files['fifth_file'] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                        <small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>
                    </div>
                    <div class="danger-message" v-if="errors['fifth_file']">
                        {{ errors['fifth_file'][0] }}
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label>Documents certifying the studies for student mobility, incl. the awarded ECTS</label>
                    <div class="mb-3 file-preview d-flex align-items-center" v-if="payment.sixth_file">
                        <div class="file-format-icon">
                            <i class="far fa-file-pdf color-blue"></i>
                        </div>

                        <span class="font-italic file-name">{{ payment.sixth_file.title }}</span>
                        <small class="text-muted">{{ payment.sixth_file.size_in_kb }} KB</small>

                        <a :href="payment.sixth_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                            <i class="fa fa-cloud-download-alt"></i>
                        </a>
                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(payment.sixth_file)" v-if="!readOnly">
                            <i class="far fa-trash-alt"></i>
                        </button>

                        <input type="hidden" name="sixth_file" value="1" v-if="readOnly">
                    </div>
                    <div class="form-control-holder light-mark" :class="[errors['sixth_file'] ? 'danger-mark' : '']" v-else>
                        <div class="input-group file-upload mb-3">
                            <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                            </div>
                            <div class="custom-file">
                                <input :readonly="readOnly" @change="fileClick($event)" class="custom-file-input" id="sixth_file"
                                       name="sixth_file"
                                       type="file">
                                <label class="custom-file-label" for="sixth_file">
                                    {{ files['sixth_file'] ? files['sixth_file'] : "Upload a Document" }}
                                </label>
                            </div>
                        </div>
                        <small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>
                    </div>
                    <div class="danger-message" v-if="errors['sixth_file']">
                        {{ errors['sixth_file'][0] }}
                    </div>
                </div>
            </div>

            <div>
                <div class="h5">
                    Other
                    <small class="text-muted text-uppercase d-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small>
                </div>

                <div v-for="(file, findex) in payment.other_files" class="form-row">
                    <div class="form-group col-md-6">
                        <div class="mb-3 file-preview d-flex align-items-center" v-if="file.title">
                            <div class="file-format-icon">
                                <i class="far fa-file-pdf color-blue"></i>
                            </div>

                            <span class="font-italic file-name">{{ file.title }}</span>
                            <small class="text-muted">{{ file.size_in_kb }} KB</small>

                            <a :href="file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                <i class="fa fa-cloud-download-alt"></i>
                            </a>
                            <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteOtherFile(file, findex)" v-if="!readOnly">
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </div>
                        <div class="form-control-holder blue-mark" v-else>
                            <div class="input-group file-upload mb-3">
                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </span>
                                </div>
                                <div class="custom-file">
                                    <input @change="fileClick($event)" :readonly="readOnly" class="custom-file-input"
                                           :id="'other_files-' + findex"
                                           :name="'other_files[' + findex + ']'"
                                           type="file">
                                    <label class="custom-file-label" :for="'other_files-' + findex">
                                        {{ files['other_files[' + findex + ']'] ? files['other_files[' + findex + ']'] : "Upload a Document" }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <small>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        </small>
                    </div>
                </div>

                <div class="mt-" v-if="!readOnly">
                    <button type="button" @click="addOtherFile()" class="btn btn-lg btn-outline-secondary mb-4">
                        <i class="fas fa-plus mr-2"></i>
                        <strong>Add Another</strong>
                    </button>
                </div>
            </div>
        </div>

        <div class="btn-out-of-box text-center btn-actions mt-5" v-if="!payment.id || payment.status == 'draft' || (payment.status == 'declined' && !currentUser.is_manager)">

            <div>
                <button type="button" @click="submit($event)" v-show="currentUser.is_manager"
                        class="btn pms-btn-purple btn-xlg text-uppercase mb-4" name="submit" value="1">Submit
                </button>
            </div>
            <div>
                <button type="button" @click="submitDraft($event)" v-show="currentUser.is_editor"
                        class="btn pms-btn-light-blue btn-lg text-uppercase mb-5" name="draft" value="1">Save draft
                </button>
            </div>
        </div>
        <input type="hidden" name="project_id" :value="projectId">
    </form>

</template>

<script>
    import {mapGetters} from "vuex";
    import paymentAPI from "../../api/payment"

    export default {
        props: {
            projectId: null,
            paymentId: null,
            readOnly: Boolean,
        },
        data() {
            return {
                payment: {
                    props: {
                        0: {}, 1: {}, 2: {}, 3: {}, 4: {}
                    },
                    other_files: []
                },
                files: {},
            }
        },
        created() {
            this.$store.dispatch('user/getCurrentUser');

            if (this.paymentId) {
                paymentAPI.show(this.paymentId)
                    .then(response => {
                        Object.assign(this.payment, response.data);
                    });
            }
        },
        computed: {
            ...mapGetters({
                errors: 'payment/getErrors',
                currentUser: 'user/getCurrentUser'
            }),
        },
        methods: {
            submit(e) {
                var self = this;

                paymentAPI.submit(
                    self.paymentId,
                    new FormData(e.target.form)
                ).then(function (response) {
                    window.location.replace('/grant-projects/' + self.projectId + '/payments/')
                }).catch(function (error) {
                    if (error.response.status == 422) {
                        self.$store.commit('payment/setErrors', error.response.data.errors);
                    }
                });
            },
            submitDraft(e) {
                var self = this;

                if (self.paymentId) {
                    paymentAPI.update(
                        self.paymentId,
                        new FormData(e.target.form)
                    ).then(function (response) {
                        window.location.replace('/grant-projects/' + self.projectId + '/payments/' + response.data.id + '/edit')
                    }).catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('payment/setErrors', error.response.data.errors);
                        }
                    });
                } else {
                    paymentAPI.store(
                        new FormData(e.target.form)
                    ).then(function (response) {
                        window.location.replace('/grant-projects/' + self.projectId + '/payments/' + response.data.id + '/edit')
                    }).catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('payment/setErrors', error.response.data.errors);
                        }
                    });
                }
            },
            fileClick(e) {
                if (this.readOnly) {
                    e.preventDefault();
                }
                else {
                    if (e.target.files.length) {
                        this.$set(this.files, e.target.name, e.target.files[0].name)
                    }
                }
            },
            deleteFile(file) {
                paymentAPI.deleteFile(file.id)
                    .then(() => {
                        Vue.delete(this.payment, file.type);
                    });
            },
            deleteOtherFile(file, key) {
                paymentAPI.deleteFile(file.id)
                    .then(response => {
                        this.payment.other_files.splice(key, 1);
                    });
            },
            addOtherFile() {
                this.payment.other_files.push({});
            },
        },
    }
</script>
