<template>
    <div>
        <div class="row">
            <div class="col-12 mb-3">
                <form @submit.prevent="search">
                    <div class="form-row align-items-center">
                        <div class="col-auto">
                            <label class="sr-only" for="inp-keyword">Keyword</label>
                            <input type="text" name="keyword" id="inp-keyword" class="form-control"
                                   placeholder="Keyword" v-model="filter.keyword">
                        </div>
                        <div class="col-auto">
                            <label class="sr-only" for="inp-institution">Institution</label>
                            <select name="institution" id="inp-institution" class="form-control"
                                    v-model="filter.institution">
                                <option v-for="institution in filterLists.institutions" v-bind:value="institution.id">
                                    {{ institution.title }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inp-experts" value="1" v-model="filter.experts">
                                <label class="form-check-label" for="inp-experts">Experts</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inp-grants" value="1" v-model="filter.grants">
                                <label class="form-check-label" for="inp-grants">Grant Participants</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inp-researches" value="1" v-model="filter.researches">
                                <label class="form-check-label" for="inp-researches">Research Participants</label>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                        <div class="col-auto" v-if="showSearchResetBtn">
                            <button class="btn btn-success" @click="resetSearch">Reset</button>
                        </div>
                        <div class="col-auto ml-auto">
                            <user-create-form></user-create-form>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div>
            <v-client-table :data="users" :columns="columns" :options="options">
                <p slot="email" slot-scope="{row}">
                    <span class="fa fa-crown" v-show="row.is_admin"></span> {{ row.email }}
                </p>
                <p slot="institution" slot-scope="{row}">
                    {{ row.institution ? row.institution.title : '-' }}
                </p>
                <div slot="project" slot-scope="{row}">
                    <div v-for="project in row.projects">
                        {{ project.title_en }}
                    </div>
                </div>
                <div slot="btns" slot-scope="{row}" v-show="row.is_editable" :key="row.id" class="text-nowrap">
                    <user-create-form :user="row"></user-create-form>
                    <user-delete-form :user="row"></user-delete-form>
                </div>
            </v-client-table>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        data() {
            return {
                columns: ['email', 'full_name', 'personal_code', 'institution', 'project', 'btns'],
                options: {
                    skin: 'table',
                    sortable: ['email', 'full_name', 'personal_code', 'institution', 'project'],
                    headings: {
                        personal_code: 'Pers. Code',
                        email: 'E-mail',
                        btns: ''
                    },
                    sortIcon: {
                        base: 'fas',
                        is: 'fa-chevron-down',
                        up: 'fa-chevron-up',
                        down: 'fa-chevron-down'
                    },
                    filterable: false,
                    perPage: 999999,
                },
                showSearchResetBtn: false
            }
        },
        computed: {
            ...mapGetters({
                users: 'user/getUsers',
                filter: 'user/getFilter',
                filterLists: 'user/getFilterLists'
            })
        },
        created() {
            this.$store.dispatch('user/getUsers');
        },
        methods: {
            search: function () {
                this.$store.dispatch('user/getUsers', this.filter);
                this.showSearchResetBtn = true;
            },
            resetSearch: function() {
                this.$store.dispatch('user/getUsers');
                this.showSearchResetBtn = false;
            }
        }
    }
</script>
