<template>
    <div>
        <form @submit.prevent="submit">
            <div class="form-group">
                <label for="inp-title">Country</label>
                <select v-model="institution.country_id" class="form-control" name="country_id" :disabled="institution.nzdis_uid">
                    <option v-for="c in selectCountries" :value="c.id" :key="c.id">{{ c.title }}</option>
                </select>
                <span class="invalid-feedback" role="alert" v-if="errors.title">
                    {{ errors.title[0] }}
                </span>
            </div>
            <div class="form-group">
                <label for="inp-title">Title</label>
                <input type="text" name="title" id="inp-title"
                       v-model="institution.title"
                       :class="['form-control', errors.title ? 'is-invalid' : '']"
                       :disabled="institution.nzdis_uid">
                <span class="invalid-feedback" role="alert" v-if="errors.title">
                    {{ errors.title[0] }}
                </span>
            </div>
            <div class="form-group">
                <label for="inp-registration_number">Registration Number</label>
                <input type="text" name="registration_number" id="inp-registration_number"
                       v-model="institution.registration_number"
                       :class="['form-control', errors.registration_number ? 'is-invalid' : '']"
                       :disabled="institution.nzdis_uid">
                <span class="invalid-feedback" role="alert" v-if="errors.registration_number">
                    {{ errors.registration_number[0] }}
                </span>
            </div>
            <div class="form-group">
                <label for="inp-address">Address</label>
                <input type="text" name="address" id="inp-address"
                       v-model="institution.address"
                       :class="['form-control', errors.address ? 'is-invalid' : '']"
                       :disabled="institution.nzdis_uid">
                <span class="invalid-feedback" role="alert" v-if="errors.address">
                    {{ errors.address[0] }}
                </span>
            </div>
            <div class="form-group">
                <label for="inp-email">Email</label>
                <input type="text" name="email" id="inp-email"
                       v-model="institution.email"
                       :class="['form-control', errors.email ? 'is-invalid' : '']"
                       :disabled="institution.nzdis_uid">
                <span class="invalid-feedback" role="alert" v-if="errors.email">
                    {{ errors.email[0] }}
                </span>
            </div>
            <div class="form-group">
                <label for="inp-website">Website</label>
                <input type="text" name="website" id="inp-website"
                       v-model="institution.website"
                       :class="['form-control', errors.website ? 'is-invalid' : '']"
                       :disabled="institution.nzdis_uid">
                <span class="invalid-feedback" role="alert" v-if="errors.website">
                    {{ errors.website[0] }}
                </span>
            </div>
            <div class="form-group text-right" v-if="!institution.nzdis_uid">
                <button type="submit" class="btn btn-success">Save</button>
            </div>

            <div :class="institution.nzdis_uid ? 'mt-5' : ''">
                <h5>Participants</h5>
                <table class="table">
                    <thead>
                    <tr>
                        <th>E-mail</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Role</th>
                        <th width="1%"></th>
                    </tr>
                    </thead>
                    <tr v-for="participant in institution.participants">
                        <td>{{ participant.email }}</td>
                        <td>{{ participant.first_name }}</td>
                        <td>{{ participant.last_name }}</td>
                        <td>
                            <span v-for="(role, key) in participant.roles">
                                {{ role.name }}<span v-if="key != participant.roles.length - 1">,</span>
                            </span>
                        </td>
                        <td class="text-nowrap">
                            <user-create-form :user="participant"></user-create-form>
                            <institution-participant-delete-form :participant="participant" :institution="institution"></institution-participant-delete-form>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <user-create-form v-bind:institutionId="id"></user-create-form>
                <user-search-form v-bind:institutionId="institution.id"></user-search-form>
            </div>
        </form>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import variableAPI from "../../api/variable"
    import institutionApi from "../../api/institution"

    export default {
        props: {
            id: Number,
        },
        data() {
            return {
                selectCountries: [],
            }
        },
        computed: {
            ...mapGetters({
                institution: 'institution/getInstitution',
                errors: 'institution/getErrors'
            })
        },
        created() {
            this.$store.dispatch('institution/find', this.id);

            variableAPI.countries()
                .then(response => {
                    this.selectCountries = response.data;
                });
        },
        methods: {
            submit() {
                institutionApi.update(this.id, this.institution);
            },
        },
    }
</script>
