export default {
    index (data) {
        return axios.get('/api/payments', {params: data});
    },
    store (data) {
        return axios.post('/api/payments', data);
    },
    submit (id, data) {
        return axios.post('/api/payments/' + id + '/submit', data);
    },
    show (id) {
        return axios.get('/api/payments/' + id);
    },
    update (id, data) {
        return axios.post('/api/payments/' + id, data);
    },
    deleteFile(fileId) {
        return axios.post(`/api/payments-delete-file/${fileId}`);
    },
}
