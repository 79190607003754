<template>
    <div>
        <div class="row">
            <div class="col-12 mb-3">
                <form @submit.prevent="search">
                    <div class="form-row align-items-center">
                        <div class="col-auto">
                            <label class="sr-only" for="inp-keyword">Keyword</label>
                            <input type="text" name="keyword" id="inp-keyword" class="form-control"
                                   placeholder="Keyword" v-model="filter.keyword">
                        </div>
                        <div class="col-auto">
                            <button type="submit" class="btn btn-primary">Search</button>
                        </div>
                        <div class="col-auto" v-if="showSearchResetBtn">
                            <button class="btn btn-success" @click="resetSearch">Reset</button>
                        </div>
                        <div class="col-auto ml-auto">
                            <a href="/institutions/nzdis-import" class="btn btn-warning mr-2">NZDIS Import</a>
                            <institution-create-form></institution-create-form>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div>
            <v-client-table :data="institutions" :columns="columns" :options="options">
                <p slot="nzdis_uid" slot-scope="{row}">
                    {{ row.nzdis_uid ? row.nzdis_uid : '-' }}
                </p>
                <div slot="btns" slot-scope="{row}" :key="row.id" class="text-nowrap">
                    <a :href="'institutions/' + row.id + '/edit'" class="btn btn-outline-primary btn-rounded">
                        <i class="fa fa-pencil-alt"></i>
                    </a>
                    <institution-delete-form :institution="row" v-if="!row.nzdis_uid"></institution-delete-form>
                </div>
            </v-client-table>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        data() {
            return {
                columns: ['title', 'registration_number', 'address', 'nzdis_uid', 'participants_count', 'btns'],
                options: {
                    skin: 'table',
                    sortable: ['title', 'registration_number', 'address', 'nzdis_uid', 'participants_count'],
                    headings: {
                        title: 'Title',
                        registration_number: 'Reg. Number',
                        address: 'Address',
                        nzdis_uid: 'NZDIS',
                        participants_count: 'Part.',
                        btns: '',
                    },
                    sortIcon: {
                        base: 'fas',
                        is: 'fa-chevron-down',
                        up: 'fa-chevron-up',
                        down: 'fa-chevron-down'
                    },
                    filterable: false,
                    perPage: 999999,
                },
                filter: {
                    keyword: '',
                },
                showSearchResetBtn: false
            }
        },
        computed: {
            ...mapGetters({
                institutions: 'institution/getInstitutions'
            })
        },
        created() {
            this.$store.dispatch('institution/getInstitutions');
        },
        methods: {
            search: function () {
                this.$store.dispatch('institution/getInstitutions', this.filter);
                this.showSearchResetBtn = true;
            },
            resetSearch: function() {
                this.$store.dispatch('institution/getInstitutions');
                this.showSearchResetBtn = false;
                this.filter.keyword = '';
            }
        }
    }
</script>
