<template>
    <div class="d-inline">
        <button type="button" class="btn btn-success btn" data-toggle="modal" data-target="#institution-create">
            Create
        </button>

        <div class="modal fade" id="institution-create" tabindex="-1" role="dialog"
             aria-labelledby="institution-createLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="institution-createLabel">
                            Creating a new institution
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="institution-create-close-btn">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submit">
                            <div class="form-group">
                                <label for="inp-title">Country</label>
                                <select v-model="fields.country_id" class="form-control" name="country_id">
                                    <option v-for="c in selectCountries" :value="c.id" :key="c.id">
                                        {{ c.title }}
                                    </option>
                                </select>
                                <span class="invalid-feedback" role="alert" v-if="errors.title">
                                    {{ errors.title[0] }}
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="inp-title">Title</label>
                                <input type="text" name="title" id="inp-title"
                                       v-model="fields.title"
                                       :class="['form-control', errors.title ? 'is-invalid' : '']">
                                <span class="invalid-feedback" role="alert" v-if="errors.title">
                                    {{ errors.title[0] }}
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="inp-registration_number">Registration Number</label>
                                <input type="text" name="registration_number" id="inp-registration_number"
                                       v-model="fields.registration_number"
                                       :class="['form-control', errors.registration_number ? 'is-invalid' : '']">
                                <span class="invalid-feedback" role="alert" v-if="errors.registration_number">
                                    {{ errors.registration_number[0] }}
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="inp-address">Address</label>
                                <input type="text" name="address" id="inp-address"
                                       v-model="fields.address"
                                       :class="['form-control', errors.address ? 'is-invalid' : '']">
                                <span class="invalid-feedback" role="alert" v-if="errors.address">
                                    {{ errors.address[0] }}
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="inp-email">E-mail</label>
                                <input type="text" name="email" id="inp-email"
                                       v-model="fields.email"
                                       :class="['form-control', errors.email ? 'is-invalid' : '']">
                                <span class="invalid-feedback" role="alert" v-if="errors.email">
                                    {{ errors.email[0] }}
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="inp-website">Website</label>
                                <input type="text" name="website" id="inp-website"
                                       v-model="fields.website"
                                       :class="['form-control', errors.website ? 'is-invalid' : '']">
                                <span class="invalid-feedback" role="alert" v-if="errors.website">
                                    {{ errors.website[0] }}
                                </span>
                            </div>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import variableAPI from "../../api/variable"

    export default {
        data() {
            return {
                fields: {},
                selectCountries: [],
            }
        },
        computed: {
            ...mapGetters({
                errors: 'institution/getErrors'
            })
        },
        methods: {
            submit() {
                this.$store.dispatch('institution/store', this.fields);
            },
        },
        created() {
            variableAPI.countries()
                .then(response => {
                    this.selectCountries = response.data;
                });
        }
    }
</script>
