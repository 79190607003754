export default {
    index (filter) {
        return axios.get('/api/research-projects', {params: filter});
    },
    get (id) {
        return axios.get('/api/research-projects/' + id + '/edit');
    },
    create (data) {
        return axios.post('/api/research-projects', data);
    },
    update (id, data) {
        return axios.post(`/api/research-projects/${id}`, data);
    },
    submit (id, data) {
        return axios.post(`/api/research-projects/${id}/submit`, data);
    },
    deleteFile(projectId, fileId) {
        return axios.post(`/api/research-projects/${projectId}/delete-file/${fileId}`);
    },
    deleteParticipant(id) {
        return axios.post(`/api/project-participants/${id}`);
    }
}
