<template>
    <form>
        <div>
            <div class="row">
                <div class="col text-center" v-if="projectId && !viewMode">
                    <h1 class="mt-5">
                        <span>{{ __("edit_project") }} </span>
                    </h1>
                    <div class="text-muted" v-if="project.institution">
                        {{ project.uid }} ({{ project.institution.title }})
                        <br />
                        {{ project.version}}
                    </div>
                </div>
                <div class="col text-center" v-else-if="projectId && viewMode">
                    <h1 class="mt-5">
                        <span>{{ __("show_project") }} </span>
                    </h1>
                    <div class="text-muted" v-if="project.institution">
                        {{ project.uid }} ({{ project.institution.title }})
                        <br />
                        {{ project.version}}
                    </div>
                </div>
                <div class="col text-center" v-else>
                    <h1 class="mt-5">
                        <span>{{ __('researches.start_new_project') }}</span>
                    </h1>
                    <p class="subtitle mt-3">
                        {{ __('researches.start_new_project_description') }}
                    </p>
                </div>
            </div>

            <span class="anchor-container">
                <span id="step-1" class="anchor" style="top: -50px;"></span>
            </span>
            <div class="main-box">
                <div class="text-center mb-3">
                    <img class="logo" src="/images/rsgrants-logo.png" alt />
                    <img class="logo" src="/images/viaa-logo.png" alt />
                </div>
                <div class="step-heading d-flex align-items-center">
                    <div class="num">
                        1
                    </div>
                    <div class="txt">
                        <div class="h3">
                            {{ __('researches.step1_title') }}
                        </div>
                        <p>
                            {{ __('researches.step1_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-12" v-if="project.project_agreement">
                            <label for="inp-title_lv">Project Agreement</label>
                            <div :class="['form-control-holder', 'disabled', errors['project_agreement'] ? 'danger-mark' : '']">
                                <input class="form-control"
                                       id="inp-project_agreement"
                                       name="project_agreement"
                                       type="text"
                                       :readonly="true"
                                       v-model="project.project_agreement"
                                />
                            </div>
                            <div class="danger-message" v-if="errors['title_lv']">
                                {{ errors['project_agreement'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-title_lv">Project title (LV)</label>
                            <div :class="['form-control-holder', errors['title_lv'] ? 'danger-mark' : '']">
                                <input class="form-control"
                                       id="inp-title_lv"
                                       name="title_lv"
                                       type="text"
                                       :readonly="viewMode"
                                       v-model="project.title_lv"
                                />
                            </div>
                            <div class="danger-message" v-if="errors['title_lv']">
                                {{ errors['title_lv'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-title_en">Project title (EN) <small class="text-danger">fill before saving the draft</small></label>
                            <div :class="['form-control-holder', errors['title_en'] ? 'danger-mark' : '']">
                                <input
                                        :readonly="viewMode"
                                        class="form-control"
                                        id="inp-title_en"
                                        name="title_en"
                                        type="text"
                                        v-model="project.title_en"
                                />
                            </div>
                            <div class="danger-message" v-if="errors['title_en']">
                                {{ errors['title_en'][0] }}
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>Project Start <small class="text-danger">fill before saving the draft</small></label>
                            <div class="form-control-holder">
                                <datepicker
                                        :disabled-dates="disabledStartDates"
                                        @input="startDateSelected()"
                                        :disabledPicker="viewMode"
                                        :bootstrap-styling="true"
                                        name="started_at"
                                        type="date"
                                        v-model="project.started_at"
                                        :disabled="viewMode"
                                ></datepicker>
                            </div>

                            <div class="danger-message" v-if="errors['started_at']">
                                {{ errors['started_at'][0] }}
                            </div>

                            <small class="d-flex align-items-center text-muted mt-2">
                                <div class="px-3">DD</div>
                                <div class="px-3">MM</div>
                                <div class="px-3">YYYY</div>
                            </small>
                        </div>

                        <div class="form-group col-md-4">
                            <label>Project End <small class="text-danger">fill before saving the draft</small></label>
                            <div class="form-control-holder">
                                <datepicker
                                        @input="endDateSelected()"
                                        :bootstrap-styling="true"
                                        name="ended_at"
                                        type="date"
                                        v-model="project.ended_at"
                                        :disabledPicker="viewMode || !project.started_at"
                                        :disabled="viewMode || !project.started_at"
                                        :disabled-dates="disabledEndDates"
                                ></datepicker>
                            </div>

                            <div class="danger-message" v-if="errors['ended_at']">
                                {{ errors['ended_at'][0] }}
                            </div>

                            <small class="d-flex align-items-center text-muted mt-2">
                                <div class="px-3">DD</div>
                                <div class="px-3">MM</div>
                                <div class="px-3">YYYY</div>
                            </small>
                        </div>

                        <div class="form-group col-md-2">
                            <label for="inp-duration">Duration</label>
                            <div class="form-control-holder" v-bind:class="{ 'danger-mark': errors['duration'] }">
                                <input
                                        class="form-control"
                                        id="inp-duration"
                                        type="text"
                                        name="duration"
                                        v-model="projectDuration"
                                        readonly="readonly"
                                />
                            </div>
                            <div class="danger-message" v-if="errors['duration']">
                                {{ errors['duration'][0] }}
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>Total Cost</label>
                            <div class="form-control-holder">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">€</span>
                                    <input
                                            class="form-control"
                                            type="text"
                                            name="budget"
                                            :value="budgetTotals.total"
                                            disabled
                                    />
                                    <input
                                            class="form-control"
                                            name="budget"
                                            type="hidden"
                                            :value="budgetTotals.total"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label>EEA Grants <small>85% of total cost</small></label>
                            <div class="form-control-holder light-mark">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">€</span>
                                    <input
                                            class="form-control"
                                            name="eea_grants"
                                            type="text"
                                            v-model="budgetTotals.eea_grants"
                                            disabled
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label>State Budget Co-Financing <small>15% of total cost</small></label>
                            <div class="form-control-holder light-mark">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">€</span>
                                    <input
                                            class="form-control"
                                            name="state_budget"
                                            type="text"
                                            v-model="budgetTotals.state_budget"
                                            disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="box-content">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label class="d-block" for="inp-summary_en">Project summary (EN)
                                <button
                                    type="button"
                                    data-toggle="popover"
                                    class="btn btn-link popover-link p-0"
                                    title="Writing project summary"
                                    data-content="Please write a concise summary in a popular science format describing the objectives of the project for non-specialist audience.  If the application is approved, this summary will be made public.">(?)
                                </button>
                                <div class="area-counter float-right">{{ project.summary_en ? project.summary_en.length : 0 }}/<strong>2000</strong></div>
                            </label>
                            <div :class="['form-control-holder light-mark', errors['summary_en'] ? 'danger-mark' : '']">
                            <textarea :readonly="viewMode"
                                      class="form-control"
                                      id="inp-summary_en"
                                      name="summary_en"
                                      rows="5" v-model="project.summary_en"
                                      maxlength="2000"></textarea>

                                <div class="danger-message" v-if="errors['summary_en']">
                                    {{ errors['summary_en'][0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="h3 color-blue">
                    Project’s Promoter
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>Name</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text" v-model="project.promoter.title"/>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Registration No.</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text"
                                       v-model="project.promoter.registration_number"/>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Legal Address</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text" v-model="project.promoter.address"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>Country</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text" v-model="(typeof project.promoter.country == 'object') ? project.promoter.country.title : project.promoter.country"/>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label>E-mail</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="email" v-model="project.promoter.email"/>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Website</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text" v-model="project.promoter.website"/>
                            </div>
                        </div>
                    </div>

                    <div class="h3 color-blue mt-4">
                        Project’s Coordinator
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-coordinator-name">Name, Surname</label>
                            <div class="form-control-holder light-mark" :class="[errors['coordinator.name'] ? 'danger-mark' : '']">
                                <input :readonly="viewMode" class="form-control"
                                       id="inp-coordinator-name" name="coordinator[name]"
                                       type="text" v-model="project.coordinator.name">
                                <span class="danger-message" role="alert" v-if="errors['coordinator.name']">
                                    {{ errors['coordinator.name'][0] }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-coordinator-position">Position</label>
                            <div class="form-control-holder light-mark" :class="[errors['coordinator.position'] ? 'danger-mark' : '']">
                                <input :readonly="viewMode" class="form-control"
                                       id="inp-coordinator-position" name="coordinator[position]"
                                       type="text" v-model="project.coordinator.position">
                                <span class="danger-message" role="alert" v-if="errors['coordinator.position']">
                                    {{ errors['coordinator.position'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-coordinator-phone">Phone number
                                <small>WITH A COUNTRY CODE</small>
                            </label>
                            <div class="form-control-holder light-mark" :class="[errors['coordinator.phone'] ? 'danger-mark' : '']">
                                <input :readonly="viewMode" class="form-control"
                                       id="inp-coordinator-phone" name="coordinator[phone]"
                                       type="text" v-model="project.coordinator.phone">
                                <span class="danger-message" role="alert" v-if="errors['coordinator.phone']">
                                    {{ errors['coordinator.phone'][0] }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-coordinator-email">E-mail</label>
                            <div class="form-control-holder light-mark" :class="[errors['coordinator.email'] ? 'danger-mark' : '']">
                                <input :readonly="viewMode" class="form-control"
                                       id="inp-coordinator-email" name="coordinator[email]"
                                       type="text" v-model="project.coordinator.email">
                                <span class="danger-message" role="alert" v-if="errors['coordinator.email']">
                                    {{ errors['coordinator.email'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="h3 color-blue mt-4">
                        Legal Representative
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-representative-name">Name, Surname</label>
                            <div class="form-control-holder light-mark" :class="[errors['representative.name'] ? 'danger-mark' : '']">
                                <input
                                        :readonly="viewMode"
                                        class="form-control"
                                        id="inp-representative-name"
                                        name="representative[name]"
                                        type="text"
                                        v-model="project.representative.name">
                                <span class="danger-message" role="alert" v-if="errors['representative.name']">
                                    {{ errors['representative.name'][0] }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-representative-position">Position</label>
                            <div class="form-control-holder light-mark" :class="[errors['representative.position'] ? 'danger-mark' : '']">
                                <input
                                        :readonly="viewMode"
                                        class="form-control"
                                        id="inp-representative-position"
                                        name="representative[position]"
                                        type="text"
                                        v-model="project.representative.position">
                                <span class="danger-message" role="alert" v-if="errors['representative.position']">
                                    {{ errors['representative.position'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-representative-phone">
                                Phone number
                                <small>WITH A COUNTRY CODE</small>
                            </label>
                            <div class="form-control-holder light-mark" :class="[errors['representative.phone'] ? 'danger-mark' : '']">
                                <input
                                        :readonly="viewMode"
                                        class="form-control"
                                        id="inp-representative-phone"
                                        name="representative[phone]"
                                        type="text"
                                        v-model="project.representative.phone">
                                <span class="danger-message" role="alert" v-if="errors['representative.phone']">
                                    {{ errors['coordinator.phone'][0] }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-representative-email">E-mail</label>
                            <div class="form-control-holder light-mark" :class="[errors['representative.email'] ? 'danger-mark' : '']">
                                <input
                                        :readonly="viewMode"
                                        class="form-control"
                                        id="inp-representative-email"
                                        name="representative[email]"
                                        type="text"
                                        v-model="project.representative.email">
                                <span class="danger-message" role="alert" v-if="errors['representative.email']">
                                    {{ errors['representative.email'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="h3 color-blue">
                    Project’s Partner(s)
                </div>

                <div class="box-content">
                    <table class="table mb-5" v-if="(addedPartners.length - 1)">
                        <thead>
                        <tr>
                            <th width="1px"></th>
                            <th scope="col">INSTITUTION TITLE</th>
                            <th scope="col">CONTACT PERSON</th>
                            <th scope="col">LEGAL REPRESENTATIVE</th>
                            <th scope="col">LETTER OF INTENT</th>
                            <th scope="col" width="1px"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(partner, pindex) in addedPartners" v-if="partner.is_hidden !== true">
                            <td>
                                <span v-if="hasError('partners.' + pindex)">
                                    <span class="danger-icon">!</span>
                                </span>
                            </td>
                            <td>
                                {{ partner.institution.title }}
                                <span class="text-muted d-block">
                                {{ partner.institution.address }}
                            </span>
                            </td>
                            <td>{{ partner.contact.name }}</td>
                            <td>{{ partner.representative.name }}</td>
                            <td>
                                <a :href="partner.letter_of_intent_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" style="margin-top: -2px;" target="_blank" v-if="partner.letter_of_intent_file">
                                    <i class="fas fa-cloud-download-alt"></i>
                                </a>
                            </td>
                            <td class="text-nowrap">
                                <button type="button" class="btn btn-outline-primary btn-rounded"
                                        v-on:click="makePartnerVisible(partner)">
                                    <i class="fas fa-eye" v-if="viewMode"></i>
                                    <i class="fas fa-pencil-alt" v-else></i>
                                </button>
                                <button type="button" class="btn btn-outline-danger btn-rounded"
                                        v-on:click="deletePartner(partner, pindex)" v-if="!viewMode">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div v-if="!(addedPartners.length - 1)" :class="[!viewMode ? 'mb-5' : '']">
                        <div class="danger-icon mr-2" v-if="viewMode">!</div> Project's partners is not defined.
                    </div>
                    <div>
                        <button v-show="!viewMode"
                                :class="['btn btn-lg btn-outline-secondary', project.partners.length ? 'mb-4' : '']"
                                @click="addPartner()"
                                type="button">
                            <i class="fas fa-plus mr-2"></i> <strong>Add Partner</strong>
                        </button>
                    </div>

                    <div v-for="(partner, pindex) in project.partners">
                        <div :class="[!partner.is_visible ? 'd-none': '']" class="partner-item">
                            <div class="divider mt-2"></div>
                            <input type="hidden"
                                   :id="'inp-id-' + pindex"
                                   :name="'partners[' + pindex + '][id]'"
                                   class="form-control"
                                   v-model="partner.id">

                            <div class="form-row mt-4">
                                <div class="form-group col-md-4">
                                    <label :for="'inp-name-' + pindex">Name
                                        <button
                                                type="button"
                                                data-toggle="popover"
                                                class="btn btn-link popover-link p-0"
                                                title="Project partner's name"
                                                data-content="Name research institutions, universities, university colleges, enterprises, health authorities.">(?)
                                        </button>
                                    </label>
                                    <div class="form-control-holder light-mark">
                                        <input type="text" v-if="viewMode" :value="(partner.institution.institution_text) ? partner.institution.institution_text : partner.institution.id" class="form-control" readonly="readonly" disabled>


                                        <v-select v-else
                                                  :clearable="false"
                                                  @search:blur="selectBlur(partner)"
                                                  :create-option="r => ({ title: r, institution_text: r, registration_number: '', address: '', country: '', email: '', website: '' })"
                                                  :filterable="false"
                                                  :id="'inp-name-' + pindex"
                                                  :options="promoterOptions"
                                                  @search="onPromoterSearch"
                                                  label="title"
                                                  taggable
                                                  v-model="partner.institution">
                                            <template slot="no-options">
                                                Start typing
                                            </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.title }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.title }}
                                                </div>
                                            </template>
                                        </v-select>

                                        <input :name="'partners[' + pindex + '][institution][id]'"
                                               class="form-control"
                                               type="hidden"
                                               v-model="partner.institution.id"
                                               :readonly="viewMode">
                                        <input :name="'partners[' + pindex + '][institution][institution_text]'"
                                               class="form-control"
                                               type="hidden"
                                               v-model="partner.institution.institution_text"
                                               :readonly="viewMode">
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label :for="'inp-institution-registration_number-' + pindex">Registration
                                        No.</label>
                                    <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.institution.registration_number'] ? 'danger-mark' : '']">
                                        <input
                                                :disabled="viewMode || partner.institution.id"
                                                :id="'inp-institution-registration_number-' + pindex"
                                                :name="'partners[' + pindex + '][institution][registration_number]'"
                                                class="form-control"
                                                type="text"
                                                v-model="partner.institution.registration_number">
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.registration_number']">
                                            {{ errors['partners.' + pindex + '.institution.registration_number'][0] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label :for="'inp-partner-address-' + pindex">Address</label>
                                    <div class="form-control-holder light-mark" :class="[ errors['partners.' + pindex + '.institution.address'] ? 'danger-mark' : '']">
                                        <input :disabled="viewMode || partner.institution.id" :id="'inp-partner-address-' + pindex"
                                               :name="'partners[' + pindex + '][institution][address]'"
                                               class="form-control"
                                               type="text"
                                               v-model="partner.institution.address">
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.address']">
                                            {{ errors['partners.' + pindex + '.institution.address'][0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mt-4">
                                <div class="form-group col-md-4">
                                    <label :for="'inp-partner-country-' + pindex">Country
                                        <button
                                            type="button"
                                            data-toggle="popover"
                                            class="btn btn-link popover-link p-0"
                                            title="Project partner's country"
                                            data-content="Project partner from Latvia should correspond to the status of the research institution registered in the national register of Latvia (NZDIS). For Project partner in Latvia please enter just the name of the research institution as stated in the list of research institutions: https://izm.gov.lv/en/research-institutions, all other fields will be filled automatically.">(Latvia?)
                                        </button>
                                    </label>
                                    <div class="form-control-holder light-mark" :class="[ errors['partners.' + pindex + '.institution.country'] ? 'danger-mark' : '']">
                                        <input v-if="viewMode || partner.institution.id" disabled :id="'inp-partner-country-' + pindex"
                                               :name="'partners[' + pindex + '][institution][country]'"
                                               class="form-control"
                                               type="text"
                                               v-model="(partner.institution.country) ? partner.institution.country.title : partner.institution.country_text">
                                        <div v-else>
                                            <select v-model="partner.institution.country_id" class="form-control" :name="'partners[' + pindex + '][institution][country_id]'" :id="'inp-partner-country-' + pindex">
                                                <option v-for="c in selectCountries" :value="c.id" :key="c.id">{{ c.title }}</option>
                                                <option :value="null">Other country</option>
                                            </select>
                                            <input class="form-control" :readonly="viewMode" v-model="partner.institution.country_text" v-if="!partner.institution.id && partner.institution.country_id === null" type="text" :name="'partners[' + pindex + '][institution][country_text]'" >
                                        </div>

                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.country']">
                                            {{ errors['partners.' + pindex + '.institution.country'][0] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label :for="'inp-partner-email-' + pindex">E-mail</label>
                                    <div class="form-control-holder light-mark" :class="[ errors['partners.' + pindex + '.institution.email'] ? 'danger-mark' : '']">
                                        <input :disabled="viewMode || partner.institution.id" :id="'inp-partner-email-' + pindex"
                                               :name="'partners[' + pindex + '][institution][email]'"
                                               class="form-control"
                                               type="text"
                                               v-model="partner.institution.email">
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.email']">
                                            {{ errors['partners.' + pindex + '.institution.email'][0] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label :for="'inp-partner-website-' + pindex">Website</label>
                                    <div class="form-control-holder light-mark" :class="[ errors['partners.' + pindex + '.institution.website'] ? 'danger-mark' : '']">
                                        <input :disabled="viewMode || partner.institution.id" :id="'inp-partner-website-' + pindex"
                                               :name="'partners[' + pindex + '][institution][website]'"
                                               class="form-control"
                                               type="text"
                                               v-model="partner.institution.website">
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.website']">
                                            {{ errors['partners.' + pindex + '.institution.website'][0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mt-4">
                                <div class="form-group col-md-12">
                                    <label :for="'inp-partner-letter-' + pindex">Letter of Intent</label>

                                    <div class="mb-3 file-preview d-flex align-items-center" v-if="partner.letter_of_intent_file">
                                        <input type="hidden" :name="'partners[' + pindex + '][letter]'" value="1" v-if="viewMode">

                                        <div class="file-format-icon">
                                            <i class="far fa-file-pdf color-blue"></i>
                                        </div>

                                        <span class="font-italic file-name">{{ partner.letter_of_intent_file.title }}</span>
                                        <small class="text-muted">{{ partner.letter_of_intent_file.size_in_kb }} KB</small>

                                        <a :href="partner.letter_of_intent_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" style="margin-top: -2px;" target="_blank">
                                            <i class="fas fa-cloud-download-alt"></i>
                                        </a>
                                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(partner.letter_of_intent_file)"  v-if="!viewMode">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </div>

                                    <div :class="['form-control-holder light-mark', errors['partners.' + pindex + '.letter'] ? 'danger-mark' : '']" v-else>
                                        <div class="input-group file-upload mb-3">
                                            <div class="input-group-prepend">
                                        <span class="input-group-text"><i
                                                class="fas fa-cloud-upload-alt"></i></span>
                                            </div>
                                            <div class="custom-file">
                                                <input
                                                        @change="fileClick($event)"
                                                        :readonly="viewMode"
                                                        :id="'inp-partner-letter-' + pindex"
                                                        :name="'partners[' + pindex + '][letter]'"
                                                        class="custom-file-input"
                                                        type="file">
                                                <label :for="'inp-partner-letter-' + pindex" class="custom-file-label">
                                                    {{ files['partners[' + pindex + '][letter]'] ? files['partners[' + pindex + '][letter]'] : "Upload a Document" }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <small>{{ __('researches.step6_file_information') }}</small>

                                    <div class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.letter']">
                                        {{ errors['partners.' + pindex + '.letter'][0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mt-4" v-if="partner.institution.country_id == 2">
                                <div class="form-group col-md-6">
                                    <label :for="'inp-norwegian-' + pindex">Norwegian Research Institute
                                        <button
                                                type="button"
                                                data-toggle="popover"
                                                class="btn btn-link popover-link p-0"
                                                title="Norwegian Research Institute"
                                                data-content="Nb! Norwegian research institutes will use Standard scales of unit costs, no indirect or overhead costs should be included in the budget, as they are already included in the hourly rates.">(?)
                                        </button>
                                    </label>
                                    <div class="custom-control custom-checkbox mt-2">
                                        <input :disabled="viewMode"
                                               :id="'inp-norwegian-' + pindex"
                                               :name="'partners[' + pindex + '][norwegian]'"
                                               type="checkbox" v-model="partner.norwegian"
                                               class="custom-control-input">
                                        <label class="custom-control-label info-label" :for="'inp-norwegian-' + pindex"></label>
                                    </div>
                                </div>
                                <div class="form-group col-md-6" v-if="partner.norwegian">
                                    <label :for="'inp-standard_budget' + pindex">
                                        Use standard rate for proposed budget
                                    </label>
                                    <div class="custom-control custom-checkbox mt-2">
                                        <input :disabled="viewMode"
                                               :id="'inp-standard_budget-' + pindex"
                                               :name="'partners[' + pindex + '][standard_budget]'"
                                               type="checkbox" v-model="partner.standard_budget"
                                               class="custom-control-input">
                                        <label class="custom-control-label info-label" :for="'inp-standard_budget-' + pindex"></label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-6">
                                    <div class="h5">Contact person</div>

                                    <div class="form-row mt-4">
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-contact-email-' + pindex">
                                                Name, Surname
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.contact.name'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-contact-name-' + pindex"
                                                       :name="'partners[' + pindex + '][contact][name]'"
                                                       class="form-control"
                                                       type="text"
                                                       v-model="partner.contact.name">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.contact.name']">
                                                    {{ errors['partners.' + pindex + '.contact.name'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-contact-email-' + pindex">Position</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.contact.position'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-contact-position-' + pindex"
                                                       :name="'partners[' + pindex + '][contact][position]'"
                                                       class="form-control"
                                                       type="text" v-model="partner.contact.position">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.contact.position']">
                                                    {{ errors['partners.' + pindex + '.contact.position'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row mt-4">
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-contact-email-' + pindex">Phone No.
                                                <button
                                                    type="button"
                                                    data-toggle="popover"
                                                    class="btn btn-link popover-link p-0"
                                                    title="Phone no."
                                                    data-content="Please include country calling code.">(?)
                                                </button>
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.contact.phone'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-contact-phone-' + pindex"
                                                       :name="'partners[' + pindex + '][contact][phone]'"
                                                       class="form-control"
                                                       type="text"
                                                       v-model="partner.contact.phone">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.contact.phone']">
                                                    {{ errors['partners.' + pindex + '.contact.phone'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-contact-email-' + pindex">E-mail</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.contact.email'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-contact-email-' + pindex"
                                                       :name="'partners[' + pindex + '][contact][email]'"
                                                       class="form-control"
                                                       type="text"
                                                       v-model="partner.contact.email">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.contact.email']">
                                                    {{ errors['partners.' + pindex + '.contact.email'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="h5">Legal Representative</div>

                                    <div class="form-row mt-4">
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-representative-email-' + pindex">
                                                Name, Surname
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.representative.name'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-representative-name-' + pindex"
                                                       :name="'partners[' + pindex + '][representative][name]'"
                                                       class="form-control"
                                                       type="text" v-model="partner.representative.name">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.representative.name']">
                                                    {{ errors['partners.' + pindex + '.representative.name'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-representative-email-' + pindex">Position</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.representative.position'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-representative-position-' + pindex"
                                                       :name="'partners[' + pindex + '][representative][position]'"
                                                       class="form-control"
                                                       type="text"
                                                       v-model="partner.representative.position">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.representative.position']">
                                                    {{ errors['partners.' + pindex + '.representative.position'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row mt-4">
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-representative-email-' + pindex">Phone No.
                                                <button
                                                    type="button"
                                                    data-toggle="popover"
                                                    class="btn btn-link popover-link p-0"
                                                    title="Phone no."
                                                    data-content="Please include country calling code.">(?)
                                                </button>
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.representative.phone'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-representative-phone-' + pindex"
                                                       :name="'partners[' + pindex + '][representative][phone]'"
                                                       class="form-control"
                                                       type="text" v-model="partner.representative.phone">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.representative.phone']">
                                                    {{ errors['partners.' + pindex + '.representative.phone'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-representative-email-' + pindex">E-mail</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.representative.email'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-representative-email-' + pindex"
                                                       :name="'partners[' + pindex + '][representative][email]'"
                                                       class="form-control"
                                                       type="text" v-model="partner.representative.email">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.representative.email']">
                                                    {{ errors['partners.' + pindex + '.representative.email'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button type="button" class="mt-3 px-5 btn btn-lg btn-primary"
                                        @click="submitPartner(partner)">
                                    <span v-if="viewMode">Close</span>
                                    <span v-else>Submit</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-2" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">2</div>
                    <div class="txt">
                        <div class="h3">
                            {{ __('researches.step2_title') }}
                        </div>
                        <p>
                            {{ __('researches.step2_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label>Type of Research</label>
                            <div class="form-control-holder light-mark" :class="[ errors['research_type'] ? 'danger-mark' : '']">
                                <select class="form-control"
                                        :readonly="viewMode"
                                        v-model="project.research_type"
                                        name="research_type">
                                    <option value="">Choose...</option>
                                    <option v-for="type in researchTypes" :value="type.id">
                                        {{ type.title }}
                                    </option>
                                </select>
                                <span class="danger-message" role="alert" v-if="errors['research_type']">
                                    {{ errors['research_type'][0] }}
                                </span>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label>Field of Research</label>
                            <div class="form-control-holder light-mark" :class="[ errors['research_field'] ? 'danger-mark' : '']">
                                <select class="form-control"
                                        :readonly="viewMode"
                                        v-model="project.research_field"
                                        name="research_field"
                                        @change="researchFieldSelected()">
                                    <option value="">Choose...</option>
                                    <option v-for="type in researchFields" :value="type.id">
                                        {{ type.title }}
                                    </option>
                                </select>
                                <span class="danger-message" role="alert" v-if="errors['research_field']">
                                    {{ errors['research_field'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <div v-if="(typeof researchFieldsDetailed.find(x => x.id === project.research_field_details) == 'object')">
                            <div class=" d-flex align-items-start" v-if="project.research_field_details">
                                <div class="info-icon mr-2">i</div>
                                <p>
                                    The Field of Research you selected has the scientific code
                                    {{ researchFieldsDetailed.find(x => x.id === project.research_field_details).description }}.
                                </p>
                            </div>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label>Detailed <small>FIELD OF RESEARCH</small></label>
                            <div class="form-control-holder light-mark" :class="[ errors['research_field_details'] ? 'danger-mark' : '']">
                                <select class="form-control"
                                        v-model="project.research_field_details"
                                        name="research_field_details"
                                        :readonly="viewMode">
                                    <option v-for="type in researchFieldsDetailed" :value="type.id">
                                        {{ type.title }}
                                    </option>
                                </select>
                                <span class="danger-message" role="alert" v-if="errors['research_field_details']">
                                    {{ errors['research_field_details'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="divider mt-5 mb-3"></div>

                    <div class="form-group col-md-12">
                        <label>RIS3 <small>SPECIALIZATION AREA</small></label>
                        <div class="form-control-holder light-mark" :class="[ errors['ris3'] ? 'danger-mark' : '']">
                            <select class="form-control"
                                    v-model="project.ris3"
                                    name="ris3"
                                    :readonly="viewMode"
                                    @change="ris3FieldSelected()">
                                <option value="">Choose...</option>
                                <option v-for="type in ris3Fields" :value="type.id">
                                    {{ type.title }}
                                </option>
                            </select>
                            <span class="danger-message" role="alert" v-if="errors['ris3']">
                                    {{ errors['ris3'][0] }}
                                </span>
                        </div>
                    </div>
                </div>

                    <div class="divider mt-5 mb-3"></div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-12">
                            <label>
                                Does The Application Address The Challenges&#47;Priorities
                                Set For This Call</label>
                        </div>
                    </div>
                    <div class="form-row mt-4">
                        <div class="form-group col-md-4" v-for="(priority, rpindex) in researchPriorities">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox"
                                       :disabled="viewMode"
                                       class="custom-control-input"
                                       name="research_priority[]"
                                       :id="'research-priority-' + rpindex"
                                       :value="priority.id"
                                       v-model="project.research_priority"/>
                                <label class="custom-control-label info-label"
                                       :for="'research-priority-' + rpindex">
                                    {{ priority.title }}
                                </label>

                                <button
                                        type="button"
                                        data-toggle="popover"
                                        class="btn btn-link popover-link p-0"
                                        :title="priority.title"
                                        :data-content="priority.description">(?)
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="divider mt-5 mb-3"></div>

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label class="d-block">Justification of the selected challenges / priorities
                                <div class="area-counter float-right">
                                    {{ project.research_summary_en ? project.research_summary_en.length : 0 }}/<strong>2000</strong>
                                </div>
                            </label>
                            <div :class="['form-control-holder light-mark', errors.research_summary_en ? 'danger-mark' : '']">
                                <textarea :readonly="viewMode" class="form-control"
                                          id="inp-research_summary_en"
                                          name="research_summary_en"
                                          maxlength="2000"
                                          rows="5" v-model="project.research_summary_en"></textarea>
                            </div>
                            <div class="danger-message" v-if="errors['research_summary_en']">
                                {{ errors['research_summary_en'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-12 mt-3">
                            <label class="d-block">International cooperation
                                <div class="area-counter float-right">
                                    {{ project.research_summary_lv ? project.research_summary_lv.length : 0 }}/<strong>10000</strong>
                                </div>
                            </label>
                            <div :class="['form-control-holder light-mark', errors.research_summary_en ? 'danger-mark' : '']">
                                <textarea :readonly="viewMode" :class="['form-control', errors.research_summary_lv ? 'danger-mark' : '']"
                                          id="inp-research_summary_lv"
                                          name="research_summary_lv"
                                          maxlength="10000"
                                          rows="5" v-model="project.research_summary_lv"></textarea>
                            </div>
                            <div class="danger-message" v-if="errors['research_summary_lv']">
                                {{ errors['research_summary_lv'][0] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-3" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">3</div>
                    <div class="txt">
                        <div class="h3">{{ __('researches.step3_title') }}</div>
                        <p>
                            {{ __('researches.step3_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4" v-if="project.experts.length">
                        <div class="form-group col-md-6">
                            <label>Add Details of the Experts</label>
                        </div>
                    </div>
                    <table class="table mb-0" v-if="project.experts.length">
                        <thead>
                        <tr>
                            <th>Name & Surname</th>
                            <th>CV in English</th>
                            <th>Institution</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(expert, eindex) in project.experts">
                            <td>
                                <input
                                        :name="'experts[' + eindex + '][id]'"
                                        class="form-control"
                                        type="hidden"
                                        v-model="expert.id"
                                        v-if="expert.id">

                                <div :class="['form-control-holder', errors['experts.' + eindex + '.name'] ? 'danger-mark' : '']">
                                    <input
                                            :readonly="viewMode"
                                            :name="'experts[' + eindex + '][name]'"
                                            class="form-control"
                                            type="text"
                                            v-model="expert.name">
                                </div>

                                <div class="danger-message" v-if="errors['experts.' + eindex + '.name']">
                                    {{ errors['experts.' + eindex + '.name'][0] }}
                                </div>
                            </td>
                            <td>
                                <div v-if="expert.cv_file">
                                    <span class="text-muted">{{ expert.cv_file.title }}</span>
                                    <a :href="expert.cv_file.url" class="btn btn-light-blue btn-rounded" style="margin-top: -2px;" target="_blank">
                                        <i class="fas fa-cloud-download-alt"></i>
                                    </a>
                                    <input type="hidden" :name="'experts[' + eindex + '][cv]'" value="1" v-if="viewMode">
                                </div>
                                <div :class="['form-control-holder', errors['experts.' + eindex + '.cv'] ? 'danger-mark' : '']" v-else>
                                    <div class="input-group file-upload">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"><i
                                                class="fas fa-cloud-upload-alt"></i></span>
                                        </div>
                                        <div class="custom-file">
                                            <input @change="fileClick($event)" :readonly="viewMode"
                                                   :id="'expert-cv-' + eindex"
                                                   :name="'experts[' + eindex + '][cv]'"
                                                   class="custom-file-input"
                                                   type="file">
                                            <label :for="'expert-cv-' + eindex" class="custom-file-label">
                                                {{ files['experts[' + eindex + '][cv]'] ? files['experts[' + eindex + '][cv]'] : "Upload a Document" }}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="danger-message" v-if="errors['experts.' + eindex + '.cv']">
                                        {{ errors['experts.' + eindex + '.cv'][0] }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div :class="['form-control-holder', errors['experts.' + eindex + '.institution_text'] ? 'danger-mark' : '']">
                                    <input
                                            :readonly="viewMode"
                                            :name="'experts[' + eindex + '][institution_text]'"
                                            class="form-control"
                                            type="text"
                                            v-model="expert.institution_text">
                                </div>

                                <div class="danger-message" v-if="errors['experts.' + eindex + '.institution_text']">
                                    {{ errors['experts.' + eindex + '.institution_text'][0] }}
                                </div>
                            </td>
                            <td>
                                <button type="button" class="btn btn-outline-danger btn-rounded"
                                        v-on:click="deleteExpert(expert, eindex)" v-if="!currentUser.is_admin && !viewMode">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="mt-5" v-else>
                        <div
                            :class="['form-group col-md-12 d-flex align-items-start']">
                            <div class="info-icon mr-2" v-if="viewMode">i</div>
                            <p class="mb-0">
                                Project's experts is not defined.
                            </p>
                        </div>
                    </div>
                    <div>
                        <button
                                v-show="!viewMode"
                                @click="addExpert()"
                                class="btn btn-lg btn-outline-secondary mt-5"
                                type="button">
                            <i class="fas fa-plus mr-2"></i> <strong>Add Expert</strong>
                        </button>
                    </div>

                    <div class="divider my-5"></div>

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label class="d-block" for="inp-notes">Comments </label>
                            <div class="form-control-holder" :class="[errors['expert_notes'] ? 'danger-mark' : '']">
                            <textarea :readonly="viewMode"
                                      class="form-control"
                                      id="inp-notes"
                                      name="expert_notes"
                                      placeholder="Start typing..."
                                      rows="5"
                                      v-model="project.expert_notes"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-4" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">4</div>
                    <div class="txt">
                        <div class="h3">{{ __('researches.step4_title') }}</div>
                        <p>
                            {{ __('researches.step4_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div
                                :class="['form-group col-md-12 d-flex align-items-start', (!project.partners.length) ? 'mb-0' : '']">
                            <div class="info-icon mr-3">i</div>
                            <p :class="[(!project.partners.length) ? 'mb-0' : '']">
                                New institutions are added automatically when you add new
                                project partners. Project promoter is in the list
                                immediately after starting the research project submission
                                form.
                            </p>
                        </div>
                    </div>

                    <div class="accordion" id="accordionBox">
                        <div class="card" v-for="(partner, pindex) in project.partners">
                            <div
                                    aria-expanded="false"
                                    class="card-header d-flex align-items-center justify-content-between collapsed"
                                    data-toggle="collapse"
                                    :data-target="'#research-staff-' + pindex"
                                    :aria-controls="'research-staff-' + pindex">
                                <h4 class="mb-0">
                                    <!-- @TODO -->
                                    <span class="title-mark-light bg-danger mr-3" v-if="errors['partners.' + pindex + '.research_notes'] || errors['partners.' + pindex + '.research_notes_2'] || errors['partners.' + pindex + '.research_notes_3']">
                                    </span>
                                    {{ partner.institution.title }}
                                    <small class="text-muted" v-if="filterAddedResearchStaff(partner).length">
                                        Added:
                                        <span v-for="(staff, key) in filterAddedResearchStaff(partner)">
                                            {{ staff.name }}<span v-if="filterAddedResearchStaff(partner).length > 0 && (key + 1) != filterAddedResearchStaff(partner).length">, </span>
                                        </span>
                                    </small>
                                </h4>

                                <div class="arrow">
                                    <i class="fas fa-chevron-up"></i>
                                    <i class="fas fa-chevron-down"></i>
                                </div>
                            </div>

                            <div
                                    aria-labelledby="headingOne"
                                    class="collapse"
                                    data-parent="#accordionBox"
                                    :id="'research-staff-' + pindex">
                                <div class="card-body">
                                    <table class="table" v-if="filterAddedResearchStaff(partner).length">
                                        <thead>
                                        <tr>
                                            <th width="1px"></th>
                                            <th scope="col">NAME &amp; SURNAME</th>
                                            <th scope="col">Degree</th>
                                            <th scope="col">Current<br/>Position</th>
                                            <th scope="col">Participation<br/>Period</th>
                                            <th scope="col">CV <small>in English</small></th>
                                            <th width="1px"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(staff, sindex) in filterAddedResearchStaff(partner)">
                                            <td>
                                                <span v-if="hasError('partners.' + pindex + '.staff.' + sindex)">
                                                    <span class="danger-icon">!</span>
                                                </span>
                                            </td>
                                            <td>{{ staff.name }}</td>
                                            <td>{{ staff.degree }}</td>
                                            <td>{{ staff.position }}</td>
                                            <td>
                                                <strong>{{ staff.started_at }}</strong>
                                                to
                                                <strong>{{ staff.ended_at }}</strong>
                                            </td>
                                            <td>
                                                <div class="mb-3 file-preview d-flex align-items-center" v-if="staff.cv">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <span class="text-muted">
                                                            {{ staff.cv.title }}
                                                        </span>
                                                        <a class="btn btn-light-blue btn-rounded ml-2" :href="staff.cv.url" target="_blank">
                                                            <i class="fas fa-cloud-download-alt"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>

                                            <td class="text-nowrap">
                                                <button type="button" class="btn btn-outline-primary btn-rounded" @click="makeResearchStaffVisible(partner, staff)">
                                                    <i class="fas fa-eye" v-if="viewMode"></i>
                                                    <i class="fas fa-pencil-alt" v-else></i>
                                                </button>
                                                <button type="button" class="btn btn-outline-danger btn-rounded" @click="deleteResearchStaff(partner, staff, sindex)"  v-if="!viewMode">
                                                    <i class="fa fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="!filterAddedResearchStaff(partner).length" :class="[viewMode ? 'mt-2' : '']">
                                        <div class="danger-icon mr-2" v-if="viewMode">!</div> Research staff is not defined.
                                    </div>
                                    <div v-if="!viewMode">
                                        <button type="button" class="btn btn-lg btn-outline-secondary mb-4 mt-5" v-on:click="addResearchStaff(partner)">
                                            <i class="fas fa-plus mr-2"></i>
                                            <strong>Add Research Staff</strong>
                                        </button>
                                    </div>

                                    <div v-for="(staff, sindex) in partner.researchStaff" :class="[!staff.is_visible ? 'd-none': '']">
                                        <input type="hidden" :name="'partners[' + pindex + '][staff][' + sindex +'][id]'" v-model="staff.id">

                                        <div class="divider mt-4"></div>

                                        <div class="form-row mt-4">
                                            <div class="form-group col-md-4">
                                                <label>Name, Surname</label>
                                                <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.staff.' + sindex +'.name'] ? 'danger-mark' : '']">
                                                    <input
                                                            :readonly="viewMode"
                                                            :name="'partners[' + pindex + '][staff][' + sindex +'][name]'"
                                                            class="form-control"
                                                            type="text"
                                                            v-model="staff.name" />
                                                    <div class="danger-message" v-if="errors['partners.' + pindex + '.staff.' + sindex +'.name']">
                                                        {{ errors['partners.' + pindex + '.staff.' + sindex +'.name'][0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>Degree</label>
                                                <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.staff.' + sindex +'.degree'] ? 'danger-mark' : '']">
                                                    <input :readonly="viewMode" :name="'partners[' + pindex + '][staff][' + sindex +'][degree]'"
                                                            class="form-control"
                                                            type="text" v-model="staff.degree">
                                                    <div class="danger-message" v-if="errors['partners.' + pindex + '.staff.' + sindex +'.degree']">
                                                        {{ errors['partners.' + pindex + '.staff.' + sindex +'.degree'][0] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>Current Position</label>
                                                <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.staff.' + sindex +'.position'] ? 'danger-mark' : '']">
                                                    <input
                                                            :readonly="viewMode"
                                                            :name="'partners[' + pindex + '][staff][' + sindex +'][position]'"
                                                            class="form-control"
                                                            type="text" v-model="staff.position"/>
                                                    <div class="danger-message" v-if="errors['partners.' + pindex + '.staff.' + sindex +'.position']">
                                                        {{ errors['partners.' + pindex + '.staff.' + sindex +'.position'][0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row mt-2">
                                            <div class="input-daterange col-md-6 px-3">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Participation Period</label>
                                                        <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.staff.' + sindex +'.started_at'] ? 'danger-mark' : '']">
                                                            <datepicker
                                                                :disabled-dates="staffDisabledStartDates"
                                                                :disabledPicker="viewMode"
                                                                :disabled="viewMode"
                                                                :bootstrap-styling="true"
                                                                :name="'partners[' + pindex + '][staff][' + sindex +'][started_at]'"
                                                                type="date"
                                                                v-model="staff.started_at"
                                                            ></datepicker>
                                                        </div>

                                                        <div class="danger-message" v-if="errors['partners.' + pindex + '.staff.' + sindex +'.started_at']">
                                                            {{ errors['partners.' + pindex + '.staff.' + sindex +'.started_at'][0] }}
                                                        </div>

                                                        <small class="d-flex align-items-center text-muted mt-2">
                                                            <div class="px-3">DD</div>
                                                            <div class="px-3">MM</div>
                                                            <div class="px-3">YYYY</div>
                                                        </small>
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label class="invisible">End</label>
                                                        <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.staff.' + sindex +'.ended_at'] ? 'danger-mark' : '']">
                                                            <datepicker
                                                                :disabled-dates="{to: new Date(staff.started_at), from: staffDisabledEndDates.from}"
                                                                :bootstrap-styling="true"
                                                                :name="'partners[' + pindex + '][staff][' + sindex +'][ended_at]'"
                                                                type="date"
                                                                v-model="staff.ended_at"
                                                                :disabledPicker="viewMode || !staff.started_at"
                                                                :disabled="viewMode || !staff.started_at"
                                                            ></datepicker>
                                                        </div>

                                                        <div class="danger-message" v-if="errors['partners.' + pindex + '.staff.' + sindex +'.ended_at']">
                                                            {{ errors['partners.' + pindex + '.staff.' + sindex +'.ended_at'][0] }}
                                                        </div>

                                                        <small class="d-flex align-items-center text-muted mt-2">
                                                            <div class="px-3">DD</div>
                                                            <div class="px-3">MM</div>
                                                            <div class="px-3">YYYY</div>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label>Upload CV</label>

                                                <div class="form-control-holder light-mark">
                                                    <div class="input-group file-upload mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <i class="fas fa-cloud-upload-alt"></i>
                                                            </span>
                                                        </div>
                                                        <div class="custom-file">
                                                            <input @change="fileClick($event)" :readonly="viewMode" :id="'research-staff-cv-' + sindex"
                                                                   :name="'partners[' + pindex + '][staff][' + sindex +'][cv]'"
                                                                   class="custom-file-input" type="file">
                                                            <label class="custom-file-label" :for="'research-staff-cv-' + sindex">
                                                                {{ files['partners[' + pindex + '][staff][' + sindex +'][cv]'] ? files['partners[' + pindex + '][staff][' + sindex +'][cv]'] : "Upload a Document" }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <small>
                                                        {{ __('researches.step6_file_information') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button @click="submitResearchStaff(staff)" type="button" class="mt-3 px-5 btn btn-lg btn-primary">
                                                <span v-if="viewMode">Close</span>
                                                <span v-else>Submit</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="divider my-5"></div>

                                    <div class="h5">Actions of the research team</div>

                                    <div class="form-row">
                                        <div class="form-group col-md-12 mt-3">
                                            <label class="d-block">
                                                Participation of Senior Research Staff
                                                <div class="area-counter float-right">
                                                    {{ partner.research_notes ? partner.research_notes.length : 0 }}/<strong>5000</strong>
                                                </div>
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.research_notes'] ? 'danger-mark' : '']">
                                                <textarea
                                                        :readonly="viewMode"
                                                        class="form-control"
                                                        id="inp-research_notes"
                                                        :name="'partners[' + pindex + '][research_notes]'"
                                                        placeholder="Start typing..."
                                                        rows="5"
                                                        maxlength="5000"
                                                        v-model="partner.research_notes"></textarea>
                                                <div class="danger-message" v-if="errors['partners.' + pindex + '.research_notes']">
                                                    {{ errors['partners.' + pindex + '.research_notes'][0] }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label class="d-block">
                                                PostDocs Involvement in the Project
                                                <span class="area-counter float-right">
                                                    {{ partner.research_notes_2 ? partner.research_notes_2.length : 0 }}/<strong>5000</strong>
                                                </span>
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.research_notes_2'] ? 'danger-mark' : '']">
                                               <textarea
                                                       :readonly="viewMode"
                                                       class="form-control"
                                                       id="inp-research_notes_2"
                                                       :name="'partners[' + pindex + '][research_notes_2]'"
                                                       placeholder="Start typing..."
                                                       rows="5"
                                                       maxlength="5000"
                                                       v-model="partner.research_notes_2"></textarea>
                                                <div class="danger-message" v-if="errors['partners.' + pindex + '.research_notes_2']">
                                                    {{ errors['partners.' + pindex + '.research_notes_2'][0] }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label class="d-block">
                                                PhD students involved in the project
                                                <span class="area-counter float-right">
                                                    {{ partner.research_notes_3 ? partner.research_notes_3.length : 0 }}/<strong>5000</strong>
                                                </span>
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.research_notes_3'] ? 'danger-mark' : '']">
                                            <textarea
                                                    :readonly="viewMode"
                                                    class="form-control"
                                                    id="inp-research_notes_3"
                                                    :name="'partners[' + pindex + '][research_notes_3]'"
                                                    placeholder="Start typing..."
                                                    rows="5"
                                                    maxlength="5000"
                                                    v-model="partner.research_notes_3"></textarea>
                                                <div class="danger-message" v-if="errors['partners.' + pindex + '.research_notes_3']">
                                                    {{ errors['partners.' + pindex + '.research_notes_3'][0] }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-5" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">5</div>
                    <div class="txt">
                        <div class="h3">
                            {{ __('researches.step5_title') }}
                        </div>
                        <p>
                            {{ __('researches.step5_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-12 d-flex align-items-start">
                            <div class="info-icon mr-3 mt-1">i</div>
                            <p>
                                New institutions are added automatically when you add new
                                project partners or experts. Project promoter is in the
                                list immediately after starting the research project
                                submission form.
                            </p>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-12">
                            <label>Proposed Budget
                                <small class="text-uppercase text-muted">€ in euro currency</small>
                            </label>
                        </div>
                    </div>

                    <div class="table-responsive proposed-budget-table">
                        <table class="table table-sticky-last-row">
                            <thead class="text-uppercase">
                            <tr>
                                <td class="border-bottom-0"></td>
                                <th colspan="2" class="text-center">Personnel costs</th>
                                <td colspan="8" class="border-bottom-0"></td>
                            </tr>
                            <tr>
                                <th scope="col">INSTITUTION</th>
                                <th scope="col">Project administrative staff</th>
                                <th scope="col">Project implementation staff</th>
                                <th scope="col">Travel allowances</th>
                                <th scope="col">Subcontracting
                                    <button
                                            type="button"
                                            data-toggle="popover"
                                            class="btn btn-link popover-link p-0"
                                            title="Subcontracting"
                                            data-content="No more than 15% of the total budget">(?)
                                    </button>
                                </th>
                                <th scope="col">Depreciation costs</th>
                                <th scope="col">Costs of inventory, instruments and materials</th>
                                <th scope="col">other costs
                                    <!--<button
                                            type="button"
                                            data-toggle="popover"
                                            class="btn btn-link popover-link p-0"
                                            title="Other costs"
                                            data-content="Costs of publishing and dissemination of project results, costs of scientific forums (organization or participation), reimbursement of travel costs to the PhD students and postdocs who do not have employment contract, external scientific experts, audit costs, if project budget for project promoter or project partner exceeds EUR 325,000. All other costs which are clearly required for the implementation of the project and respectively identifiable">(?)
                                    </button>-->
                                </th>
                                <th scope="col">total direct costs</th>
                                <th scope="col">overheads
                                    <button
                                            type="button"
                                            data-toggle="popover"
                                            class="btn btn-link popover-link p-0"
                                            title="Overheads"
                                            data-content="Flat rate of 25% of total direct eligible  costs, excluding direct eligible costs for subcontracting. Not applicable for institutions in Norway. For institution in Norway overhead costs should not be included in the budget, as they are already included in the hourly rates.">(?)
                                    </button>
                                </th>
                                <th scope="col">
                                    <div class="bg">
                                        <div class="z-index">
                                            Total Budget with overheads
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(partner, pindex) in project.partners">
                                <td>
                                    {{ partner.institution.title }}
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :name="'partners[' + pindex + '][personnel_costs]'"
                                               type="hidden" v-model="partner.budget.personnel_costs">
                                        <input
                                            :disabled="!partner.institution.country_id"
                                            :readonly="viewMode" :name="'partners[' + pindex + '][project_administrative_staff]'"
                                            class="form-control"
                                            type="text"
                                            :class="[errors.partners && errors.partners[pindex] && errors.partners[pindex].project_administrative_staff || (partner.budget.project_administrative_staff > calculateTotalDirectCosts(partner) * 0.10) ? 'text-danger border-danger danger-mark' : '']"
                                            v-model="partner.budget.project_administrative_staff">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input
                                            :disabled="!partner.institution.country_id"
                                            :readonly="viewMode" :name="'partners[' + pindex + '][project_implementation_staff]'"
                                            class="form-control"
                                            type="text"
                                            :class="[errors.partners && errors.partners[pindex] && errors.partners[pindex].project_implementation_staff ? 'danger-mark' : '']"
                                            v-model="partner.budget.project_implementation_staff">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input
                                                :disabled="!partner.institution.country_id"
                                                :readonly="viewMode" :name="'partners[' + pindex + '][travel_allowances]'"
                                               class="form-control"
                                               :class="[errors.partners && errors.partners[pindex] && errors.partners[pindex].travel_allowances ? 'danger-mark' : '']"
                                               type="text" v-model="partner.budget.travel_allowances">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input
                                                :disabled="!partner.institution.country_id"
                                                :readonly="viewMode" :name="'partners[' + pindex + '][sub_contracting]'"
                                               class="form-control"
                                               :class="[(errors.partners && errors.partners[pindex] && errors.partners[pindex].sub_contracting) || (partner.budget.sub_contracting > (calculateTotalBudget(partner) * 0.15)) ? 'text-danger border-danger ' : '']"
                                               type="text" v-model="partner.budget.sub_contracting">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input
                                                :disabled="!partner.institution.country_id"
                                                :readonly="viewMode" :name="'partners[' + pindex + '][depreciation_costs]'"
                                               class="form-control"
                                               type="text"
                                               :class="[errors.partners && errors.partners[pindex] && errors.partners[pindex].depreciation_costs ? 'danger-mark' : '']"
                                               v-model="partner.budget.depreciation_costs">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input
                                                :disabled="!partner.institution.country_id"
                                                :readonly="viewMode" :name="'partners[' + pindex + '][inventory_costs]'"
                                               class="form-control"
                                               type="text"
                                               :class="[errors.partners && errors.partners[pindex] && errors.partners[pindex].inventory_costs ? 'danger-mark' : '']"
                                               v-model="partner.budget.inventory_costs">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input
                                                :disabled="!partner.institution.country_id"
                                                :readonly="viewMode" :name="'partners[' + pindex + '][other_costs]'"
                                               class="form-control"
                                               type="text"
                                               :class="[errors.partners && errors.partners[pindex] && errors.partners[pindex].other_costs ? 'danger-mark' : '']"
                                               v-model="partner.budget.other_costs">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="calculateTotalDirectCosts(partner)" class="form-control highlighted" disabled
                                               type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="calculateTotalOverheads(partner)"
                                               class="form-control"
                                               :class="(partner.norwegian && partner.standard_budget) ? '' : 'highlighted'"
                                               type="text" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="bg">
                                        <div class="z-index">
                                            <div class="form-control-holder text-right">
                                                <input :value="calculateTotalBudget(partner)" class="form-control highlighted"
                                                       disabled type="text">
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(expert, eindex) in project.experts">
                                <td>
                                    {{ expert.name }}
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input class="form-control" type="text" value="0" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input  class="form-control" type="text" value="0" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :name="'experts[' + eindex + '][travel_allowances]'"
                                               :readonly="viewMode"
                                               v-model="expert.budget.travel_allowances"
                                               class="form-control" type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input  class="form-control" type="text" value="0" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input  class="form-control" type="text" value="0" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input  class="form-control" type="text" value="0" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input  class="form-control" type="text" value="0" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="calculateTotalDirectCosts(expert)" class="form-control highlighted" disabled
                                               type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="calculateTotalOverheads(expert)" class="form-control highlighted" disabled
                                               type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="bg">
                                        <div class="z-index">
                                            <div class="form-control-holder text-right">
                                                <input :value="calculateTotalBudget(expert)" class="form-control highlighted" disabled type="text">
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td>
                                <span class="text-uppercase color-blue">
                                    <strong> Grand Total</strong>
                                </span>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.project_administrative_staff.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.project_implementation_staff.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.travel_allowances.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.sub_contracting.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.depreciation_costs.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.inventory_costs.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.other_costs.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.total_direct_costs.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input :value="budgetTotals.overheads.toFixed(2)" class="form-control" disabled type="text">
                                    </div>
                                </td>
                                <td>
                                    <div class="bg">
                                        <div class="z-index">
                                            <div class="grand-total-num">
                                                € <strong>{{ budgetTotals.total.toFixed(2) }}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div v-if="((millionLimit && budgetTotals.total > 1000000) || (eightHundredLimit && budgetTotals.total > 800000)) || budgetTotals.total < 300000">
                        <div class="divider my-5"></div>

                        <div class="form-row mt-4">
                            <div class="form-group col-md-12 d-flex align-items-start">
                                <div class="danger-icon mr-3">!</div>
                                <p>
                                    <span v-if="budgetTotals.total < 300000">
                                        The minimum project amount is
                                        <strong>EUR 300 000</strong>.<br>
                                    </span>
                                    <span v-if="millionLimit && budgetTotals.total > 1000000">
                                        The maximum project amount is EUR
                                        <strong>EUR 1 000 000</strong>.<br>
                                    </span>
                                    <span v-else-if="eightHundredLimit && budgetTotals.total > 800000">
                                        The maximum project amount is EUR <strong>EUR 800 000</strong>.<br>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-if="partnersLimit">
                        <div class="divider my-5"></div>

                        <div class="form-row mt-4">
                            <div class="form-group col-md-12 d-flex align-items-start">
                                <div class="danger-icon mr-3">!</div>
                                <p>
                                    <span>
                                       A project partner from Estonia or Lithuania, as well as from at least one donor country, is required to submit the project.<br>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-6" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">6</div>
                    <div class="txt">
                        <div class="h3">{{ __('researches.step6_title') }}</div>
                        <p>
                            {{ __('researches.step6_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label>Research Proposal</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.research_proposal_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.research_proposal_file.title }}</span>
                                <small class="text-muted">{{ project.research_proposal_file.size_in_kb }} KB</small>

                                <a :href="project.research_proposal_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.research_proposal_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="research_proposal" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark" :class="[errors['research_proposal'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="file-research-proposal"
                                               name="research_proposal"
                                               type="file">
                                        <label class="custom-file-label" for="file-research-proposal">
                                            {{ files.research_proposal ? files.research_proposal : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>
                                    {{ __('researches.step6_file_information') }}
                                </small>
                            </div>
                            <div class="danger-message" v-if="errors['research_proposal']">
                                {{ errors['research_proposal'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Detailed Budget</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.detailed_budget_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.detailed_budget_file.title }}</span>
                                <small class="text-muted">{{ project.detailed_budget_file.size_in_kb }} KB</small>

                                <a :href="project.detailed_budget_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.detailed_budget_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="detailed_budget" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark" :class="[errors['detailed_budget'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode"
                                               class="custom-file-input"
                                               id="file-detailed-budget"
                                               name="detailed_budget"
                                               type="file"
                                        >
                                        <label class="custom-file-label" for="file-detailed-budget">
                                            {{ files.detailed_budget ? files.detailed_budget : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>{{ __('researches.step6_file_information') }}</small>
                            </div>
                            <div class="danger-message" v-if="errors['detailed_budget']">
                                {{ errors['detailed_budget'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Timetable</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.ghawtt_chart_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.ghawtt_chart_file.title }}</span>
                                <small class="text-muted">{{ project.ghawtt_chart_file.size_in_kb }} KB</small>

                                <a :href="project.ghawtt_chart_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.ghawtt_chart_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="ghawtt_chart" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark"  :class="[errors['ghawtt_chart'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode"
                                               class="custom-file-input"
                                               id="file-ghawtt-chart"
                                               name="ghawtt_chart"
                                               type="file">
                                        <label class="custom-file-label" for="file-ghawtt-chart">
                                            {{ files.ghawtt_chart ? files.ghawtt_chart : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>{{ __('researches.step6_file_information') }}</small>
                            </div>
                            <div class="danger-message" v-if="errors['ghawtt_chart']">
                                {{ errors['ghawtt_chart'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Publicity Plan</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.publicity_plan_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.publicity_plan_file.title }}</span>
                                <small class="text-muted">{{ project.publicity_plan_file.size_in_kb }} KB</small>

                                <a :href="project.publicity_plan_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.publicity_plan_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="publicity_plan" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark"  :class="[errors['publicity_plan'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-cloud-upload-alt"></i></span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="file-publicity-plan" name="publicity_plan"
                                               type="file">
                                        <label class="custom-file-label" for="file-publicity-plan">
                                            {{ files.publicity_plan ? files.publicity_plan : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>
                                    {{ __('researches.step6_file_information') }}
                                </small>
                            </div>
                            <div class="danger-message" v-if="errors['publicity_plan']">
                                {{ errors['publicity_plan'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Project Risk Assessment</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.project_risk_assessment_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.project_risk_assessment_file.title }}</span>
                                <small class="text-muted">{{ project.project_risk_assessment_file.size_in_kb }} KB</small>

                                <a :href="project.project_risk_assessment_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.project_risk_assessment_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="project_risk_assessment" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark"  :class="[errors['project_risk_assessment'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode"
                                               class="custom-file-input"
                                               id="file-protect-risk-assessment"
                                               name="project_risk_assessment"
                                               type="file">
                                        <label class="custom-file-label" for="file-protect-risk-assessment">
                                            {{ files.project_risk_assessment ? files.project_risk_assessment : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>
                                    {{ __('researches.step6_file_information') }}
                                </small>
                            </div>
                            <div class="danger-message" v-if="errors['project_risk_assessment']">
                                {{ errors['project_risk_assessment'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Ethics Assessment</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.ethics_assessment_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.ethics_assessment_file.title }}</span>
                                <small class="text-muted">{{ project.ethics_assessment_file.size_in_kb }} KB</small>

                                <a :href="project.ethics_assessment_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.ethics_assessment_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="ethics_assessment" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark" :class="[errors['ethics_assessment'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="file-ethics-assessment"
                                               name="ethics_assessment"
                                               type="file">
                                        <label class="custom-file-label" for="file-ethics-assessment">
                                            {{ files.ethics_assessment ? files.ethics_assessment : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>{{ __('researches.step6_file_information') }}</small>
                            </div>
                            <div class="danger-message" v-if="errors['ethics_assessment']">
                                {{ errors['ethics_assessment'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Promoter’s finance and accounting policy</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.promoter_finance_policy_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.promoter_finance_policy_file.title }}</span>
                                <small class="text-muted">{{ project.promoter_finance_policy_file.size_in_kb }} KB</small>

                                <a :href="project.promoter_finance_policy_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.promoter_finance_policy_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="promoter_finance_policy" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark"  :class="[errors['promoter_finance_policy'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="promoter_finance_policy_file"
                                               name="promoter_finance_policy"
                                               type="file">
                                        <label class="custom-file-label" for="promoter_finance_policy_file">
                                            {{ files.promoter_finance_policy ? files.promoter_finance_policy : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>{{ __('researches.step6_file_information') }}</small>
                            </div>
                            <div class="danger-message" v-if="errors['promoter_finance_policy']">
                                {{ errors['promoter_finance_policy'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Promoter’s annual reports from the last three years</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.promoter_annual_report_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.promoter_annual_report_file.title }}</span>
                                <small class="text-muted">{{ project.promoter_annual_report_file.size_in_kb }} KB</small>

                                <a :href="project.promoter_annual_report_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.promoter_annual_report_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="promoter_annual_report" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark"  :class="[errors['promoter_annual_report'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="promoter_annual_report_file"
                                               name="promoter_annual_report"
                                               type="file">
                                        <label class="custom-file-label" for="promoter_annual_report_file">
                                            {{ files.promoter_annual_report ? files.promoter_annual_report : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>{{ __('researches.step6_file_information') }}</small>
                            </div>
                            <div class="danger-message" v-if="errors['promoter_annual_report']">
                                {{ errors['promoter_annual_report'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Confirmation Letter (LV)</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.confirmation_letter_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.confirmation_letter_file.title }}</span>
                                <small class="text-muted">{{ project.confirmation_letter_file.size_in_kb }} KB</small>

                                <a :href="project.confirmation_letter_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.confirmation_letter_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="confirmation_letter" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark"  :class="[errors['confirmation_letter'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="confirmation_letter_file"
                                               name="confirmation_letter"
                                               type="file">
                                        <label class="custom-file-label" for="confirmation_letter_file">
                                            {{ files.confirmation_letter ? files.confirmation_letter : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>{{ __('researches.step6_file_information') }}</small>
                            </div>
                            <div class="danger-message" v-if="errors['confirmation_letter']">
                                {{ errors['confirmation_letter'][0] }}
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label>Confirmation Letter (EN)</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.confirmation_letter_en_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.confirmation_letter_en_file.title }}</span>
                                <small class="text-muted">{{ project.confirmation_letter_en_file.size_in_kb }} KB</small>

                                <a :href="project.confirmation_letter_en_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.confirmation_letter_en_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="confirmation_letter_en" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark"  :class="[errors['confirmation_letter_en'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="confirmation_letter_en_file"
                                               name="confirmation_letter_en"
                                               type="file">
                                        <label class="custom-file-label" for="confirmation_letter_en_file">
                                            {{ files.confirmation_letter_en ? files.confirmation_letter_en : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>{{ __('researches.step6_file_information') }}</small>
                            </div>
                            <div class="danger-message" v-if="errors['confirmation_letter_en']">
                                {{ errors['confirmation_letter_en'][0] }}
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label>Project summary (LV)</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.summary_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.summary_file.title }}</span>
                                <small class="text-muted">{{ project.summary_file.size_in_kb }} KB</small>

                                <a :href="project.summary_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.summary_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="summary" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark"  :class="[errors['summary'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input :readonly="viewMode" @change="fileClick($event)" class="custom-file-input" id="summary_file"
                                               name="summary"
                                               type="file">
                                        <label class="custom-file-label" for="summary_file">
                                            {{ files['summary'] ? files['summary'] : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>{{ __('researches.step6_file_information') }}</small>
                            </div>

                            <div class="danger-message" v-if="errors['summary']">
                                {{ errors['summary'][0] }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="h5">
                            Other
                            <small class="text-muted text-uppercase d-block">{{ __('researches.step6_info_upload_more_description') }}</small>
                        </div>

                        <div v-for="(file, findex) in project.other_files" class="form-row">
                            <div class="form-group col-md-6">
                                <div class="mb-3 file-preview d-flex align-items-center" v-if="file.title">
                                    <div class="file-format-icon">
                                        <i class="far fa-file-pdf color-blue"></i>
                                    </div>

                                    <span class="font-italic file-name">{{ file.title }}</span>
                                    <small class="text-muted">{{ file.size_in_kb }} KB</small>

                                    <a :href="file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                        <i class="fa fa-cloud-download-alt"></i>
                                    </a>

                                    <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteOtherFile(file, findex)" v-if="!viewMode">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                                <div class="form-control-holder blue-mark" v-else>
                                    <div class="input-group file-upload mb-3">
                                        <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </span>
                                        </div>
                                        <div class="custom-file">
                                            <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input"
                                                   :id="'other_files-' + findex"
                                                   :name="'other_files['+ findex + ']'"
                                                   type="file">
                                            <label class="custom-file-label" :for="'other_files-' + findex">
                                                {{ files['other_files['+ findex + ']'] ? files['other_files['+ findex + ']'] : "Upload a Document" }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <small>
                                    {{ __('researches.step6_file_information') }}
                                </small>
                            </div>
                        </div>
                        <div class="mt-" v-if="!viewMode">
                            <button type="button" @click="addOtherFile()" class="btn btn-lg btn-outline-secondary mb-4">
                                <i class="fas fa-plus mr-2"></i>
                                <strong>Add Another</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="btn-out-of-box text-center btn-actions" v-if="!project.submitted_at">
            <div v-show="userIsManager">
                <button type="button" @click="submit($event)" v-show="userIsManager" class="btn pms-btn-purple btn-xlg text-uppercase mb-4" name="submit" value="1">Submit project</button>
            </div>

            <div>
                <button type="button" @click="submitDraft($event)" v-show="!viewMode || userIsEditor" class="btn pms-btn-light-blue btn-lg text-uppercase mb-5" name="draft" value="1">Save draft</button>
            </div>
        </div>
    </form>
</template>

<script>
    import {mapGetters} from "vuex";
    import researchProjectAPI from "../../api/researchProject"
    import variableAPI from "../../api/variable"
    import JQuery from 'jquery'

    export default {
        props: {
            projectId: null,
            viewMode: Boolean,
        },
        updated: function() {
            JQuery('.proposed-budget-table input').keyup(function() {
                var strippedNumber = JQuery(this).val().replace(/[^\d\.]/g,'');
                var closestFloat = parseFloat(strippedNumber).toFixed(2);
                var closestInt = parseInt(strippedNumber).toString();

                if (JQuery(this).val() !== closestFloat && JQuery(this).val() !== closestInt) {
                    JQuery(this).parent('.form-control-holder').addClass('danger-mark');
                } else {
                    JQuery(this).parent('.form-control-holder').removeClass('danger-mark');
                }
            });
        },
        data() {
            return {
                project: {
                    started_at: null,
                    ended_at: null,
                    promoter: {
                        country: {}
                    },
                    partners: [],
                    experts: [],
                    researchStaff: [],
                    coordinator: {},
                    representative: {},
                    research_type: null,
                    research_field: null,
                    research_field_details: null,
                    ris3_field: null,
                    research_priority: [],
                    other_files: [],
                },
                promoterOptions: [],
                researchTypes: [],
                researchFields: [],
                researchFieldsDetailed: [],
                ris3Fields: [],
                researchPriorities: [],
                userIsManager: false,
                userIsEditor: false,
                selectCountries: [],
                // promoter: {},
                tmp: '',
                disabledStartDates: {
                    to: new Date(2021, 2, 1),
                    from: new Date(2021, 4, 1),
                },
                disabledEndDates: {
                    from: new Date(2024, 4, 1),
                },
                staffDisabledStartDates: {},
                staffDisabledEndDates: {},
                files: {},
            }
        },
        created() {
            variableAPI.index({type: 'research-type'})
                .then(response => {
                    this.researchTypes = response.data;
                });
            variableAPI.index({type: 'research-priority'})
                .then(response => {
                    this.researchPriorities = response.data;
                });
            variableAPI.index({type: 'research-field'})
                .then(response => {
                    this.researchFields = response.data;
                });
            variableAPI.index({type: 'ris3'})
                .then(response => {
                    this.ris3Fields = response.data;
                });
            variableAPI.countries({filter: 'researches'})
                .then(response => {
                    this.selectCountries = response.data;
                });

            if (this.projectId) {
                researchProjectAPI.get(this.projectId)
                    .then(response => {
                        let p = response.data;
                        p.ended_at = new Date(p.ended_at);
                        p.started_at = new Date(p.started_at);
                        for (let e of p.experts) {
                            if (e.budget === undefined) {
                                e.budget = {};
                            }
                        }

                        this.researchFieldPreselected(p.research_field);
                        this.ris3FieldSelected();

                        Object.assign(this.project, p);
                        this.startDateSelected();
                        this.endDateSelected();
                    });
            }

            this.$store.dispatch('user/getCurrentUser')
                .then(() => {
                    if (!this.projectId) {
                        this.project.promoter = this.currentUser.institution;
                        this.addPartner(this.currentUser.institution);
                    }
                    else {
                        this.userIsManager = this.currentUser.is_manager;
                        this.userIsEditor = this.currentUser.is_editor;
                    }
                });

        },
        computed: {
            ...mapGetters({
                errors: 'researchProject/getErrors',
                currentUser: 'user/getCurrentUser'
            }),
            addedPartners: function() {
                return this.project.partners.filter(p => !p.notAdded);
            },
            projectDuration: function () {
                if (!this.project.ended_at || !this.project.started_at) {
                    return '';
                }

                let months;
                months = (this.project.ended_at.getFullYear() - this.project.started_at.getFullYear()) * 12;
                months -= this.project.started_at.getMonth();
                months += this.project.ended_at.getMonth();
                if (this.project.ended_at.getDate() < this.project.started_at.getDate()) {
                    months--;
                }

                return months <= 0 ? 0 : months;
            },
            budgetTotals: function () {
                let totals = {
                    personnel_costs: 0,
                    travel_allowances: 0,
                    sub_contracting: 0,
                    other_costs: 0,
                    total_direct_costs: 0,
                    overheads: 0,
                    total: 0,
                    eea_grants: 0,
                    state_budget: 0,
                    inventory_costs: 0,
                    depreciation_costs: 0,
                    project_administrative_staff: 0,
                    project_implementation_staff: 0
                };
                let summer = (acc, cur) => {
                    acc.personnel_costs += cur.budget.personnel_costs === '' ? 0 : parseFloat(cur.budget.personnel_costs);
                    acc.travel_allowances += cur.budget.travel_allowances === '' ? 0 : parseFloat(cur.budget.travel_allowances);
                    acc.sub_contracting += cur.budget.sub_contracting === '' ? 0 : parseFloat(cur.budget.sub_contracting);
                    acc.other_costs += cur.budget.other_costs === '' ? 0 : parseFloat(cur.budget.other_costs);
                    acc.inventory_costs += cur.budget.inventory_costs === '' ? 0 : parseFloat(cur.budget.inventory_costs);
                    acc.depreciation_costs += cur.budget.depreciation_costs === '' ? 0 : parseFloat(cur.budget.depreciation_costs);
                    acc.project_administrative_staff += cur.budget.project_administrative_staff === '' ? 0 : parseFloat(cur.budget.project_administrative_staff);
                    acc.project_implementation_staff += cur.budget.project_implementation_staff === '' ? 0 : parseFloat(cur.budget.project_implementation_staff);
                    acc.total_direct_costs += Number(this.calculateTotalDirectCosts(cur));
                    acc.overheads += Number(this.calculateTotalOverheads(cur));
                    acc.total += Number(this.calculateTotalBudget(cur));
                    acc.eea_grants = Math.round(((acc.total * 0.85) + Number.EPSILON) * 100) / 100;
                    acc.state_budget = Math.round(((acc.total * 0.15) + Number.EPSILON) * 100) / 100;

                    return acc;
                };

                totals = this.project.partners.reduce(summer, totals);

                return this.project.experts.reduce(summer, totals);
            },
            millionLimit: function() {
                let hasEstonia = false;
                let hasLithuania = false;
                let hasDonorCountry = false;

                this.project.partners.forEach(function(item) {
                    if (!item.is_hidden && typeof item.institution == 'object' && typeof item.institution.country_id == 'number') {
                        if (item.institution.country_id == 5) {
                            hasLithuania = true;
                        } else if(item.institution.country_id == 6) {
                            hasEstonia = true;
                        } else {
                            hasDonorCountry = true;
                        }
                    }
                });

                return (hasLithuania && hasEstonia && hasDonorCountry);
            },
            eightHundredLimit: function() {
                let hasEstonia = false;
                let hasLithuania = false;
                let hasDonorCountry = false;

                this.project.partners.forEach(function(item) {
                    if (!item.is_hidden && typeof item.institution == 'object' && typeof item.institution.country_id == 'number') {
                        if (item.institution.country_id == 5) {
                            hasLithuania = true;
                        } else if(item.institution.country_id == 6) {
                            hasEstonia = true;
                        } else {
                            hasDonorCountry = true;
                        }
                    }
                });

                return (hasLithuania || hasEstonia) && hasDonorCountry && !this.millionLimit;
            },
            partnersLimit: function() {
                let hasEstonia = false;
                let hasLithuania = false;
                let hasDonorCountry = false;

                let promoter = this.project.promoter;

                this.project.partners.forEach(function(item) {
                    if (item.institution.id !== promoter.id) {
                        let countryId = (typeof item.institution == 'object' && item.institution.country && typeof item.institution.country == 'object') ? item.institution.country.id : item.institution.country_id;
                        if (countryId == 5) {
                            hasLithuania = true;
                        } else if (countryId == 6) {
                            hasEstonia = true;
                        } else if(countryId && countryId != 1) {
                            hasDonorCountry = true;
                        }
                    }
                });

                return !((hasLithuania || hasEstonia) && hasDonorCountry);
            },
        },
        methods: {
            startDateSelected() {
                let endDate = new Date(this.project.started_at.getTime());
                endDate.setMonth(endDate.getMonth() + 36);
                this.$set(this.disabledEndDates, "to", endDate);
                this.staffDisabledStartDates.to = this.project.started_at;
            },
            endDateSelected() {
                let endDate = new Date(this.project.ended_at.getTime());
                endDate.setMonth(endDate.getMonth() - 36);
                this.$set(this.disabledStartDates, "from", endDate);

                let staffDisabledDate = new Date(this.project.ended_at);
                staffDisabledDate.setHours(0);
                staffDisabledDate.setDate(staffDisabledDate.getDate() + 1);
                this.staffDisabledEndDates.from = staffDisabledDate;
            },
            fileClick(e, index) {
                if (!index) {
                    let index = 0;
                }

                if (this.viewMode) {
                    e.preventDefault();
                } else {
                    if (e.target.files.length) {
                        this.$set(this.files, e.target.name, e.target.files[0].name)
                    }
                }
            },
            selectBlur(partner) {
                if (!partner.institution.id) {
                    partner.institution.title = this.tmp;
                    partner.institution.institution_text = this.tmp;
                }
            },
            addOtherFile() {
                this.project.other_files.push({});
            },
            researchFieldSelected() {
                variableAPI.index({type: 'research-field-detailed', parent_id: this.project.research_field})
                    .then(response => {
                        this.researchFieldsDetailed = response.data;
                    });
            },
            researchFieldPreselected(parentId) {
                variableAPI.index({type: 'research-field-detailed', parent_id: parentId})
                    .then(response => {
                        this.researchFieldsDetailed = response.data;
                    });
            },
            ris3FieldSelected() {
                variableAPI.index({type: 'ris3'})
                    .then(response => {
                        this.ris3Fields = response.data;
                    });
            },
            calculateTotalDirectCosts(partner) {
                let budget = partner.budget;

                let res = (budget.personnel_costs === '' ? 0 : parseFloat(budget.personnel_costs))
                + (budget.travel_allowances === '' ? 0 : parseFloat(budget.travel_allowances))
                + (budget.sub_contracting === '' ? 0 : parseFloat(budget.sub_contracting))
                + (budget.other_costs === '' ? 0 : parseFloat(budget.other_costs))
                + (budget.inventory_costs === '' ? 0 : parseFloat(budget.inventory_costs))
                + (budget.depreciation_costs === '' ? 0 : parseFloat(budget.depreciation_costs))
                + (budget.project_administrative_staff === '' ? 0 : parseFloat(budget.project_administrative_staff))
                + (budget.project_implementation_staff === '' ? 0 : parseFloat(budget.project_implementation_staff));

                return res.toFixed(2);
            },
            calculateTotalOverheads(partner) {
                let budget = partner.budget;

                if (partner.norwegian && partner.standard_budget) {
                    return 0;
                } else {
                    return (0.25 * (this.calculateTotalDirectCosts(partner) - (budget.sub_contracting === '' ? 0 : parseFloat(budget.sub_contracting)))).toFixed(2);
                }
            },
            calculateTotalBudget(partner) {
                return (Number(this.calculateTotalDirectCosts(partner)) + Number(this.calculateTotalOverheads(partner))).toFixed(2);
            },
            submit(e) {
                let self = this;
                researchProjectAPI
                    .submit(
                        this.projectId, new FormData(e.target.form)
                    )
                    .then(function (response) {
                        window.location.replace('/research-projects/' + response.data.id)
                    })
                    .catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('researchProject/setErrors', error.response.data.errors);
                        }
                    });

                return '';
            },
            submitDraft(e) {
                var self = this;
                if (this.projectId) {
                    researchProjectAPI.update(
                        this.projectId,
                        new FormData(e.target.form)
                    ).then(function (response) {
                        window.location.replace('/research-projects/' + self.projectId + '/edit')
                    }).catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('researchProject/setErrors', error.response.data.errors);
                        }
                    });
                } else {
                    researchProjectAPI.create(
                        new FormData(e.target.form)
                    ).then(function (response) {
                        window.location.replace('/research-projects/' + response.data.id + '/edit')
                    }).catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('researchProject/setErrors', error.response.data.errors);
                        }
                    });
                }
            },
            onPromoterSearch(keyword, loading) {
                if (!keyword) {
                    return;
                }

                this.tmp = keyword;

                loading(true);

                axios.get('/api/institutions', {params: {project: 'research', keyword: keyword }})
                    .then(response => {
                        this.promoterOptions = response.data;
                        loading(false);
                    });
            },
            addExpert() {
                if (this.viewMode) {
                    return;
                }

                this.project.experts.push({
                    name: '',
                    institution: '',
                    is_visible: true,
                    budget: {
                        personnel_costs: 0,
                        travel_allowances: 0,
                        sub_contracting: 0,
                        other_costs: 0,
                        total_direct_costs: 0,
                        overheads: 0,
                        inventory_costs: 0,
                        depreciation_costs: 0,
                        project_administrative_staff: 0,
                        project_implementation_staff: 0,
                    }
                });
            },
            addPartner(institution = null) {
                if (this.viewMode) {
                    return;
                }

                let partner = {
                    id: null,
                    institution: {},
                    researchStaff: [],
                    contact: {},
                    representative: {},
                    is_visible: true,
                    notAdded: true,
                    budget: {
                        personnel_costs: 0,
                        travel_allowances: 0,
                        sub_contracting: 0,
                        other_costs: 0,
                        total_direct_costs: 0,
                        overheads: 0,
                        total: 0,
                        inventory_costs: 0,
                        depreciation_costs: 0,
                        project_administrative_staff: 0,
                        project_implementation_staff: 0,
                    }
                };
                this.project.partners.forEach(e => e.is_visible = false);

                if (institution !== null) {
                    partner.is_hidden = true;
                    partner.institution = institution;
                    partner.is_visible = false;
                    partner.notAdded = false;
                    if (typeof institution.country !== 'undefined') {
                        partner.institution.country = institution.country.title;
                    }
                }

                this.project.partners.push(partner);
            },
            deletePartner(partner, index) {
                if (partner.id) {
                    researchProjectAPI.deleteParticipant(partner.id)
                        .then(response => {
                            this.project.partners.splice(index, 1);
                        });
                } else {
                    this.project.partners.splice(index, 1);
                }
                window.toastr.success('Your request successfully completed!')
            },
            deleteExpert(expert, index) {
                if (expert.id) {
                    researchProjectAPI.deleteParticipant(expert.id)
                        .then(response => {
                            this.project.experts.splice(index, 1);
                        });
                } else {
                    this.project.experts.splice(index, 1);
                }
                window.toastr.success('Your request successfully completed!')
            },
            deleteResearchStaff(partner, staff, index) {
                if (staff.id) {
                    researchProjectAPI.deleteParticipant(staff.id)
                        .then(response => {
                            partner.researchStaff.splice(index, 1);
                        });
                } else {
                    partner.researchStaff.splice(index, 1);
                }
                window.toastr.success('Your request successfully completed!')
            },
            addResearchStaff(partner) {
                if (this.viewMode) {
                    return;
                }

                partner.researchStaff.forEach(e => e.is_visible = false);
                partner.researchStaff.push({
                    name: '',
                    degree: '',
                    position: '',
                    participation_start: '',
                    participation_end: '',
                    is_visible: true,
                    notAdded: true,
                });
            },
            deleteFile(file) {
                researchProjectAPI.deleteFile(this.project.id, file.id)
                    .then(response => {
                        Vue.delete(this.project, file.type + '_file');
                    });
            },
            deleteOtherFile(file, key) {
                researchProjectAPI.deleteFile(this.project.id, file.id)
                    .then(response => {
                        this.project.other_files.splice(key, 1);
                    });
            },
            submitPartner(partner) {
                Vue.set(partner, 'is_visible', false);
                Vue.set(partner, 'notAdded', false);
            },
            makePartnerVisible(partner) {
                this.project.partners.filter(e => e !== partner).forEach(e => e.is_visible = false);
                this.project.partners = this.project.partners.filter(e => !e.notAdded);
                partner.is_visible = true;
            },
            submitResearchStaff(rs) {
                Vue.set(rs, 'is_visible', false);
                Vue.set(rs, 'notAdded', false);
            },
            makeResearchStaffVisible(partner, rs) {
                partner.researchStaff.filter(e => e !== rs).forEach(e => e.is_visible = false);
                partner.researchStaff = partner.researchStaff.filter(e => !e.notAdded);
                rs.is_visible = true;
            },
            filterAddedResearchStaff(partner) {
                return partner.researchStaff.filter(p => !p.notAdded);
            },
            hasError(error) {
                for (let e in this.errors) {
                    if (e.startsWith(error)) {
                        return true;
                    }
                }
                return false;
            }
        },
    }
</script>
