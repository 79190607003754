export default {
    index (filter) {
        return axios.get('/api/users', {withCredentials: true, params: filter});
    },
    store (data) {
        return axios.post('/api/users', data);
    },
    edit (data) {
        return axios.post(`/api/users/${data.get('id')}`, data);
    },
    deleteFile (userId) {
        return axios.post(`/api/users/${userId}/delete-file`);
    },
    destroy (id) {
        return axios.delete('/api/users/' + id);
    },
    me () {
        return axios.get('/api/me');
    }
}
