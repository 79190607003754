<template>
    <form>
        <div>
            <div class="row">
                <div class="col text-center" v-if="projectId && !viewMode">
                    <h1 class="mt-5">
                        <span>{{ __("edit_project") }} </span>
                    </h1>
                    <div class="text-muted" v-if="project.institution">
                        {{ project.uid }} ({{ project.institution.title }})
                    </div>
                </div>
                <div class="col text-center" v-else-if="projectId && viewMode">
                    <h1 class="mt-5">
                        <span>{{ __("show_project") }} </span>
                    </h1>
                    <div class="text-muted" v-if="project.institution">
                        {{ project.uid }} ({{ project.institution.title }})
                    </div>
                </div>
                <div class="col text-center" v-else>
                    <h1 class="mt-5">
                        <span>{{ __('scholarships.start_new_project') }}</span>
                    </h1>
                    <p class="subtitle mt-3">
                        {{ __('scholarships.start_new_project_description') }}
                    </p>
                </div>
            </div>

            <span class="anchor-container">
                <span id="step-1" class="anchor" style="top: -50px;"></span>
            </span>
            <div class="main-box">
                <div class="text-center mb-3">
                    <img class="logo" src="/images/rsgrants-logo.png" alt />
                    <img class="logo" src="/images/viaa-logo.png" alt />
                </div>
                <div class="step-heading d-flex align-items-center">
                    <div class="num">
                        1
                    </div>
                    <div class="txt">
                        <div class="h3">
                            {{ __('scholarships.step1_title') }}
                        </div>
                        <p>
                            {{ __('scholarships.step1_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-title_lv">{{ __('scholarships.step1_field_project_title_lv') }}</label>
                            <div :class="['form-control-holder', errors['title_lv'] ? 'danger-mark' : '']">
                                <input class="form-control"
                                       id="inp-title_lv"
                                       name="title_lv"
                                       type="text"
                                       :readonly="viewMode"
                                       v-model="project.title_lv"
                                />
                            </div>
                            <div class="danger-message" v-if="errors['title_lv']">
                                {{ errors['title_lv'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-title_en">{{ __('scholarships.step1_field_project_title_en') }}</label>
                            <div :class="['form-control-holder', errors['title_en'] ? 'danger-mark' : '']">
                                <input
                                        :readonly="viewMode"
                                        class="form-control"
                                        id="inp-title_en"
                                        name="title_en"
                                        type="text"
                                        v-model="project.title_en"
                                />
                            </div>
                            <div class="danger-message" v-if="errors['title_en']">
                                {{ errors['title_en'][0] }}
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>{{ __('scholarships.step1_field_project_start') }}</label>
                            <div class="form-control-holder">
                                <input class="form-control" readonly type="text" name="started_at" v-model="project.started_at" v-if="viewMode">
                                <datepicker
                                        :disabled-dates="disabledStartDates"
                                        :disabledPicker="viewMode"
                                        :bootstrap-styling="true"
                                        name="started_at"
                                        type="date"
                                        v-model="project.started_at"
                                        v-else
                                ></datepicker>
                            </div>

                            <div class="danger-message" v-if="errors['started_at']">
                                {{ errors['started_at'][0] }}
                            </div>

                            <small class="d-flex align-items-center text-muted mt-2">
                                <div class="px-3">DD</div>
                                <div class="px-3">MM</div>
                                <div class="px-3">YYYY</div>
                            </small>
                        </div>

                        <div class="form-group col-md-4">
                            <label>{{ __('scholarships.step1_field_project_end') }}
                                <button type="button" data-toggle="popover" class="btn btn-link popover-link p-0" title="Project end date"
                                        data-content="Project end date is calculated automatically when a specific project duration is set. The latest project end date can be 30 Apr 2024.">
                                    (?)
                                </button>
                            </label>
                            <div :class="['form-control-holder', (new Date(project.ended_at).getTime() >= new Date(2024, 4, 1).getTime()) ? 'danger-mark' : '']">
                                <input class="form-control" readonly name="ended_at" type="text" v-model="project.ended_at">
                            </div>

                            <div class="danger-message" v-if="errors['ended_at']">
                                {{ errors['ended_at'][0] }}
                            </div>

                            <small class="d-flex align-items-center text-muted mt-2">
                                <div class="px-3">DD</div>
                                <div class="px-3">MM</div>
                                <div class="px-3">YYYY</div>
                            </small>
                        </div>

                        <div v-if="!(typeof this.project.created_at === 'undefined' || new Date(this.project.created_at).getTime() >= new Date(2022, 1, 1).getTime())" class="form-group col-md-3">
                            <label for="inp-duration">{{ __('scholarships.step1_field_project_duration') }}</label>
                            <div class="form-control-holder">
                                <select name="duration" :readonly="(!project.started_at || viewMode)" class="form-control" id="inp-duration" @change="durationSelected()" v-model.number="project.duration">
                                    <option></option>
                                    <option value="12">12</option>
                                    <template v-if="new Date(project.started_at).getTime() <= new Date(2022, 6, 1).getTime()">
                                        <option value="24">24</option>
                                        <option value="30">30</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>{{ __('scholarships.step1_field_total_cost') }}</label>
                            <div class="form-control-holder">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">€</span>
                                    <input class="form-control"
                                            name="eea_grants"
                                            type="text"
                                            :value="calculateTotalCost()"
                                            disabled/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label>EEA Grants <small>85% of total cost</small></label>
                            <div class="form-control-holder light-mark">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">€</span>
                                    <input
                                        class="form-control"
                                        name="eea_grants"
                                        type="text"
                                        v-model="eeaGrants"
                                        disabled
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label>State Budget Co-Financing <small>15% of total cost</small></label>
                            <div class="form-control-holder light-mark">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">€</span>
                                    <input
                                        class="form-control"
                                        name="state_budget"
                                        type="text"
                                        v-model="stateBudget"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-12 d-flex align-items-start">
                            <div class="info-icon mr-3 mt-1">i</div>
                            <p>
                                {{ __('scholarships.step1_info_project_summary') }}
                            </p>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label class="d-block" for="inp-summary_en">{{ __('scholarships.step1_field_project_summary_en') }}
                                <div class="area-counter float-right">{{ project.summary_en ? project.summary_en.length : 0 }}/<strong>2000</strong></div>
                            </label>
                            <div :class="['form-control-holder', errors['summary_en'] ? 'danger-mark' : '']">
                            <textarea :readonly="viewMode"
                                      class="form-control"
                                      id="inp-summary_en"
                                      name="summary_en"
                                      rows="5" v-model="project.summary_en"
                                      maxlength="2000"></textarea>

                                <div class="danger-message" v-if="errors['summary_en']">
                                    {{ errors['summary_en'][0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="h3 color-blue">
                    Project’s Promoter
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>Name</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text" v-model="project.promoter.title"/>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Registration No.</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text"
                                       v-model="project.promoter.registration_number"/>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Legal Address</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text" v-model="project.promoter.address"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>Country</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text" v-model="project.promoter.country.title"/>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label>E-mail</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="email" v-model="project.promoter.email"/>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Website</label>
                            <div class="form-control-holder">
                                <input class="form-control" disabled type="text" v-model="project.promoter.website"/>
                            </div>
                        </div>
                    </div>

                    <div class="h3 color-blue mt-4">
                        {{ __('scholarships.step1_title_project_contact_person') }}
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-coordinator-name">{{ __('scholarships.step1_field_contact_name') }}</label>
                            <div class="form-control-holder light-mark" :class="[errors['coordinator.name'] ? 'danger-mark' : '']">
                                <input :readonly="viewMode" class="form-control"
                                       id="inp-coordinator-name" name="coordinator[name]"
                                       type="text" v-model="project.coordinator.name">
                                <span class="danger-message" role="alert" v-if="errors['coordinator.name']">
                                    {{ errors['coordinator.name'][0] }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-coordinator-position">{{ __('scholarships.step1_field_contact_position') }}</label>
                            <div class="form-control-holder light-mark" :class="[errors['coordinator.position'] ? 'danger-mark' : '']">
                                <input :readonly="viewMode" class="form-control"
                                       id="inp-coordinator-position" name="coordinator[position]"
                                       type="text" v-model="project.coordinator.position">
                                <span class="danger-message" role="alert" v-if="errors['coordinator.position']">
                                    {{ errors['coordinator.position'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-coordinator-phone">{{ __('scholarships.step1_field_contact_number') }}
                                <small class="text-uppercase">{{ __('scholarships.step1_field_contact_number_with_code') }}</small>
                            </label>
                            <div class="form-control-holder light-mark" :class="[errors['coordinator.phone'] ? 'danger-mark' : '']">
                                <input :readonly="viewMode" class="form-control"
                                       id="inp-coordinator-phone" name="coordinator[phone]"
                                       type="text" v-model="project.coordinator.phone">
                                <span class="danger-message" role="alert" v-if="errors['coordinator.phone']">
                                    {{ errors['coordinator.phone'][0] }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-coordinator-email">{{ __('scholarships.step1_field_contact_email') }}</label>
                            <div class="form-control-holder light-mark" :class="[errors['coordinator.email'] ? 'danger-mark' : '']">
                                <input :readonly="viewMode" class="form-control"
                                       id="inp-coordinator-email" name="coordinator[email]"
                                       type="text" v-model="project.coordinator.email">
                                <span class="danger-message" role="alert" v-if="errors['coordinator.email']">
                                    {{ errors['coordinator.email'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="h3 color-blue mt-4">
                        Legal Representative
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-representative-name">{{ __('scholarships.step1_field_contact_name') }}</label>
                            <div class="form-control-holder light-mark" :class="[errors['representative.name'] ? 'danger-mark' : '']">
                                <input
                                    :readonly="viewMode"
                                    class="form-control"
                                    id="inp-representative-name"
                                    name="representative[name]"
                                    type="text"
                                    v-model="project.representative.name">
                                <span class="danger-message" role="alert" v-if="errors['representative.name']">
                                    {{ errors['representative.name'][0] }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-representative-position">{{ __('scholarships.step1_field_contact_position') }}</label>
                            <div class="form-control-holder light-mark" :class="[errors['representative.position'] ? 'danger-mark' : '']">
                                <input
                                    :readonly="viewMode"
                                    class="form-control"
                                    id="inp-representative-position"
                                    name="representative[position]"
                                    type="text"
                                    v-model="project.representative.position">
                                <span class="danger-message" role="alert" v-if="errors['representative.position']">
                                    {{ errors['representative.position'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label for="inp-representative-phone">
                                {{ __('scholarships.step1_field_contact_number') }}
                                <small class="text-uppercase">{{ __('scholarships.step1_field_contact_number_with_code') }}</small>
                            </label>
                            <div class="form-control-holder light-mark" :class="[errors['representative.phone'] ? 'danger-mark' : '']">
                                <input
                                    :readonly="viewMode"
                                    class="form-control"
                                    id="inp-representative-phone"
                                    name="representative[phone]"
                                    type="text"
                                    v-model="project.representative.phone">
                                <span class="danger-message" role="alert" v-if="errors['representative.phone']">
                                    {{ errors['coordinator.phone'][0] }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inp-representative-email">{{ __('scholarships.step1_field_contact_email') }}</label>
                            <div class="form-control-holder light-mark" :class="[errors['representative.email'] ? 'danger-mark' : '']">
                                <input
                                    :readonly="viewMode"
                                    class="form-control"
                                    id="inp-representative-email"
                                    name="representative[email]"
                                    type="text"
                                    v-model="project.representative.email">
                                <span class="danger-message" role="alert" v-if="errors['representative.email']">
                                    {{ errors['representative.email'][0] }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="h3 color-blue">
                    Project’s Partner(s)
                </div>

                <div class="box-content">
                    <table class="table mb-5" v-if="addedPartners.length">
                        <thead>
                        <tr>
                            <th width="1px"></th>
                            <th scope="col">INSTITUTION TITLE</th>
                            <th scope="col">CONTACT PERSON</th>
                            <th scope="col">LEGAL REPRESENTATIVE</th>
                            <th scope="col">LETTER OF INTENT</th>
                            <th scope="col" width="1px"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(partner, pindex) in addedPartners" v-if="partner.is_hidden !== true">
                            <td>
                                <span v-if="hasError('partners.' + pindex)">
                                    <span class="danger-icon">!</span>
                                </span>
                            </td>
                            <td>
                                {{ partner.institution.title }}
                                <span class="text-muted d-block">
                                {{ partner.institution.address }}
                            </span>
                            </td>
                            <td>{{ partner.contact.name }}</td>
                            <td>{{ partner.representative.name }}</td>
                            <td>
                                <a :href="partner.letter_of_intent_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" style="margin-top: -2px;" target="_blank" v-if="partner.letter_of_intent_file">
                                    <i class="fas fa-cloud-download-alt"></i>
                                </a>
                            </td>
                            <td class="text-nowrap">
                                <button type="button" class="btn btn-outline-primary btn-rounded"
                                        v-on:click="makePartnerVisible(partner)">
                                    <i class="fas fa-eye" v-if="viewMode"></i>
                                    <i class="fas fa-pencil-alt" v-else></i>
                                </button>

                                <button type="button" class="btn btn-outline-danger btn-rounded"
                                        v-on:click="deletePartner(partner, pindex)" v-if="!viewMode">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div v-if="addedPartners.length === 0" :class="[!viewMode ? 'mb-5' : '']">
                        <div class="danger-icon mr-2" v-if="viewMode">!</div> Project's partners is not defined.
                    </div>
                    <div>
                        <button v-show="!viewMode"
                                :class="['btn btn-lg btn-outline-secondary', project.partners.length ? 'mb-4' : '']"
                                @click="addPartner()"
                                type="button">
                            <i class="fas fa-plus mr-2"></i> <strong>Add Partner</strong>
                        </button>
                    </div>

                    <div v-for="(partner, pindex) in project.partners">
                        <div :class="[!partner.is_visible ? 'd-none': '']" class="partner-item">
                            <div class="divider mt-2"></div>
                            <input type="hidden"
                                   :id="'inp-id-' + pindex"
                                   :name="'partners[' + pindex + '][id]'"
                                   class="form-control"
                                   v-model="partner.id">

                            <div class="form-row mt-4">
                                <div class="form-group col-md-4">
                                    <label :for="'inp-name-' + pindex">Name</label>
                                    <div class="form-control-holder light-mark">
                                        <input type="text" v-if="viewMode" :value="(partner.institution.institution_text) ? partner.institution.institution_text : partner.institution.id" class="form-control" readonly="readonly" disabled>
                                        <v-select v-else :clearable="false"
                                                  @search:blur="selectBlur(partner)"
                                                  :create-option="r => ({ title: r, institution_text: r, registration_number: '', address: '', country: '', email: '', website: '' })"
                                                  :filterable="false"
                                                  :id="'inp-name-' + pindex"
                                                  :options="promoterOptions"
                                                  @search="onPromoterSearch"
                                                  label="title"
                                                  taggable
                                                  v-model="partner.institution">
                                            <template slot="no-options">
                                                Start typing
                                            </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.title }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.title }}
                                                </div>
                                            </template>
                                        </v-select>

                                        <input :name="'partners[' + pindex + '][institution][id]'"
                                               class="form-control"
                                               type="hidden"
                                               v-model="partner.institution.id"
                                               :readonly="viewMode">
                                        <input :name="'partners[' + pindex + '][institution][institution_text]'"
                                               class="form-control"
                                               type="hidden"
                                               v-model="partner.institution.institution_text"
                                               :readonly="viewMode">
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label :for="'inp-institution-registration_number-' + pindex">Registration
                                        No.</label>
                                    <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.institution.registration_number'] ? 'danger-mark' : '']">
                                        <input
                                            :readonly="viewMode || partner.institution.id"
                                            :id="'inp-institution-registration_number-' + pindex"
                                            :name="'partners[' + pindex + '][institution][registration_number]'"
                                            class="form-control"
                                            type="text"
                                            v-model="partner.institution.registration_number">
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.registration_number']">
                                            {{ errors['partners.' + pindex + '.institution.registration_number'][0] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label :for="'inp-partner-address-' + pindex">Address</label>
                                    <div class="form-control-holder light-mark" :class="[ errors['partners.' + pindex + '.institution.address'] ? 'danger-mark' : '']">
                                        <input :readonly="viewMode || partner.institution.id" :id="'inp-partner-address-' + pindex"
                                               :name="'partners[' + pindex + '][institution][address]'"
                                               class="form-control"
                                               type="text"
                                               v-model="partner.institution.address">
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.address']">
                                            {{ errors['partners.' + pindex + '.institution.address'][0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mt-4">
                                <div class="form-group col-md-4">
                                    <label :for="'inp-partner-country-' + pindex">Country</label>
                                    <div class="form-control-holder light-mark" :class="[ errors['partners.' + pindex + '.institution.country'] ? 'danger-mark' : '']">
                                        <input v-if="viewMode || partner.institution.id" disabled :id="'inp-partner-country-' + pindex"
                                               :name="'partners[' + pindex + '][institution][country]'"
                                               class="form-control"
                                               type="text"
                                               v-model="(partner.institution.country) ? partner.institution.country.title : partner.institution.country_text">
                                        <div v-else>
                                            <select v-model="partner.institution.country_id" class="form-control" :name="'partners[' + pindex + '][institution][country_id]'" :id="'inp-partner-country-' + pindex">
                                                <option v-for="c in selectCountries" :value="c.id" :key="c.id">{{ c.title }}</option>
                                            </select>
                                            <input class="form-control" v-model="partner.institution.country_text" v-if="!partner.institution.id && partner.institution.country_id === null" type="text" :name="'partners[' + pindex + '][institution][country_text]'" >
                                        </div>
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.country']">
                                            {{ errors['partners.' + pindex + '.institution.country'][0] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label :for="'inp-partner-email-' + pindex">E-mail</label>
                                    <div class="form-control-holder light-mark" :class="[ errors['partners.' + pindex + '.institution.email'] ? 'danger-mark' : '']">
                                        <input :readonly="viewMode || partner.institution.id" :id="'inp-partner-email-' + pindex"
                                               :name="'partners[' + pindex + '][institution][email]'"
                                               class="form-control"
                                               type="text"
                                               v-model="partner.institution.email">
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.email']">
                                            {{ errors['partners.' + pindex + '.institution.email'][0] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label :for="'inp-partner-website-' + pindex">Website</label>
                                    <div class="form-control-holder light-mark" :class="[ errors['partners.' + pindex + '.institution.website'] ? 'danger-mark' : '']">
                                        <input :readonly="viewMode || partner.institution.id" :id="'inp-partner-website-' + pindex"
                                               :name="'partners[' + pindex + '][institution][website]'"
                                               class="form-control"
                                               type="text"
                                               v-model="partner.institution.website">
                                        <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.institution.website']">
                                            {{ errors['partners.' + pindex + '.institution.website'][0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mt-4">
                                <div class="form-group col-md-12">
                                    <label :for="'inp-partner-letter-' + pindex">Letter of Intent</label>

                                    <div class="mb-3 file-preview d-flex align-items-center" v-if="partner.letter_of_intent_file">
                                        <input type="hidden"  :name="'partners[' + pindex + '][letter]'" value="1" v-if="viewMode">

                                        <div class="file-format-icon">
                                            <i class="far fa-file-pdf color-blue"></i>
                                        </div>

                                        <span class="font-italic file-name">{{ partner.letter_of_intent_file.title }}</span>
                                        <small class="text-muted">{{ partner.letter_of_intent_file.size_in_kb }} KB</small>

                                        <a :href="partner.letter_of_intent_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" style="margin-top: -2px;" target="_blank">
                                            <i class="fas fa-cloud-download-alt"></i>
                                        </a>
                                        <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(partner.letter_of_intent_file)">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </div>

                                    <div  :class="['form-control-holder light-mark', errors['partners.' + pindex + '.letter'] ? 'danger-mark' : '']" v-else>
                                        <div class="input-group file-upload mb-3">
                                            <div class="input-group-prepend">
                                        <span class="input-group-text"><i
                                            class="fas fa-cloud-upload-alt"></i></span>
                                            </div>
                                            <div class="custom-file">
                                                <input
                                                    @change="fileClick($event)"
                                                    :readonly="viewMode"
                                                    :id="'inp-partner-letter-' + pindex"
                                                    :name="'partners[' + pindex + '][letter]'"
                                                    class="custom-file-input"
                                                    type="file">
                                                <label :for="'inp-partner-letter-' + pindex" class="custom-file-label">
                                                    {{ files['partners[' + pindex + '][letter]'] ? files['partners[' + pindex + '][letter]'] : "Upload a Document" }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <small>* Only .pdf (4mb)</small>

                                    <div class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.letter']">
                                    {{ errors['partners.' + pindex + '.letter'][0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-6">
                                    <div class="h5">Contact person</div>

                                    <div class="form-row mt-4">
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-contact-email-' + pindex">
                                                Name, Surname
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.contact.name'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-contact-name-' + pindex"
                                                       :name="'partners[' + pindex + '][contact][name]'"
                                                       class="form-control"
                                                       type="text"
                                                       v-model="partner.contact.name">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.contact.name']">
                                                    {{ errors['partners.' + pindex + '.contact.name'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-contact-email-' + pindex">Position</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.contact.position'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-contact-position-' + pindex"
                                                       :name="'partners[' + pindex + '][contact][position]'"
                                                       class="form-control"
                                                       type="text" v-model="partner.contact.position">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.contact.position']">
                                                    {{ errors['partners.' + pindex + '.contact.position'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row mt-4">
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-contact-email-' + pindex">Phone No.</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.contact.phone'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-contact-phone-' + pindex"
                                                       :name="'partners[' + pindex + '][contact][phone]'"
                                                       class="form-control"
                                                       type="text"
                                                       v-model="partner.contact.phone">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.contact.phone']">
                                                    {{ errors['partners.' + pindex + '.contact.phone'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-contact-email-' + pindex">E-mail</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.contact.email'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-contact-email-' + pindex"
                                                       :name="'partners[' + pindex + '][contact][email]'"
                                                       class="form-control"
                                                       type="text"
                                                       v-model="partner.contact.email">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.contact.email']">
                                                    {{ errors['partners.' + pindex + '.contact.email'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="h5">Legal Representative</div>

                                    <div class="form-row mt-4">
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-representative-email-' + pindex">
                                                Name, Surname
                                            </label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.representative.name'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-representative-name-' + pindex"
                                                       :name="'partners[' + pindex + '][representative][name]'"
                                                       class="form-control"
                                                       type="text" v-model="partner.representative.name">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.representative.name']">
                                                    {{ errors['partners.' + pindex + '.representative.name'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-representative-email-' + pindex">Position</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.representative.position'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-representative-position-' + pindex"
                                                       :name="'partners[' + pindex + '][representative][position]'"
                                                       class="form-control"
                                                       type="text"
                                                       v-model="partner.representative.position">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.representative.position']">
                                                    {{ errors['partners.' + pindex + '.representative.position'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row mt-4">
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-representative-email-' + pindex">Phone</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.representative.phone'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-representative-phone-' + pindex"
                                                       :name="'partners[' + pindex + '][representative][phone]'"
                                                       class="form-control"
                                                       type="text" v-model="partner.representative.phone">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.representative.phone']">
                                                    {{ errors['partners.' + pindex + '.representative.phone'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label :for="'inp-partner-representative-email-' + pindex">E-mail</label>
                                            <div class="form-control-holder light-mark" :class="[errors['partners.' + pindex + '.representative.email'] ? 'danger-mark' : '']">
                                                <input :readonly="viewMode"
                                                       :id="'inp-partner-representative-email-' + pindex"
                                                       :name="'partners[' + pindex + '][representative][email]'"
                                                       class="form-control"
                                                       type="text" v-model="partner.representative.email">
                                                <span class="danger-message" role="alert" v-if="errors['partners.' + pindex + '.representative.email']">
                                                    {{ errors['partners.' + pindex + '.representative.email'][0] }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button type="button" class="mt-3 px-5 btn btn-lg btn-primary"
                                        @click="submitPartner(partner)">
                                    <span v-if="viewMode">Close</span>
                                    <span v-else>Submit</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-2" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">2</div>
                    <div class="txt">
                        <div class="h3">
                            {{ __('scholarships.step2_title') }}
                        </div>
                        <p v-if="typeof this.project.created_at === 'undefined' || new Date(this.project.created_at).getTime() >= new Date(2022, 1, 1).getTime()">
                            {{ __('scholarships.step2_description_round_two') }}
                        </p>
                        <p v-else>
                            {{ __('scholarships.step2_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-12 d-flex align-items-start">
                            <div class="info-icon mr-3 mt-1">i</div>
                            <p>
                                Distance category must be indicated using the distance calculator supported <a href="https://ec.europa.eu/programmes/erasmus-plus/resources/distance-calculator_en">by the
                                European Commission</a> by entering the location (city) of the sending and receiving institutions.
                            </p>
                        </div>
                    </div>

                    <table class="table mb-5" v-if="addedMobilityFlows.length">
                        <thead>
                        <tr>
                            <th scope="col">MOBILITY FLOW</th>
                            <th scope="col">Incoming / outgoing</th>
                            <th scope="col">Number of participants</th>

                            <th scope="col">Partner institution</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Distance category</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(mobilityFlow, mfindex) in addedMobilityFlows">
                            <td>
                                <span v-if="hasError('mobility_flows.' + mfindex)">!</span>
                                {{ mobilityFlow.type }}{{ getMobilityFlowTypeNumber(mobilityFlow)}} {{ getMobilityFlowTypeDescription(mobilityFlow)}}
                            </td>
                            <td>{{ mobilityFlow.direction }}</td>
                            <td>{{ mobilityFlow.participants }}</td>
                            <td><strong>{{ mobilityFlow.institution !== null ? allInstitutions[mobilityFlow.institution].title : '' }}</strong></td>
                            <td>
                                {{ mobilityFlow.duration }}
                                {{ mobilityFlow.duration && mobilityFlow.type === 'A' ? 'months' : '' }}
                                {{ mobilityFlow.duration && (mobilityFlow.type === 'B' || mobilityFlow.type === 'C') ? 'weeks' : '' }}
                                {{ mobilityFlow.duration && (mobilityFlow.type === 'AS' || mobilityFlow.type === 'BS' || mobilityFlow.type === 'CS') ? 'working days' : '' }}
                                {{ mobilityFlow.duration && (mobilityFlow.type === 'AO' || mobilityFlow.type === 'BO' || mobilityFlow.type === 'CO') ? 'hours' : '' }}
                            </td>
                            <td>{{ mfDistanceLabel(mobilityFlow.distance) }}</td>
                            <td class="text-nowrap">
                                <button type="button" @click="makeMobilityFlowVisible(mobilityFlow)" class="btn btn-outline-primary btn-rounded">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>

                                <button type="button" class="btn btn-outline-danger btn-rounded"
                                        v-on:click="deleteMobilityFlow(mobilityFlow, mfindex)" v-if="!viewMode">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>

                        <tfoot>
                        <tr>
                            <td colspan="2">
                                <span class="text-uppercase color-blue"><strong> Total participants</strong></span>
                            </td>
                            <td colspan="5">
                                <div class="form-control-holder text-right row">
                                    <input type="text" class="form-control col-3" :value="calculateAllMFParticipantsSum()" disabled>
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    </table>

                    <div>
                        <button v-show="!viewMode"
                                :class="['btn btn-lg btn-outline-secondary', project.mobility_flows.length ? 'mb-4' : '']"
                                @click="addMobilityFlow()"
                                type="button" >
                            <i class="fas fa-plus mr-2"></i>
                            <strong>Add Mobility Flow</strong>
                        </button>
                    </div>

                    <div v-for="(mobilityFlow, mfindex) in project.mobility_flows">
                        <div :class="[!mobilityFlow.is_visible ? 'd-none': '']" class="partner-item">
                            <div class="divider mt-2"></div>
                            <input type="hidden"
                                   :id="'inp-id-' + mfindex"
                                   :name="'mobility_flows[' + mfindex + '][id]'"
                                   class="form-control"
                                   v-model="mobilityFlow.id">
                            <div class="form-row mt-4">
                                <div class="form-group col-md-4">
                                    <label :for="'inp-type-' + mfindex">Mobility Flow</label>
                                    <div class="form-control-holder" :class="[errors['mobility_flows.' + mfindex + '.type'] ? 'danger-mark' : '']">
                                        <select :id="'inp-type-' + mfindex"
                                                v-model="mobilityFlow.type"
                                                class="form-control"
                                                :name="'mobility_flows[' + mfindex + '][type]'">
                                            <option value="A">A student flow</option>
                                            <option value="AS">AS Student shortened mobility</option>
                                            <option value="AO">AO Student online mobility</option>
                                            <option value="B">B academic staff flow</option>
                                            <option value="BS">BS Academic staff shortened mobility</option>
                                            <option value="BO">BO Academic staff online mobility</option>
                                            <option value="C">C administrative staff flow</option>
                                            <option value="CS">CS Administrative staff shortened mobility</option>
                                            <option value="CO">CO Administrative staff online mobility</option>
                                        </select>
                                        <span class="danger-message" role="alert" v-if="errors['mobility_flows.' + mfindex + '.type']">
                                            {{ errors['mobility_flows.' + mfindex + '.type'][0] }}
                                        </span>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label :for="'inp-direction-' + mfindex" class="info-label">Incoming / Outgoing
                                        <button type="button" data-toggle="popover" class="btn btn-link popover-link p-0"
                                                data-content="Incoming: from donorstates (Norway, Lichtenstein, Iceland) to Latvia. Outgoing: from Latvia to donorstates (Norway, Lichtenstein, Iceland).">
                                            (?)
                                        </button>
                                    </label>
                                    <div class="form-control-holder" :class="[errors['mobility_flows.' + mfindex + '.direction'] ? 'danger-mark' : '']">
                                        <select :id="'inp-direction-' + mfindex"
                                                v-model="mobilityFlow.direction"
                                                class="form-control"
                                                :name="'mobility_flows[' + mfindex + '][direction]'">
                                            <option value="Incoming">Incoming</option>
                                            <option value="Outgoing">Outgoing</option>
                                        </select>
                                        <span class="danger-message" role="alert" v-if="errors['mobility_flows.' + mfindex + '.direction']">
                                            {{ errors['mobility_flows.' + mfindex + '.direction'][0] }}
                                        </span>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label :for="'inp-institution-' + mfindex">Partner Institution</label>
                                    <div class="form-control-holder" :class="[errors['mobility_flows.' + mfindex + '.institution'] ? 'danger-mark' : '']">
                                        <select :id="'inp-institution-' + mfindex"
                                                v-model="mobilityFlow.institution"
                                                class="form-control"
                                                :name="'mobility_flows[' + mfindex + '][institution]'">
                                            <option v-for="(institution, iindex) in allInstitutions" :value="iindex">{{institution.title}}</option>
                                        </select>
                                        <span class="danger-message" role="alert" v-if="errors['mobility_flows.' + mfindex + '.institution']">
                                            {{ errors['mobility_flows.' + mfindex + '.institution'][0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row mt-4">
                                <div class="form-group col-md-4">
                                    <label :for="'inp-duration-' + mfindex">Duration</label>
                                    <div class="form-control-holder" :class="[errors['mobility_flows.' + mfindex + '.duration'] ? 'danger-mark' : '']">
                                        <select :id="'inp-duration-' + mfindex"
                                                v-model="mobilityFlow.duration"
                                                class="form-control"
                                                :name="'mobility_flows[' + mfindex + '][duration]'">
                                            <template v-if="mobilityFlow.type === 'A'">
                                                <option value="3">3 months</option>
                                                <option value="3.5">3.5 months</option>
                                                <option value="4">4 months</option>
                                                <option value="4.5">4.5 months</option>
                                                <option value="5">5 months</option>
                                                <option value="5.5">5.5 months</option>
                                                <option value="6">6 months</option>
                                                <option value="6.5">6.5 months</option>
                                                <option value="7">7 months</option>
                                                <option value="7.5">7.5 months</option>
                                                <option value="8">8 months</option>
                                                <option value="8.5">8.5 months</option>
                                                <option value="9">9 months</option>
                                                <option value="9.5">9.5 months</option>
                                                <option value="10">10 months</option>
                                                <option value="10.5">10.5 months</option>
                                                <option value="11">11 months</option>
                                            </template>
                                            <template v-else-if="mobilityFlow.type === 'AS'">
                                                <option value="5">5 working days</option>
                                            </template>
                                            <template v-else-if="mobilityFlow.type === 'AO'">
                                                <option value="20">20 hours</option>
                                                <option value="21">21 hours</option>
                                                <option value="22">22 hours</option>
                                                <option value="23">23 hours</option>
                                                <option value="24">24 hours</option>
                                                <option value="25">25 hours</option>
                                                <option value="26">26 hours</option>
                                                <option value="27">27 hours</option>
                                                <option value="28">28 hours</option>
                                                <option value="29">29 hours</option>
                                                <option value="30">30 hours</option>
                                                <option value="31">31 hours</option>
                                                <option value="32">32 hours</option>
                                                <option value="33">33 hours</option>
                                                <option value="34">34 hours</option>
                                                <option value="35">35 hours</option>
                                                <option value="36">36 hours</option>
                                                <option value="37">37 hours</option>
                                                <option value="38">38 hours</option>
                                                <option value="39">39 hours</option>
                                                <option value="40">40 hours</option>
                                            </template>
                                            <template v-else-if="mobilityFlow.type === 'B' || mobilityFlow.type === 'C'">
                                                <option value="1">1 week</option>
                                                <option value="2">2 weeks</option>
                                                <option value="3">3 weeks</option>
                                                <option value="4">4 weeks</option>
                                            </template>
                                            <template v-else-if="mobilityFlow.type === 'BS' || mobilityFlow.type === 'CS'">
                                                <option value="2">2 working days</option>
                                            </template>
                                            <template v-else-if="mobilityFlow.type === 'BO' || mobilityFlow.type === 'CO'">
                                                <option value="8">8 hours</option>
                                                <option value="9">9 hours</option>
                                                <option value="10">10 hours</option>
                                                <option value="11">11 hours</option>
                                                <option value="12">12 hours</option>
                                                <option value="13">13 hours</option>
                                                <option value="14">14 hours</option>
                                                <option value="15">15 hours</option>
                                                <option value="16">16 hours</option>
                                            </template>
                                        </select>
                                        <span class="danger-message" role="alert" v-if="errors['mobility_flows.' + mfindex + '.duration']">
                                            {{ errors['mobility_flows.' + mfindex + '.duration'][0] }}
                                        </span>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label :for="'inp-distance-' + mfindex">Distance Category</label>
                                    <div class="form-control-holder" :class="[errors['mobility_flows.' + mfindex + '.distance'] ? 'danger-mark' : '']">
                                        <select :id="'inp-distance-' + mfindex"
                                                v-model="mobilityFlow.distance"
                                                class="form-control"
                                                :name="'mobility_flows[' + mfindex + '][distance]'">
                                            <template v-if="mobilityFlow.type !== 'AO' && mobilityFlow.type !== 'BO' && mobilityFlow.type !== 'CO'">
                                                <option value="1">500 - 1999 km</option>
                                                <option value="2">2000 – 2999 km</option>
                                            </template>
                                            <template v-else>
                                                <option value="3">Online</option>
                                            </template>
                                        </select>
                                        <span class="danger-message" role="alert" v-if="errors['mobility_flows.' + mfindex + '.distance']">
                                            {{ errors['mobility_flows.' + mfindex + '.distance'][0] }}
                                        </span>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label :for="'inp-participants-' + mfindex">Number of participants</label>
                                    <div class="form-control-holder" :class="[errors['mobility_flows.' + mfindex + '.participants'] ? 'danger-mark' : '']">
                                        <input :id="'inp-participants-' + mfindex"
                                               :name="'mobility_flows[' + mfindex + '][participants]'"
                                               class="form-control non-negative-decimal-zero-number-input"
                                               type="number"
                                               min="1"
                                               v-model="mobilityFlow.participants">
                                        <span class="danger-message" role="alert" v-if="errors['mobility_flows.' + mfindex + '.participants']">
                                            {{ errors['mobility_flows.' + mfindex + '.participants'][0] }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button type="button" class="mt-3 px-5 btn btn-lg btn-primary"
                                        @click="submitMobilityFlow(mobilityFlow)">Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-3" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">3</div>
                    <div class="txt">
                        <div class="h3">{{ __('scholarships.step3_title') }}</div>
                        <p>
                            {{ __('scholarships.step3_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-12 d-flex align-items-start">
                            <div class="info-icon mr-3">i</div>
                            <p>
                                New flows are added automatically when you add new mobility flows in the mobility flow
                                table above.
                            </p>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6 mb-0">
                            <label class="mb-0">{{ __('scholarships.step3_title_travel_and_individual_support') }}</label>
                        </div>
                    </div>

                    <div class="table-responsive mt-4" v-if="addedMobilityFlows.length">
                        <table class="table table-sticky-last-row">
                            <thead class="text-uppercase">
                            <tr>
                                <th scope="col">{{ __('scholarships.step3_field_flow') }}</th>
                                <th scope="col">{{ __('scholarships.step3_field_no_of_participants') }}</th>
                                <th scope="col">{{ __('scholarships.step3_field_travel_grant') }}</th>
                                <th scope="col">{{ __('scholarships.step3_field_individual_support') }}</th>
                                <th scope="col">
                                    <div class="bg"><div class="z-index">Total</div></div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="mobilityFlow in addedMobilityFlows">
                                <td>{{ mobilityFlow.type }}{{ getMobilityFlowTypeNumber(mobilityFlow)}} {{getMobilityFlowTypeDescription(mobilityFlow)}}</td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control" disabled
                                               :value="mobilityFlow.participants">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control" disabled
                                               :value="calculateMFTravelGrant(mobilityFlow)">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control" disabled
                                               :value="calculateMFSubstinanceGrant(mobilityFlow)">
                                    </div>
                                </td>
                                <td>
                                    <div class="bg">
                                        <div class="z-index">
                                            <div class="form-control-holder text-right">
                                                <input type="text" class="form-control" disabled
                                                       :value="calculateMFSupportTotal(mobilityFlow)">
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>

                            <tfoot>
                            <tr>
                                <td>
                                    <span class="text-uppercase color-blue"><strong> {{ __('scholarships.step3_title_grant_total') }}</strong></span>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control" :value="calculateAllMFParticipantsSum()" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control" :value="calculateAllMFTravelGrantSum()" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control" :value="calculateAllMFSubstinanceGrantSum()" disabled>
                                    </div>
                                </td>

                                <td>
                                    <div class="bg">
                                        <div class="z-index">
                                            <div class="grand-total-num">
                                                € <strong>{{ calculateAllMFSupportTotalSum() }}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div class="divider my-5"></div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label>Special Needs Support</label>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-12 d-flex align-items-start">
                            <div class="info-icon mr-3">i</div>
                            <p>
                                {{ __('scholarships.step3_info_special_needs_support') }}
                            </p>
                        </div>
                    </div>

                    <div class="table-responsive" v-if="addedSpecialNeeds.length">
                        <table class="table mb-5">
                            <thead class="text-uppercase">
                            <tr>
                                <th scope="col">Number of participants with special needs</th>
                                <th scope="col">SPECIAL NEEDS SUPPORT (EUR)</th>
                                <th scope="col">Short description of the requested costs</th>
                                <th scope="col" width="1px"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(specialNeed, sindex) in addedSpecialNeeds">
                                <td>{{ specialNeed.participants }}</td>
                                <td>{{ specialNeed.support }}</td>
                                <td>{{ specialNeed.description }}</td>
                                <td class="text-nowrap">
                                    <button type="button" @click="makeSpecialNeedVisible(specialNeed)" class="btn btn-outline-primary btn-rounded">
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>
                                    <button type="button" class="btn btn-outline-danger btn-rounded"
                                            v-on:click="deleteSpecialNeedRequest(specialNeed, sindex)" v-if="!viewMode">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>

                            <tfoot>
                            <tr>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control col-6" :value="calculateAllSpecialNeedsParticipantsSum()" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control" :value="calculateAllSpecialNeedsSupportSum()" disabled>
                                    </div>
                                </td>
                                <td>
                                    <span class="text-uppercase color-blue"><strong> {{ __('scholarships.step3_title_grant_total') }}</strong></span>
                                </td>
                                <td></td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div>
                        <button v-show="!viewMode"
                                :class="['btn btn-lg btn-outline-secondary', project.special_needs_supports.length ? '' : '']"
                                @click="addSpecialNeed()"
                                type="button" >
                            <i class="fas fa-plus mr-2"></i>
                            <strong>Add Special Need Request</strong>
                        </button>
                    </div>

                    <div v-for="(specialNeed, snindex) in project.special_needs_supports">
                        <div :class="[!specialNeed.is_visible ? 'd-none': '']" class="partner-item">
                            <div class="divider mt-5"></div>
                            <input type="hidden"
                                   :id="'inp-specialNeed-id-' + snindex"
                                   :name="'special_needs_supports[' + snindex + '][id]'"
                                   class="form-control"
                                   v-model="specialNeed.id">
                            <div class="form-row mt-4 align-items-end">
                                <div class="form-group col-md-4">
                                    <label :for="'inp-specialNeed-participants-' + snindex">Number of participants with special needs</label>
                                    <div class="form-control-holder light-mark" :class="[errors['special_needs_supports.' + snindex + '.participants'] ? 'danger-mark' : '']">
                                        <input type="text" class="form-control"
                                               :id="'inp-specialNeed-participants-' + snindex"
                                               :name="'special_needs_supports[' + snindex + '][participants]'"
                                               v-model="specialNeed.participants"/>
                                        <span class="danger-message" role="alert" v-if="errors['special_needs_supports.' + snindex + '.participants']">
                                            {{ errors['special_needs_supports.' + snindex + '.participants'][0] }}
                                        </span>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label :for="'inp-specialNeed-support-' + snindex">Special needs support (EUR)</label>
                                    <div class="form-control-holder light-mark" :class="[errors['special_needs_supports.' + snindex + '.support'] ? 'danger-mark' : '']">
                                        <input type="text" class="form-control"
                                               :id="'inp-specialNeed-support-' + snindex"
                                               :name="'special_needs_supports[' + snindex + '][support]'"
                                               v-model="specialNeed.support"/>
                                        <span class="danger-message" role="alert" v-if="errors['special_needs_supports.' + snindex + '.support']">
                                            {{ errors['special_needs_supports.' + snindex + '.support'][0] }}
                                        </span>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label :for="'inp-specialNeed-description-' + snindex">Short description of the requested costs</label>
                                    <div class="form-control-holder green-mark" :class="[errors['special_needs_supports.' + snindex + '.description'] ? 'danger-mark' : '']">
                                        <input type="text" class="form-control"
                                               :id="'inp-specialNeed-description-' + snindex"
                                               :name="'special_needs_supports[' + snindex + '][description]'"
                                               v-model="specialNeed.description"/>
                                        <span class="danger-message" role="alert" v-if="errors['special_needs_supports.' + snindex + '.description']">
                                            {{ errors['special_needs_supports.' + snindex + '.description'][0] }}
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <button type="button" class="mt-3 px-5 btn btn-lg btn-primary"
                                        @click="submitSpecialNeed(specialNeed)">Submit
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="divider my-5"></div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-12">
                            <label>Organisational support (grants to the sending Higher Education Institutions to ensure
                                the quality
                                of the mobility arrangements)</label>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table">
                            <thead class="text-uppercase">
                            <tr>
                                <th scope="col">Institution</th>
                                <th scope="col">Number of participants</th>
                                <th scope="col">Organisational support grant (EUR)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    {{ project.promoter.title }}
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control col-6" :value="calculatePromoterOrganisationalSupportParticipants()" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control col-6" :value="calculatePromoterOrganisationalSupportGrant()" disabled>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(institution, iindex) in allInstitutions">
                                <td>
                                    {{ institution.title }}
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control col-6" :value="calculateOrganisationalSupportParticipants(iindex)" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control col-6" :value="calculateOrganisationalSupportGrant(iindex)" disabled>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td>
                                    <span class="text-uppercase color-blue"><strong> {{ __('scholarships.step3_title_grant_total') }}</strong></span>
                                </td>

                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="text" class="form-control col-6" :value="calculateOrganisationalSupportParticipantsSum()" disabled>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-control-holder text-right">
                                        <input type="numbr" class="form-control col-6" :value="calculateOrganisationalSupportGrantSum()" disabled>
                                    </div>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-4" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">4</div>
                    <div class="txt">
                        <div class="h3">{{ __('scholarships.step4_title') }}</div>
                        <p>
                            {{ __('scholarships.step4_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label>Travel support (EUR)</label>
                            <div class="form-control-holder">
                                <input type="text" class="form-control" :value="calculateAllMFTravelGrantSum()" disabled>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Individual support (EUR)</label>
                            <div class="form-control-holder light-mark">
                                <input type="text" class="form-control" :value="calculateAllMFSubstinanceGrantSum()" disabled>
                            </div>
                        </div>
                    </div>


                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label>Special needs support (EUR)</label>
                            <div class="form-control-holder">
                                <input type="text" class="form-control" :value="calculateAllSpecialNeedsSupportSum()" disabled>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Organisational support (EUR)</label>
                            <div class="form-control-holder light-mark">
                                <input type="text" class="form-control" :value="calculateOrganisationalSupportGrantSum()" disabled>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="form-group col-md-4">
                            <label>Total Cost</label>
                            <div class="form-control-holder" :class="{'danger-mark': calculateTotalCost() < 10000 || calculateTotalCost() > 200000 }">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">€</span>
                                    <input readonly="readonly" name="budget" type="text" class="form-control" :value="calculateTotalCost()">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="form-row mt-4" v-if="calculateTotalCost() < 10000 || calculateTotalCost() > 200000">
                        <div class="form-group col-md-12 d-flex align-items-start">
                            <div class="danger-icon mr-3 mt-1">!</div>
                            <p>
                                <span v-if="calculateTotalCost() < 10000">
                                    Minimālā projekta summa ir
                                    <strong>EUR 10 000</strong>.<br />
                                </span>
                                <span v-if="calculateTotalCost() > 200000">
                                    Maksimālā projekta summa ir <strong>EUR 200 000</strong>.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="anchor-container">
                <span id="step-5" class="anchor"></span>
            </span>
            <div class="main-box">
                <div class="step-heading d-flex align-items-center">
                    <div class="num">5</div>
                    <div class="txt">
                        <div class="h3">{{ __('scholarships.step5_title') }}</div>
                        <p>
                            {{ __('scholarships.step5_description') }}
                        </p>
                    </div>
                </div>

                <div class="box-content">
                    <div class="form-row mt-4">
                        <div class="form-group col-md-6">
                            <label>Project Description (EN)</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.project_description_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.project_description_file.title }}</span>
                                <small class="text-muted">{{ project.project_description_file.size_in_kb }} KB</small>


                                <a :href="project.project_description_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn-remove" v-on:click="deleteFile(project.project_description_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="project_description" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark" :class="[errors['project_description'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="project_description_file"
                                               name="project_description"
                                               type="file">
                                        <label class="custom-file-label" for="project_description_file">
                                            {{ files['project_description'] ? files['project_description'] : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <small>
                                * Only .doc, .docx, .pdf (max 4mb)
                            </small>

                            <div class="danger-message" v-if="errors['project_description']">
                                {{ errors['project_description'][0] }}
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Timetable of Activities (EN)</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.timetable_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.timetable_file.title }}</span>
                                <small class="text-muted">{{ project.timetable_file.size_in_kb }} KB</small>

                                <a :href="project.timetable_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn-remove" v-on:click="deleteFile(project.timetable_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="timetable" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark" :class="[errors['timetable'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input" id="timetable_file"
                                               name="timetable"
                                               type="file">
                                        <label class="custom-file-label" for="timetable_file">
                                            {{ files['timetable'] ? files['timetable'] : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <small>
                                * Only .pdf, xls, .xlsx (max 4mb)
                            </small>
                            <div class="danger-message" v-if="errors['timetable']">
                                {{ errors['timetable'][0] }}
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-3">
                        <div class="form-group col-md-6">
                            <label>Confirmation Letter (LV)</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.confirmation_letter_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.confirmation_letter_file.title }}</span>
                                <small class="text-muted">{{ project.confirmation_letter_file.size_in_kb }} KB</small>

                                <a :href="project.confirmation_letter_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.confirmation_letter_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="confirmation_letter" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark" :class="[errors['confirmation_letter'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input :readonly="viewMode" @change="fileClick($event)" class="custom-file-input" id="confirmation_letter_file"
                                               name="confirmation_letter"
                                               type="file">
                                        <label class="custom-file-label" for="confirmation_letter_file">
                                            {{ files['confirmation_letter'] ? files['confirmation_letter'] : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>* Only .pdf, eDoc (max 4mb)</small>
                            </div>
                            <div class="danger-message" v-if="errors['confirmation_letter']">
                                {{ errors['confirmation_letter'][0] }}
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label>Project summary (LV)</label>
                            <div class="mb-3 file-preview d-flex align-items-center" v-if="project.summary_file">
                                <div class="file-format-icon">
                                    <i class="far fa-file-pdf color-blue"></i>
                                </div>

                                <span class="font-italic file-name">{{ project.summary_file.title }}</span>
                                <small class="text-muted">{{ project.summary_file.size_in_kb }} KB</small>

                                <a :href="project.summary_file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                    <i class="fa fa-cloud-download-alt"></i>
                                </a>
                                <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteFile(project.summary_file)" v-if="!viewMode">
                                    <i class="far fa-trash-alt"></i>
                                </button>

                                <input type="hidden" name="summary" value="1" v-if="viewMode">
                            </div>
                            <div class="form-control-holder light-mark" :class="[errors['summary'] ? 'danger-mark' : '']" v-else>
                                <div class="input-group file-upload mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </span>
                                    </div>
                                    <div class="custom-file">
                                        <input :readonly="viewMode" @change="fileClick($event)" class="custom-file-input" id="summary_file"
                                               name="summary"
                                               type="file">
                                        <label class="custom-file-label" for="summary_file">
                                            {{ files['summary'] ? files['summary'] : "Upload a Document" }}
                                        </label>
                                    </div>
                                </div>
                                <small>* Only .doc, .docx, .pdf (max 4mb)</small>
                            </div>
                            <div class="danger-message" v-if="errors['summary']">
                                {{ errors['summary'][0] }}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="h5">
                            Other
                            <small class="text-muted text-uppercase d-block">{{ __('scholarships.step5_info_upload_more_description') }}</small>
                        </div>

                        <div v-for="(file, findex) in project.other_files" class="form-row">
                            <div class="form-group col-md-6">
                                <div class="mb-3 file-preview d-flex align-items-center" v-if="file.title">
                                    <div class="file-format-icon">
                                        <i class="far fa-file-pdf color-blue"></i>
                                    </div>

                                    <span class="font-italic file-name">{{ file.title }}</span>
                                    <small class="text-muted">{{ file.size_in_kb }} KB</small>

                                    <a :href="file.url" class="btn btn-outline-primary btn-rounded ml-3 mx-2" target="_blank">
                                        <i class="fa fa-cloud-download-alt"></i>
                                    </a>
                                    <button type="button" class="btn btn-outline-danger btn-rounded" v-on:click="deleteOtherFile(file, findex)" v-if="!viewMode">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                                <div class="form-control-holder blue-mark" v-else>
                                    <div class="input-group file-upload mb-3">
                                        <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </span>
                                        </div>
                                        <div class="custom-file">
                                            <input @change="fileClick($event)" :readonly="viewMode" class="custom-file-input"
                                                   :id="'other_files-' + findex"
                                                   :name="'other_files[' + findex + ']'"
                                                   type="file">
                                            <label class="custom-file-label" :for="'other_files-' + findex">
                                                {{ files['other_files[' + findex + ']'] ? files['other_files[' + findex + ']'] : "Upload a Document" }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <small>
                                    {{ __('scholarships.step5_file_information') }}
                                </small>
                            </div>
                        </div>

                        <div class="mt-" v-if="!viewMode">
                            <button type="button" @click="addOtherFile()" class="btn btn-lg btn-outline-secondary mb-4">
                                <i class="fas fa-plus mr-2"></i>
                                <strong>Add Another</strong>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="btn-out-of-box text-center btn-actions" v-if="!project.submitted_at">
            <span class="anchor-container">
                <span id="step-7" class="anchor"></span>
            </span>
            <div>
                <button type="button" @click="submit($event)" v-show="userIsManager"  class="btn pms-btn-purple btn-xlg text-uppercase mb-4" name="submit" value="1">Submit project</button>
            </div>
            <div>
                <button type="button" @click="submitDraft($event)" v-show="userIsEditor" class="btn pms-btn-light-blue btn-lg text-uppercase mb-5" name="draft" value="1">Save draft</button>
            </div>
        </div>
    </form>
</template>

<script>
    import {mapGetters} from "vuex";
    import grantProjectAPI from "../../api/grantProject"
    import variableAPI from "../../api/variable";
    import researchProjectAPI from "../../api/researchProject"
    import JQuery from 'jquery'

    export default {
        props: {
            projectId: null,
            viewMode: Boolean,
        },
        updated: function() {
            JQuery('.non-negative-number-input').keyup(function() {
                var strippedNumber = parseInt(JQuery(this).val());
                console.log(strippedNumber);
                if (strippedNumber < 0) {
                    JQuery(this).parent('.form-control-holder').addClass('danger-mark');
                } else {
                    JQuery(this).parent('.form-control-holder').removeClass('danger-mark');
                }
            });
            JQuery('.non-negative-decimal-zero-number-input').keyup(function() {
                var number = Math.floor(Number(JQuery(this).val()));

                if (number !== Infinity && String(number) === JQuery(this).val() && number > 0) {
                    JQuery(this).parent('.form-control-holder').removeClass('danger-mark');
                } else {
                    JQuery(this).parent('.form-control-holder').addClass('danger-mark');
                }
            });
        },
        data() {
            return {
                project: {
                    started_at: null,
                    ended_at: null,
                    promoter: {
                        country: {}
                    },
                    partners: [],
                    mobility_flows: [],
                    special_needs_supports: [],
                    coordinator: {},
                    representative: {},
                    other_files: [],
                },
                promoterOptions: [],
                userIsManager: false,
                userIsEditor: false,
                selectCountries: [],
                tmp: '',
                disabledEndDates: {
                    from: new Date(2024, 4, 1),
                },
                disabledStartDates: {
                    to: new Date(2022, 6, 1),
                    from: new Date(2023, 3, 30),
                },
                files: {},
            }
        },
        created() {
            variableAPI.countries({filter: 'grants'})
                .then(response => {
                    this.selectCountries = response.data;
                });

            if (this.projectId) {
                grantProjectAPI.get(this.projectId)
                    .then(response => {
                        let p = response.data;
                        p.ended_at = this.formatDate(new Date(p.ended_at));
                        p.started_at = this.formatDate(new Date(p.started_at));

                        Object.assign(this.project, p);
                    });
            }

            this.$store.dispatch('user/getCurrentUser')
                .then(() => {
                    if (!this.projectId) {
                        this.project.promoter = this.currentUser.institution;
                    }
                    this.userIsManager = this.currentUser.is_manager;
                    this.userIsEditor = this.currentUser.is_editor;
                });

        },
        computed: {
            ...mapGetters({
                errors: 'grantProject/getErrors',
                currentUser: 'user/getCurrentUser'
            }),
            addedPartners: function() {
                return this.project.partners.filter(p => !p.notAdded);
            },
            addedMobilityFlows: function() {
                return this.project.mobility_flows.filter(p => !p.notAdded);
            },
            addedSpecialNeeds: function() {
                return this.project.special_needs_supports.filter(p => !p.notAdded);
            },
            projectDuration: function () {
                if (!this.project.ended_at || !this.project.started_at) {
                    return '';
                }

                let months;
                months = (this.project.ended_at.getFullYear() - this.project.started_at.getFullYear()) * 12;
                months -= this.project.started_at.getMonth();
                months += this.project.ended_at.getMonth();
                if (this.project.ended_at.getDate() < this.project.started_at.getDate()) {
                    months--;
                }

                return months <= 0 ? 0 : months;
            },
            allInstitutions: function() {
                let institutions = this.project.partners.map(p => p.institution);

                return institutions;
            },
            eeaGrants: function () {
                return Math.round(((this.calculateTotalCost() * 0.85) + Number.EPSILON) * 100) / 100;
            },
            stateBudget: function () {
                return Math.round(((this.calculateTotalCost() * 0.15) + Number.EPSILON) * 100) / 100;
            }

        },
        watch: {
            'project.started_at': function() {
                if (typeof this.project.created_at === 'undefined' || new Date(this.project.created_at).getTime() >= new Date(2022, 1, 1).getTime()) {
                    this.project.duration = 12;
                    this.durationSelected();
                }
            }
        },
        methods: {
            durationSelected() {
                let endDate = new Date(this.project.started_at);
                endDate.setMonth(endDate.getMonth() + parseInt(this.project.duration, 10));
                if (typeof this.project.created_at === 'undefined' || new Date(this.project.created_at).getTime() >= new Date(2022, 1, 1).getTime()) {
                    endDate.setDate(endDate.getDate() - 1);
                }
                this.project.ended_at = this.formatDate(endDate);
            },
            formatDate(date) {
                const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                return ('0' + date.getDate()).slice(-2) + " " + months[date.getMonth()] + " " + date.getFullYear();
            },
            fileClick(e) {
                if (this.viewMode) {
                    e.preventDefault();
                }
                else {
                    if (e.target.files.length) {
                        this.$set(this.files, e.target.name, e.target.files[0].name)
                    }
                }
            },            selectBlur(partner) {
                if (!partner.institution.id) {
                    partner.institution.title = this.tmp;
                    partner.institution.institution_text = this.tmp;
                }
            },
            mfDistanceLabel(distance) {
                if (distance == 1) {
                    return '500 - 1999 km';
                }
                else if (distance == 2) {
                    return '2000 – 2999 km';
                }
                else if (distance == 3) {
                    return 'Online';
                }
            },
            calculateMFTravelGrant(mf) {
                if (mf.distance == 1) {
                    return mf.participants * 275;
                }
                else if (mf.distance == 2) {
                    return mf.participants * 360;
                }
                else {
                    return 0;
                }
            },
            calculateMFSubstinanceGrant(mf) {
                if (mf.type === 'A' && mf.duration) {
                    if (mf.direction === 'Outgoing') {
                        return mf.duration * mf.participants * 1200;
                    }
                    else if (mf.direction === 'Incoming') {
                        return mf.duration * mf.participants * 1000;
                    }
                }
                else if (mf.type === 'B' || mf.type === 'C') {
                    if (mf.direction === 'Incoming') {
                        switch (mf.duration) {
                            case '1': return mf.participants * 750;
                            case '2': return mf.participants * 1250;
                            case '3': return mf.participants * 2000;
                            case '4': return mf.participants * 3000;
                            // case '1': return 1 * mf.participants * 750;
                            // case '2': return 2 * mf.participants * 1250;
                            // case '3': return 3 * mf.participants * 2000;
                            // case '4': return 4 * mf.participants * 3000;
                        }
                    }
                    else if (mf.direction === 'Outgoing') {
                        switch (mf.duration) {
                            case '1': return mf.participants * 1250;
                            case '2': return mf.participants * 2200;
                            case '3': return mf.participants * 3000;
                            case '4': return mf.participants * 4000;
                            // case '1': return 1 * mf.participants * 1250;
                            // case '2': return 2 * mf.participants * 2200;
                            // case '3': return 3 * mf.participants * 3000;
                            // case '4': return 4 * mf.participants * 4000;
                        }
                    }
                }
                else if (mf.type === 'AS') {
                    return mf.participants * 350;
                }
                else if (mf.type === 'BS' || mf.type === 'CS') {
                    if (mf.direction === 'Outgoing') {
                        return mf.participants * 500;
                    }
                    else if (mf.direction === 'Incoming') {
                        return mf.participants * 300;
                    }
                }

                return 0;
            },
            calculateMFSupportTotal (mf) {
                return this.calculateMFTravelGrant(mf) + this.calculateMFSubstinanceGrant(mf);
            },
            calculateAllMFParticipantsSum() {
                return this.project.mobility_flows.map(e => parseInt(e.participants, 10)).reduce((a, v) => a + v, 0);
            },
            calculateAllMFTravelGrantSum() {
                return this.project.mobility_flows.reduce((a, v) => a + this.calculateMFTravelGrant(v), 0);
            },
            calculateAllMFSubstinanceGrantSum() {
                return this.project.mobility_flows.reduce((a, v) => a + this.calculateMFSubstinanceGrant(v), 0);
            },
            calculateAllMFSupportTotalSum() {
                return this.project.mobility_flows.reduce((a, v) => a + this.calculateMFSupportTotal(v), 0);
            },
            calculateAllSpecialNeedsParticipantsSum() {
                return this.project.special_needs_supports.map(e => parseInt(e.participants, 10)).reduce((a, v) => a + v, 0);
            },
            calculateAllSpecialNeedsSupportSum() {
                return this.project.special_needs_supports.map(e => parseFloat(e.support)).reduce((a, v) => a + v, 0);
            },
            calculatePromoterOrganisationalSupportParticipants() {
                let result = 0;
                for (let mf of this.project.mobility_flows) {
                    if (mf.direction === 'Outgoing') {
                        result += parseInt(mf.participants, 10);
                    }
                }

                return result;
            },
            calculatePromoterOrganisationalSupportGrant() {
                let s = this.calculatePromoterOrganisationalSupportParticipants();
                if (s > 100) {
                    return s * 200;
                }
                else if (s > 0 && s <= 100) {
                    return s * 350;
                }

                return 0;
            },
            calculateOrganisationalSupportParticipants(partnerIndex) {
                let result = 0;
                for (let mf of this.project.mobility_flows) {
                    if (mf.institution == partnerIndex) {
                        if (mf.direction === 'Incoming') {
                            result += parseInt(mf.participants, 10);
                        }
                    }
                }

                return result;
            },
            calculateOrganisationalSupportGrant(partnerIndex) {
                let s = this.calculateOrganisationalSupportParticipants(partnerIndex);
                if (s > 100) {
                    return s * 200;
                }
                else if (s > 0 && s <= 100) {
                    return s * 350;
                }

                return 0;
            },
            calculateOrganisationalSupportParticipantsSum() {
                let result = 0;
                for (let i =0; i < this.allInstitutions.length; i++) {
                    result += this.calculateOrganisationalSupportParticipants(i);
                }

                result += this.calculatePromoterOrganisationalSupportParticipants();

                return result;
            },
            calculateOrganisationalSupportGrantSum() {
                let result = 0;
                for (let i =0; i < this.allInstitutions.length; i++) {
                    result += this.calculateOrganisationalSupportGrant(i);
                }

                result += this.calculatePromoterOrganisationalSupportGrant();

                return result;
            },
            calculateTotalCost() {
                return this.calculateAllMFTravelGrantSum()
                    + this.calculateAllMFSubstinanceGrantSum()
                    + this.calculateAllSpecialNeedsSupportSum()
                    + this.calculateOrganisationalSupportGrantSum()
            },
            getMobilityFlowTypeNumber(mobilityFlow) {
                let num = 1;

                for (let mf of this.project.mobility_flows) {
                    if (mobilityFlow === mf) {
                        break;
                    }

                    if (mobilityFlow.type === mf.type && mobilityFlow !== mf) {
                        num++;
                    }
                }

                return num;
            },
            getMobilityFlowTypeDescription(mobilityFlow) {
                if(typeof mobilityFlow.type !== 'undefined') {
                    if (mobilityFlow.type == 'A' || mobilityFlow.type == 'AS' || mobilityFlow.type == 'AO') {
                        return 'student';
                    }
                    if (mobilityFlow.type == 'B' || mobilityFlow.type == 'BS' || mobilityFlow.type == 'BO') {
                        return 'academic staff';
                    }
                    if (mobilityFlow.type == 'C' || mobilityFlow.type == 'CS' || mobilityFlow.type == 'CO') {
                        return 'administrative staff';
                    }
                }
            },
            submit(e) {
                let self = this;

                grantProjectAPI
                    .submit(this.projectId, new FormData(e.target.form))
                    .then(function (response) {
                        window.location.replace('/grant-projects/' + response.data.id)
                    })
                    .catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('grantProject/setErrors', error.response.data.errors);
                        }
                    });

                return '';
            },
            submitDraft(e) {
                var self = this;

                if (this.projectId) {
                    grantProjectAPI.update(
                        this.projectId,
                        new FormData(e.target.form)
                    ).then(function (response) {
                        window.location.replace('/grant-projects/' + self.projectId + '/edit')
                    }).catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('grantProject/setErrors', error.response.data.errors);
                        }
                    });
                } else {
                    grantProjectAPI.create(
                        new FormData(e.target.form)
                    ).then(function (response) {
                        window.location.replace('/grant-projects/' + response.data.id + '/edit')
                    }).catch(function (error) {
                        if (error.response.status == 422) {
                            self.$store.commit('grantProject/setErrors', error.response.data.errors);
                        }
                    });
                }
            },
            onPromoterSearch(keyword, loading) {
                if (!keyword) {
                    return;
                }

                this.tmp = keyword;

                loading(true);

                axios.get('/api/institutions', {params: {project: 'grant', keyword: keyword}})
                    .then(response => {
                        this.promoterOptions = response.data;
                        loading(false);
                    });
            },
            addMobilityFlow() {
                if (this.viewMode) {
                    return;
                }

                this.project.mobility_flows.forEach(e => e.is_visible = false);
                this.project.mobility_flows.push({
                    is_visible: true,
                    notAdded: true,
                    institution: null,
                    participants: 0,
                    support_participants: 0,
                    travel_grant: 0,
                    substinance_grant: 0,
                    support: 0,
                });
            },
            addSpecialNeed() {
                if (this.viewMode) {
                    return;
                }

                this.project.special_needs_supports.forEach(e => e.is_visible = false);
                this.project.special_needs_supports.push({
                    is_visible: true,
                    notAdded: true,
                    participants: 0,
                    support: 0,
                });
            },
            addPartner(institution = null) {
                if (this.viewMode) {
                    return;
                }

                let partner = {
                    id: null,
                    institution: {},
                    researchStaff: [],
                    contact: {},
                    representative: {},
                    is_visible: true,
                    notAdded: true,
                    budget: {
                        personnel_costs: 0,
                        travel_allowances: 0,
                        sub_contracting: 0,
                        other_costs: 0,
                        total_direct_costs: 0,
                        overheads: 0,
                        total: 0
                    }
                };
                this.project.partners.forEach(e => e.is_visible = false);

                if (institution !== null) {
                    partner.is_hidden = true;
                    partner.institution = institution;
                    partner.is_visible = false;
                    partner.notAdded = false;
                    if (typeof institution.country !== 'undefined') {
                        partner.institution.country = institution.country.title;
                    }
                }

                this.project.partners.push(partner);
            },
            deletePartner(partner, index) {
                if (partner.id) {
                    researchProjectAPI.deleteParticipant(partner.id)
                        .then(response => {
                            this.project.partners.splice(index, 1);
                        });
                } else {
                    this.project.partners.splice(index, 1);
                }
                window.toastr.success('Your request successfully completed!')
            },
            deleteMobilityFlow(mobilityFlow, index) {
                if (mobilityFlow.id) {
                    grantProjectAPI.deleteMobilityFlow(this.project.id, mobilityFlow.id)
                        .then(response => {
                            this.project.mobility_flows.splice(index, 1);
                        });
                } else {
                    this.project.mobility_flows.splice(index, 1);
                }
                window.toastr.success('Your request successfully completed!')
            },
            deleteSpecialNeedRequest(specialNeedRequest, index) {
                if (specialNeedRequest.id) {
                    grantProjectAPI.deleteSpecialNeedRequest(this.project.id, specialNeedRequest.id)
                        .then(response => {
                            this.project.special_needs_supports.splice(index, 1);
                        });
                } else {
                    this.project.special_needs_supports.splice(index, 1);
                }
                window.toastr.success('Your request successfully completed!')
            },

            addOtherFile() {
                this.project.other_files.push({});
            },
            deleteFile(file) {
                grantProjectAPI.deleteFile(this.project.id, file.id)
                    .then(() => {
                        Vue.delete(this.project, file.type + '_file');
                    });
            },
            deleteOtherFile(file, key) {
                researchProjectAPI.deleteFile(this.project.id, file.id)
                    .then(response => {
                        this.project.other_files.splice(key, 1);
                    });
            },
            submitPartner(partner) {
                Vue.set(partner, 'is_visible', false);
                Vue.set(partner, 'notAdded', false);
            },
            makePartnerVisible(partner) {
                this.project.partners.filter(e => e !== partner).forEach(e => e.is_visible = false);
                this.project.partners = this.project.partners.filter(e => !e.notAdded);
                partner.is_visible = true;
            },
            submitMobilityFlow(mf) {
                Vue.set(mf, 'is_visible', false);
                Vue.set(mf, 'notAdded', false);
            },
            makeMobilityFlowVisible(mf) {
                this.project.mobility_flows.filter(e => e !== mf).forEach(e => e.is_visible = false);
                this.project.mobility_flows = this.project.mobility_flows.filter(e => !e.notAdded);
                mf.is_visible = true;
            },
            submitSpecialNeed(sn) {
                Vue.set(sn, 'is_visible', false);
                Vue.set(sn, 'notAdded', false);
            },
            makeSpecialNeedVisible(sn) {
                this.project.special_needs_supports.filter(e => e !== sn).forEach(e => e.is_visible = false);
                this.project.special_needs_supports = this.project.special_needs_supports.filter(e => !e.notAdded);
                sn.is_visible = true;
            },
            hasError(error) {
                for (let e in this.errors) {
                    if (e.startsWith(error)) {
                        return true;
                    }
                }
                return false;
            }
        },
    }
</script>
