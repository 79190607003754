<template>
    <div>
        <form @submit.prevent="submit($event)">
            <table :class="['table table-expert mb-0', (evaluationCount > 1) ? 'table-striped' : '']">
                <thead>
                <tr>
                    <th class="border-0 pl-5">CRITERIA</th>
                    <th class="border-0">JUSTIFICATION / COMMENTS</th>
                    <th class="border-0 text-nowrap pr-5">SCORE / POINTS</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="font-weight-bold border-0 pl-5">
                        <span v-if="projectType == 'grant'">Relevance of the project</span>
                        <span v-else>Excellence</span>
                    </td>
                    <td class="border-0 w-75">
                        <textarea v-model="fields.comment_1" :rows="(evaluationCount > 1) ? 2 : 5"
                                  :placeholder="(evaluationCount > 1) ? 'Strengths..' : 'Start typing..'"
                                  :class="['form-control', errors.comment_1 ? 'is-invalid' : '']"
                                  :disabled="formIsDisabled"></textarea>
                        <div class="invalid-feedback" role="alert" v-if="errors.comment_1">
                            {{ errors.comment_1[0] }}
                        </div>

                        <div v-if="evaluationCount > 1">
                            <textarea v-model="fields.comment_1_2" rows="2" placeholder="Weaknesses.."
                                      :class="['mt-2 form-control', errors.comment_1_2 ? 'is-invalid' : '']"
                                      :disabled="formIsDisabled"></textarea>
                            <div class="invalid-feedback" role="alert" v-if="errors.comment_1_2">
                                {{ errors.comment_1_2[0] }}
                            </div>

                            <textarea v-model="fields.comment_1_3" rows="2" placeholder="Suggestions"
                                      :class="['mt-2 form-control', errors.comment_1_3 ? 'is-invalid' : '']"
                                      :disabled="formIsDisabled"></textarea>
                            <div class="invalid-feedback" role="alert" v-if="errors.comment_1_3">
                                {{ errors.comment_1_3[0] }}
                            </div>
                        </div>
                    </td>
                    <td :class="['align-bottom border-0 pr-5', (evaluationCount == 1) ? 'expert-rate' : '']">
                        <div v-if="evaluationCount > 1">
                            <select v-model="fields.rate_1" id="rate_1" class="form-control" :disabled="formIsDisabled">
                                <option value="1">Compliant</option>
                                <option value="2">Partially Compliant</option>
                                <option value="3">Not Compliant</option>
                            </select>
                            <div class="invalid-feedback" role="alert" v-if="errors.rate_1">
                                {{ errors.rate_1[0] }}
                            </div>
                        </div>
                        <div class="border-expert d-flex justify-content-between align-items-end py-3" v-else>
                            <div>
                                <input type="text" v-model="fields.rate_1" placeholder="0"
                                       :class="[errors.rate_1 ? 'is-invalid' : '']" :disabled="formIsDisabled">
                                <div class="invalid-feedback" role="alert" v-if="errors.rate_1">
                                    {{ errors.rate_1[0] }}
                                </div>
                            </div>
                            <div class="text-nowrap" style="padding-bottom: .4rem;">
                                <span v-if="projectType == 'grant'">/30</span>
                                <span v-else>/5</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold border-0 pl-5">
                        <span v-if="projectType == 'grant'">Quality of the project design and implementation</span>
                        <span v-else>Implementation</span>
                    </td>
                    <td class="border-0">
                        <textarea v-model="fields.comment_2" :rows="(evaluationCount > 1) ? 2 : 5"
                                  :placeholder="(evaluationCount > 1) ? 'Strengths..' : 'Start typing..'"
                                  :class="['form-control', errors.comment_2 ? 'is-invalid' : '']"
                                  :disabled="formIsDisabled"></textarea>
                        <div class="invalid-feedback" role="alert" v-if="errors.comment_2">
                            {{ errors.comment_2[0] }}
                        </div>

                        <div v-if="evaluationCount > 1">
                            <textarea v-model="fields.comment_2_2" rows="2" placeholder="Weaknesses.."
                                      :class="['mt-2 form-control', errors.comment_2_2 ? 'is-invalid' : '']"
                                      :disabled="formIsDisabled"></textarea>
                            <div class="invalid-feedback" role="alert" v-if="errors.comment_2_2">
                                {{ errors.comment_2_2[0] }}
                            </div>

                            <textarea v-model="fields.comment_2_3" rows="2" placeholder="Suggestions"
                                      :class="['mt-2 form-control', errors.comment_2_3 ? 'is-invalid' : '']"
                                      :disabled="formIsDisabled"></textarea>
                            <div class="invalid-feedback" role="alert" v-if="errors.comment_2_3">
                                {{ errors.comment_2_3[0] }}
                            </div>
                        </div>
                    </td>
                    <td :class="['align-bottom border-0 pr-5', (evaluationCount == 1) ? 'expert-rate' : '']">
                        <div v-if="evaluationCount > 1">
                            <select v-model="fields.rate_2" id="rate_2" class="form-control" :disabled="formIsDisabled">
                                <option value="1">Compliant</option>
                                <option value="2">Partially Compliant</option>
                                <option value="3">Not Compliant</option>
                            </select>
                            <div class="invalid-feedback" role="alert" v-if="errors.rate_1">
                                {{ errors.rate_1[0] }}
                            </div>
                        </div>
                        <div class="border-expert d-flex justify-content-between align-items-end py-3" v-else>
                            <div>
                                <input type="text" v-model="fields.rate_2" placeholder="0"
                                       :class="[errors.rate_2 ? 'is-invalid' : '']" :disabled="formIsDisabled">
                                <div class="invalid-feedback" role="alert" v-if="errors.rate_2">
                                    {{ errors.rate_2[0] }}
                                </div>
                            </div>
                            <div class="text-nowrap" style="padding-bottom: .4rem;">
                                <span v-if="projectType == 'grant'">/40</span>
                                <span v-else>/5</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold border-0 pl-5">
                        <span v-if="projectType == 'grant'">Impact and dissemination</span>
                        <span v-else>Impact</span>
                    </td>
                    <td class="border-0">
                        <textarea v-model="fields.comment_3" :rows="(evaluationCount > 1) ? 2 : 5" :placeholder="(evaluationCount > 1) ? 'Strengths..' : 'Start typing..'"
                                  :class="['form-control', errors.comment_3 ? 'is-invalid' : '']"
                                  :disabled="formIsDisabled"></textarea>
                        <div class="invalid-feedback" role="alert" v-if="errors.comment_3">
                            {{ errors.comment_3[0] }}
                        </div>

                        <div v-if="evaluationCount > 1">
                            <textarea v-model="fields.comment_3_2" rows="2" placeholder="Weaknesses.."
                                      :class="['mt-2 form-control', errors.comment_3_2 ? 'is-invalid' : '']"
                                      :disabled="formIsDisabled"></textarea>
                            <div class="invalid-feedback" role="alert" v-if="errors.comment_3_2">
                                {{ errors.comment_3_2[0] }}
                            </div>

                            <textarea v-model="fields.comment_3_3" rows="2" placeholder="Suggestions"
                                      :class="['mt-2 form-control', errors.comment_3_3 ? 'is-invalid' : '']"
                                      :disabled="formIsDisabled"></textarea>
                            <div class="invalid-feedback" role="alert" v-if="errors.comment_3_3">
                                {{ errors.comment_3_3[0] }}
                            </div>
                        </div>
                    </td>

                    <td :class="['align-bottom border-0 pr-5', (evaluationCount == 1) ? 'expert-rate' : '']">
                        <div v-if="evaluationCount > 1">
                            <select v-model="fields.rate_3" id="rate_3" class="form-control" :disabled="formIsDisabled">
                                <option value="1">Compliant</option>
                                <option value="2">Partially Compliant</option>
                                <option value="3">Not Compliant</option>
                            </select>
                            <div class="invalid-feedback" role="alert" v-if="errors.rate_1">
                                {{ errors.rate_1[0] }}
                            </div>
                        </div>
                        <div class="border-expert d-flex justify-content-between align-items-end py-3" v-else>
                            <div>
                                <input type="text" v-model="fields.rate_3" placeholder="0"
                                       :class="[errors.rate_3 ? 'is-invalid' : '']" :disabled="formIsDisabled">
                                <div class="invalid-feedback" role="alert" v-if="errors.rate_3">
                                    {{ errors.rate_3[0] }}
                                </div>
                            </div>
                            <div class="text-nowrap" style="padding-bottom: .4rem;">
                                <span v-if="projectType == 'grant'">/30</span>
                                <span v-else>/5</span>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td class="font-weight-bold text-uppercase pl-5">Total Score</td>
                    <td></td>
                    <td :class="['align-bottom border-0 pr-5', (evaluationCount == 1) ? 'expert-rate' : '']">
                        <div v-if="evaluationCount > 1">
                            <div>
                                <select v-model="fields.rate_4" class="form-control" :disabled="formIsDisabled">
                                    <option value="1">Compliant</option>
                                    <option value="2">Partially Compliant</option>
                                    <option value="3">Not Compliant</option>
                                </select>
                                <div class="invalid-feedback" role="alert" v-if="errors.rate_4">
                                    {{ errors.rate_4[0] }}
                                </div>
                            </div>
                            <div class="expert-rate" v-if="evaluationCount == 3">
                                <div class="border-expert d-flex justify-content-between align-items-end py-3">
                                    <div>
                                        <input type="text" v-model="fields.rate_5" placeholder="0"
                                               :class="[errors.rate_5 ? 'is-invalid' : '']" :disabled="formIsDisabled">
                                        <div class="invalid-feedback" role="alert" v-if="errors.rate_5">
                                            {{ errors.rate_5[0] }}
                                        </div>
                                    </div>
                                    <div class="text-nowrap" style="padding-bottom: .4rem;">
                                        <span>/100%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-end" v-else>
                            <div>
                                <input type="text" v-model="totalScore" placeholder="0.0" disabled>
                            </div>
                            <div class="text-nowrap" style="padding-bottom: .4rem;">
                                <span v-if="projectType == 'grant'">/100</span>
                                <span v-else>/15</span>
                            </div>
                        </div>
                    </td>
                </tr>
                </tfoot>
            </table>
            <button type="submit" class="btn btn-primary btn-block rounded-0 mt-0 py-3 text-uppercase"
                    v-if="!formIsDisabled">Submit and continue
            </button>
        </form>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        props: {
            projectId: null,
            projectType: null,
            evaluationExpert: null,
            evaluationCount: 0
        },
        data() {
            return {
                fields: {},
                formIsDisabled: false
            }
        },
        computed: {
            ...mapGetters({
                currentUser: 'user/getCurrentUser',
                errors: 'expert/getErrors',
            }),
            totalScore: function () {
                if (this.projectType === 'grant') {
                    return parseInt(this.fields.rate_1) + parseInt(this.fields.rate_2) + parseInt(this.fields.rate_3);
                } else {
                    return (parseFloat(this.fields.rate_1 ? this.fields.rate_1 : 0) + parseFloat(this.fields.rate_2 ? this.fields.rate_2 : 0) + parseFloat(this.fields.rate_3 ? this.fields.rate_3 : 0)).toFixed(1);
                }
            }
        },
        created() {
            if (this.evaluationExpert) {
                this.fields = JSON.parse(this.evaluationExpert);
                if (this.fields.status == 2) {
                    this.formIsDisabled = true;
                }
            }
            this.$store.dispatch('user/getCurrentUser');
        },
        methods: {
            submit() {
                this.$store.dispatch('expert/storeExpertEvaluation', {
                    projectId: this.projectId,
                    form: this.fields
                });
            }
        }
    }
</script>
