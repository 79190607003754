<template>
    <div>
        <table class="table mb-5">
            <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Confirmed</th>
                <th scope="col">Paid</th>
                <th scope="col">Status</th>
                <th scope="col" width="1px"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="payment in payments">
                <td>
                    {{ payment.created_at }}
                </td>
                <td>
                   input
                </td>
            </tr>
            </tbody>
        </table>

        {{ currentUser }}
        {{ payments }}
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        created() {
            this.$store.dispatch('payment/getPayments');
        },
        computed: {
            ...mapGetters({
                payments: 'payment/index',
                currentUser: 'user/getCurrentUser'
            })
        }
    }
</script>
