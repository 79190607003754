import InstitutionAPI from '../api/institution.js';
import UserAPI from "../api/user";

export const institution = {
    namespaced: true,
    state: {
        institutions: [],
        institution: [],
        errors: [],
    },
    mutations: {
        setInstitutions(state, institutions) {
            state.institutions = institutions;
        },
        setInstitution(state, institution) {
            state.institution = institution;
        },
        unshiftInstitution(state, institution) {
            state.institutions.unshift(institution);
        },
        shiftInstitution(state, id) {
            let index = state.institutions
                .map(function(item) { return item.id; })
                .indexOf(id);
            state.institutions.splice(index, 1);
        },
        resetErrors(state) {
            state.errors = [];
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
        addParticipant(state, user) {
            state.institution.participants.unshift(user);
        },
        shiftParticipant(state, user) {
            let index = state.institution.participants
                .map(function(item) { return item.id; })
                .indexOf(user.id);

            state.institution.participants.splice(index, 1);
        }
    },
    getters: {
        getInstitutions: state => {
            return state.institutions;
        },
        getInstitution: state => {
            return state.institution;
        },
        getErrors: state => {
            return state.errors;
        }
    },
    actions: {
        getInstitutions({ commit }, filter) {
            InstitutionAPI.index(filter)
                .then(function (response) {
                    commit('setInstitutions', response.data);
                })
                .catch(function () {
                    return commit('pushInstitutions', []);
                });
        },
        store({commit}, data) {
            commit('resetErrors');
            InstitutionAPI.store(data)
                .then(function (response) {
                    commit('unshiftInstitution', response.data.institution);
                    $('#institution-create').trigger('click');
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        commit('setErrors', error.response.data.errors);
                    }
                });
        },
        find({commit}, id) {
            return InstitutionAPI.find(id)
                .then(function (response) {
                    commit('setInstitution', response.data.institution);
                    return response.data.institution;
                });
        },
        destroy({commit}, id) {
            InstitutionAPI.destroy(id)
                .then(function () {
                    $('#institution-delete-' + id).trigger('click');
                    commit('shiftInstitution', id);
                });
        },
        addParticipant({commit}, data) {
            InstitutionAPI.addParticipant(data.id, data.user)
                .then(function () {
                    $('#user-search').trigger('click');
                    commit('addParticipant', data.user);
                });
        },
        removeParticipant({commit}, data) {
            InstitutionAPI.removeParticipant(data.id, data.user)
                .then(function () {
                    $('#participant-exclude-' + data.user.id).trigger('click');
                    commit('shiftParticipant', data.user);
                });
        },
    }
};
