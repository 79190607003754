<template>
    <div class="d-inline">
        <button type="button" class="btn btn-success btn" data-toggle="modal" data-target="#user-search">
            Search and attach an existing user
        </button>

        <div class="modal fade" id="user-search" tabindex="-1" role="dialog" aria-labelledby="user-searchLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="user-searchLabel">
                            Search an user
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submit">
                            <div class="form-group">
                                <label for="inp-keyword">Keyword</label>

                                <v-select :clearable="false" id="inp-keyword" :class="[errors.keyword ? 'is-invalid' : '']" label="first_name" v-model="selected" :filterable="false" :options="options" @search="onSearch">
                                    <template slot="no-options">
                                        Start typing
                                    </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.first_name + ' ' + option.last_name + ' (' + option.email + ')' }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.first_name + ' ' + option.last_name + ' (' + option.email + ')' }}
                                        </div>
                                    </template>
                                </v-select>

                                <span class="invalid-feedback" role="alert" v-if="errors.keyword">
                                    {{ errors.keyword[0] }}
                                </span>
                            </div>
                            <div v-if="institutionId">
                                <div class="form-group">
                                    <label for="inp-role">Role</label>
                                    <select name="role" id="inp-role" class="form-control" v-model="role">
                                        <option value="manager">Manager</option>
                                        <option value="editor">Editor</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Permissions</label>
                                    <div class="form-check">
                                        <input v-model="permissions" class="form-check-input" type="checkbox" name="permissions" value="research" id="defaultCheck1">
                                        <label class="form-check-label" for="defaultCheck1">
                                            Research Projects
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input v-model="permissions" class="form-check-input" type="checkbox" name="permissions" value="grant" id="defaultCheck2">
                                        <label class="form-check-label" for="defaultCheck2">
                                            Grant Projects
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="form-group">
                                    <label>Type</label>
                                    <div class="form-check">
                                        <input v-model="expertType" class="form-check-input" type="radio" name="type" value="1" id="inp-type-1">
                                        <label class="form-check-label" for="inp-type-1">
                                            Expert
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input v-model="expertType" class="form-check-input" type="radio" name="type" value="2" id="inp-type-2">
                                        <label class="form-check-label" for="inp-type-2">
                                            Consolidation Expert
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="text-right">
                                <button type="submit" class="btn btn-link" data-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-success" :disabled="!this.selected">Attach selected user</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            institutionId: Number,
            projectId: String,
        },
        data() {
            return {
                options: [],
                selected: null,
                role: 'manager',
                permissions: [],
                errors: {},
                expertType: 1
            }
        },
        methods: {
            submit() {
                if (!this.selected) {
                    return;
                }

                let roles = [];
                if (this.permissions.length) {
                    roles = this.permissions.map(e => e + ' ' + this.role)
                }
                else {
                    roles.push(this.role)
                }
                this.selected.roles = roles;

                let url = (this.projectId) ? 'expert/assignExpert' : 'institution/addParticipant';

                let data = {
                    id: this.institutionId,
                    user: this.selected,
                    project_id: this.projectId,
                    type: this.expertType
                };

                this.$store.dispatch(url, data);

                this.selected = null;
            },
            onSearch(keyword, loading) {
                if (!keyword) {
                    return;
                }

                loading(true);

                let url = (this.projectId) ? '/api/available-experts' : '/api/institutions/available-participant-list';

                let data = {
                    keyword: keyword,
                    project_id: this.projectId
                };

                axios.get(url, {params: data})
                    .then(response => {
                        this.options = response.data;

                        loading(false);
                    });
            }
        },
    }
</script>
