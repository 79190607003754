/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

Vue.prototype.__ = string => {
    let groups = string.split('.');
    let groupCount = groups.length - 1;

    if( groupCount > 1 ) {
        return _.get(window.i18n, [groups[0], groups.slice(1).join('.')], string);
    }

    return _.get(window.i18n, string, string);
};

import vSelect from 'vue-select'
import "vue-select/src/scss/vue-select.scss";
Vue.component('v-select', vSelect);

import { ClientTable} from 'vue-tables-2';
Vue.use(ClientTable);

import Datepicker from 'vuejs-datepicker';
Vue.component('datepicker', Datepicker);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('user-list', require('./components/Users/UserList.vue').default);
Vue.component('expert-list', require('./components/Users/ExpertList.vue').default);
Vue.component('expert-form', require('./components/Users/ExpertForm.vue').default);
Vue.component('expert-delete-form', require('./components/Users/ExpertDeleteForm.vue').default);
Vue.component('user-create-form', require('./components/Users/UserCreateForm.vue').default);
Vue.component('user-search-form', require('./components/Users/UserSearchForm.vue').default);
Vue.component('user-delete-form', require('./components/Users/UserDeleteForm.vue').default);

Vue.component('project-list', require('./components/Projects/ProjectList.vue').default);

Vue.component('payment-list', require('./components/Payments/PaymentList.vue').default);
Vue.component('payment-grant-form', require('./components/Payments/PaymentGrantCreate.vue').default);
Vue.component('payment-research-form', require('./components/Payments/PaymentResearchCreate.vue').default);

Vue.component('grant-project-list', require('./components/GrantProjects/GrantProjectList.vue').default);
Vue.component('grant-project-form', require('./components/GrantProjects/GrantProjectForm.vue').default);

Vue.component('research-project-list', require('./components/ResearchProjects/ResearchProjectList.vue').default);
Vue.component('research-project-form', require('./components/ResearchProjects/ResearchProjectForm.vue').default);

Vue.component('institution-list', require('./components/Institutions/InstitutionList.vue').default);
Vue.component('institution-create-form', require('./components/Institutions/InstitutionCreateForm.vue').default);
Vue.component('institution-edit-form', require('./components/Institutions/InstitutionEditForm.vue').default);
Vue.component('institution-delete-form', require('./components/Institutions/InstitutionDeleteForm.vue').default);
Vue.component('institution-participant-delete-form', require('./components/Institutions/InstitutionParticipantDeleteForm.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import store from './store'

const app = new Vue({
    el: '#app',
    store
});

/**
 * Submits translations using async ajax request in admin section.
 */
$(function () {
    $('form.translations-groups select[name="group"]').on('change', function(event) {
        let $select = $(event.target);
        let $form = $select.parents('form');

        $form.submit();
    });

    $('table.translations input[name="text"]').on( 'focusout', function(event) {
        let $input = $(event.target);
        let $table = $('table.translations');

        $input.attr('disabled', 'disabled');

        let data = {
            group: $input.data('group'),
            locale: $input.data('locale'),
            item: $input.data('item'),
            text: $input.val(),
            _token: $('meta[name=csrf-token]').attr('content')
        };

        let request = $.ajax({
            type: 'POST',
            url: $table.data('action'),
            data: data,
            headers: {
                Accept: "application/json; charset=utf-8"
            }
        });

        request.done(function() {
            setTimeout( function() {
                $input.removeAttr('disabled');
            }, 500 );
        });
    });
});

$(document).popover({selector: '[data-toggle="popover"]'});

$("body").scrollspy({ target: "#step-nav" });

$(document).ready(function(){
    if (typeof sessionToastr !== 'undefined') {
        if (sessionToastr.status == 'success') {
            window.toastr.success(sessionToastr.message);
        }
        if(sessionToastr.status == 'danger') {
            window.toastr.error(sessionToastr.message);
        }
    }

    $('input[name="evaluation_condition"]').on('change', function() {
        $('#evaluation_completed_status-declined').attr('checked', true).trigger('change');
        $('#evaluation_completed_status-declined').parent().trigger('click');
    });

    $('#evaluation_completed_status-completed, #evaluation_completed_status-partly').on('change', function() {
        $('.condition-group input[type="radio"]:first').attr('checked', true).trigger('click');
    });


    $('input[type="file"]').change(function() {
        var file = $(this)[0].files[0].name;
        $(this).parent().find('.custom-file-label').text(file);
    });

    $('.js-confirmed-amount-input').change(function () {
        $(this).parents('tr').find('.js-process-form input[name="confirmed_amount"]').val($(this).val());
    });
    $('.js-confirmed-amount-comment-input').change(function () {
        $(this).parents('tr').find('.js-process-form input[name="confirmed_amount_comment"]').val($(this).val());
    });
    $('.js-paid-amount-comment-input').change(function () {
        $(this).parents('tr').find('.js-process-form input[name="paid_amount_comment"]').val($(this).val());
    });
    $('.js-paid-amount-input').change(function () {
        $(this).parents('tr').find('.js-process-form input[name="paid_amount"]').val($(this).val());
    });
})
