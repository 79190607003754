<template>
    <div class="d-inline">
        <button type="button" class="btn btn-outline-danger btn-rounded" data-toggle="modal" :data-target="'#user-delete-' + user.id">
            <i class="fa fa-trash-alt"></i>
        </button>

        <div class="modal fade" :id="'user-delete-' + user.id" tabindex="-1" role="dialog" aria-labelledby="user-createLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="user-createLabel">
                            Are you sure?
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submit">
                            <div class="row">
                                <div class="col-12">
                                    You are trying to delete <strong>{{ user.first_name + ' ' + user.last_name }} - {{ user.email }}</strong>. Are you sure?
                                </div>
                                <div class="col-12 text-right">
                                    <button class="btn btn-link" data-dismiss="modal">Cancel</button>
                                    <form @submit.prevent="submit" class="d-inline">
                                    <button type="submit" class="btn btn-danger">Delete</button>
                                    </form>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['user'],
        methods: {
            submit() {
                this.$store.dispatch('user/destroy', this.user.id);
            },
        },
    }
</script>
